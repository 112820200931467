@import "common";

.player_settings {
  .warning {
    font-size: 12px;
    color: #ccc;
    background: #222;
    color: #eee;
    padding: 10px 15px;
    margin-bottom: 4px;
    text-transform: uppercase;
  }

  .player-settings-checkbox {
    display: block;
    height: 50px;
    width: 100%;

    span {
      line-height: 50px;
    }
  }

  label {
    font-weight: 600;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 0.9px;
    //margin-bottom: 10px;
  }

  .control {
    clear: both;
  }

  .control.toggle,
  .control.multi_toggle {
    label {
      width: 100px;
      font-weight: 500;
      font-size: 12px;
      vertical-align: middle;
      margin-bottom: 0px;
    }
  }

  .control.textinput {
    width: 100%;

    input {
      outline: none;
    }
  }

  .contents-frame {
    position: relative;
    width: 270px;
    height: 590px;

    hr {
      margin: 10px 0px;
    }
  }

  .player-preview-frame {
    position: absolute;
    top: 10px;
    left: 300px;
    width: 1280px;
    display: flex;
    justify-content: center;
  }

  .player-preview {
    display: flex;
    flex-direction: column;
    background: #4b4e55;
    transform-origin: 0 0;
    width: 1280px;
    height: 720px;
    position: absolute;
    left: 0px;
    top: 0px;
    pointer-events: none;
    outline: solid 1px #aaa;

    img {
      vertical-align: middle;
    }
  }

  .inset-control {
    margin-left: 33px;
    //position: relative;
    //top: -10px;
  }

  .small-label {
    color: #333;
    font-weight: 600;
    font-size: 11px;
    margin-right: 10px;
    display: inline;
    text-transform: uppercase;
  }

  input[type="number"] {
    color: #333;
    font-size: 14px;
    padding: 2px 5px;
    margin-right: 10px;
  }

  .toggle-buttons {
    position: absolute;
    bottom: -10px;
    display: flex;
    color: rgba(0, 0, 0, 0.6);
    font-size: 13px;
    font-weight: 600;
    text-transform: uppercase;

    div {
      margin: 0px 10px;
    }

    div.selected {
      color: $newBlue;
    }
  }

  .social-popup-buttons {
    pointer-events: none !important;
  }
}

.full-player {
  background: black;
  display: flex;
  align-items: center;
  justify-content: center;

  .slide-preview {
    position: relative;
    width: 100%;
    pointer-events: auto;

    .slide-thumbnail {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    .slide-watermark {
      position: absolute;
      bottom: 3px;
      right: 0px;
      padding: 6px 6px 7px 10px;
      background: white;

      img {
        width: 75px;
      }
    }
  }
}

.embed-player {
  background: white;
  background-image: url("../images/player-preview/browser-chrome.png");
  background-position: left top;
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  align-items: center;
  justify-content: center;

  &.disabled {
    .embed-frame {
      opacity: 0.2;
    }
  }

  .warning {
    position: absolute;
    font-size: 20px;
    font-weight: 600;
  }

  .embed-frame {
    width: 70%;
    border: solid 1px black;
    margin-top: 70px;

    .player_ui {
      position: initial !important;
    }

    .slide-preview {
      position: relative;
      width: 100%;

      .slide-thumbnail {
        width: 100%;
        height: auto;
        vertical-align: middle;
      }

      .slide-watermark {
        position: absolute;
        bottom: 0px;
        right: 0px;
        padding: 6px 6px 7px 10px;
        background: white;

        img {
          width: 75px;
        }
      }
    }

    //img {
    //  width: 100%;
    //  vertical-align: middle;
    //}
  }
}

