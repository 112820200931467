@import "common";

.modal_dialog.theme_chooser {
    width: 1130px;
    //min-height: 680px;
    .tab_content {
        height: 100%;
    }
    .contents {
        background: #e9eaed;
        padding: 20px 0px 10px 30px;
        box-shadow: inset 0px 0px 26px rgba(0, 0, 0, 0.2);
        //height: 100%;
    }
    .theme_grid {
        min-height: 100px;
        height: 360px;
        display: grid;
        grid-template-columns: repeat(4, 240px);
        grid-column-gap: 30px;
    }

    .theme_item {
        display: inline-block;
        margin-right: 30px;
        margin-bottom: 30px;
        &:nth-last-child(-n + 4) {
            // last row (ie last 4 items)
            margin-bottom: 10px;
        }
        vertical-align: middle;
        transition: transform 400ms cubic-bezier(0.555, 0.445, 0.065, 1);
        transform-style: preserve-3d;

        .user_theme_preview {
            width: 240px;
            height: 135px;
            border: solid 1px #ccc;
            background: white;
            box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
            transition: box-shadow 400ms cubic-bezier(0.555, 0.445, 0.065, 1);
            text-align: center;
            .logo {
                height: 68px;
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
                margin: 20px;
                //display: flex;
                //align-items: center;
                //justify-content: center;
                //img {
                //  height: 70px;
                //}
            }
        }
        & > img {
            box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
            transition: box-shadow 400ms cubic-bezier(0.555, 0.445, 0.065, 1);
            vertical-align: middle;
            //border: solid 1px transparent;
            width: 240px;
            background-color: $selectionColor;
        }
        &.selected {
            & > img,
            .user_theme_preview {
                outline: solid 2px $selectionColor;
            }
        }
        &:hover {
            & > img,
            .user_theme_preview {
                outline: solid 2px $selectionColor;
            }
            .options_menu_button {
                display: block;
            }
        }
        .name {
            width: 100%;
            margin-top: 4px;
            text-align: center;
            color: #333;
            font-size: 14px;
            font-weight: 600;
            cursor: default;
        }

        .options_menu_button {
            right: 0px;
            bottom: 2px;
        }

        .palette {
            position: absolute;
            left: 1px;
            bottom: 27px;
            width: 100%;
            display: flex;
            .color {
                display: inline-block;
                flex-grow: 1;
                height: 25px;
            }
        }

        &.no-theme-svg {
            width: 240px;
            height: 135px;
            background: #fff;
            &.selected {
                outline: 2px solid #50bbe6;
            }
            .name {
                top: calc(50% - 30px);
                position: relative;
                font-size: 30px;
            }
            .palette {
                bottom: 0;
            }
        }
    }

    .theme_item.selected {
        svg {
            outline: solid 2px $selectionColor;
        }
    }

    .palettePanel {
        background: white;
        overflow: auto;
        padding: 20px 40px;
        .control.dropdown_menu_prompt {
            display: inline-block;
            vertical-align: middle;
            //vertical-align: -7px;
            label {
                min-width: 60px;
            }
        }
        .palette_colors {
            display: inline-block;
            vertical-align: middle;
            margin-top: 10px;
            margin-left: 40px;
            //width: 770px;
        }
        .group {
            clear: none !important;
            display: inline-block;
            //overflow: auto;
            .color_list {
                margin-bottom: 0px !important;
            }
        }
    }

    .buttons {
        padding: 10px 20px 10px;
        border-top: solid 1px #e1e1e1;
    }

    .add_theme_button {
        background: $selectionColor;
        padding: 6px;
        color: white;
        vertical-align: 11px;
        cursor: default;
        border-radius: 100px;
        margin-left: 10px;
        &:hover {
            background: $darkSelectionColor;
        }
    }
}

.edit_theme_panel {
    width: 400px;
    height: 100vh;
    padding: 10px 20px 20px 20px;

    .theme_warning {
        background: #333;
        color: #f1f1f1;
        padding: 20px 20px 10px 20px;
        margin: -10px -20px 0px;
        font-weight: 600;
        text-align: left;
        font-size: 13px;
        margin-bottom: 10px;
        .control {
            margin-top: 10px;
        }
        .message {
            font-weight: 500;
            letter-spacing: 0px;
            font-size: 12px;
        }
        i {
            float: left;
            color: orange;
            font-size: 38px;
            margin-right: 20px;
        }
    }
}

.modal_dialog.full_screen_dialog {
    width: 100vw;
    height: 100vh;
    max-width: 100vw;
    max-height: 100vh;
    //background: $body_background;
    background-color: $body_background;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 1000;
    display: flex;
}

.modal_dialog.transparent_dialog {
    background-color: transparent !important;
}

.modal_dialog.payment_form_panel {
    width: 100vw;
    height: 100vh;
    max-width: 100vw;
    max-height: 100vh;
    //background: $body_background;
    background-color: transparent;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 1000;
    display: flex;
}

.modal_dialog.edit_theme_dialog {
    width: 100vw;
    height: 100vh;
    max-width: 100vw;
    max-height: 100vh;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 1300; // ensure this isn't hidden by other dialogs

    display: flex;
    flex-direction: column;

    // temp styles - remove these with new theme dialog
    .theme-fonts {
        .font-options {
            border: 1px solid #ccc;
            padding: 5px 10px 0;
            margin: 10px 0;
            .numeric label {
                margin: 0;
            }
        }
        input {
            margin: 0;
        }
    }

    .sidebar {
        position: unset;
        display: inline-block;
        width: 132px;
        overflow: hidden;
        padding: 20px 10px 10px 10px;
        //height: calc(100vh - 55px);
        height: 100vh;
        box-shadow: 4px 0px 20px rgba(0, 0, 0, 0.4);
        //position: absolute;
        //top: 0px;
        //left: 0px;
        background: #333;
        .title {
            font-size: 16px;
            font-weight: 600;
            text-transform: uppercase;
            color: white;
        }

        hr {
            background: #666;
        }
        .sidebar-button {
            color: white;
            text-transform: uppercase;
            font-weight: 600;
            margin-top: 30px;
            letter-spacing: 0.9px;
            cursor: pointer;
            text-align: center;
            &:hover {
                color: $selectionColor;
            }
        }
        .tabs {
            margin-top: 0px;
            margin-bottom: 10px;
            width: 100%;
            .tab {
                display: flex;
                width: 100%;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                text-align: center;
                margin-bottom: 20px;
                font-size: 12px;
                font-weight: 600;
                letter-spacing: 0.9px;
                color: #f1f1f1;
                &.selected {
                    color: $selectionColor;
                }
            }
        }
    }

    .theme-frame {
        width: 100%;
        height: calc(100%);
        display: flex;
    }

    .right-frame {
        display: flex;
        flex-direction: column;
        height: 100%;
        flex-grow: 1;
    }

    .preview_pane {
        height: calc(100% - 55px);
        //background: $body_background;
        margin: 40px 56px;
        //float: right;
        //width: 1100px;
        position: relative;
        .slide_canvas {
            position: absolute;
            //box-shadow: none !important;
            box-shadow: 0px 5px 50px rgba(0, 0, 0, 0.2);
        }
        .main_preview {
            z-index: 100;
        }
        .alt_preview {
            z-index: 0;
        }
    }

    .panel {
        margin: 0px;
    }

    .top_bar {
        height: 55px;
        background: #222;
        display: flex;
        align-items: baseline;
        font-size: 14px;

        .top_bar_inner {
            background: #222;
            display: flex;
            align-items: baseline;
            flex: 1;
            padding: 10px 10px 10px;
        }
        
        .blank_space {
            background: #000;
            width: 120px;
            height: 100%;
            align-self: flex-start;
        }

        .popup.button {
            border: none;
            min-width: auto;
            background: transparent;
            border-radius: 2px;
            margin: auto 0;

            padding: 0px 5px;
            margin-left: 5px;

            .micon {
                padding-top: 7px;
            }
        }

        label {
            font-size: 14px;
        }

        .theme_name {
            font-size: 16px;
            font-weight: 600;
            color: white;
            padding-top: 6px;
            padding-left: 10px;
        }

        .shared_theme_icon {
            align-self: center;

            .micon {
                background: red;
                border-radius: 100%;
                padding: 2px;
                margin-bottom: 3px;
                margin-left: 10px;
                color: white;
              }
        }
    }

    .buttons {
        width: 100%;
        height: 55px;
        bottom: 0px;
        display: flex;
        padding: 10px 10px 10px;
        //border-top: solid 1px #ccc;
        z-index: 1000;
        //box-shadow:0px 0px 20px rgba(0,0,0,.2);
        .spacer {
            display: inline-block;
            flex-grow: 2;
        }
        .control.button {
            background: none !important;
            border: none;
            border-radius: 0;
            label {
                font-size: 14px;
                color: #676767;
            }
            &.secondary {
                label {
                    color: #11A9E2;
                }

                &.disabled {
                    background: transparent !important;

                    label {
                        color: rgba(0, 0, 0, 0.5);;
                    }
                }
            }

            &.accent {
                background: #11A9E2 !important;

                label {
                    color: #ffffff;
                }
            }
            &.disabled {
                cursor: default;
                background: #e0e0e0 !important;
                
                label {
                    color: #888;
                }
            }
        }
    }

    .palette {
        .control.dropdown_menu_prompt {
            float: left;
        }
        .palette_colors {
            margin-left: 250px;
        }
    }
    .edit_theme_properties {
        .theme_properties {
            height: 153px;
        }
    }

    //label {
    //  font-weight: 600;
    //  font-size: 14px;
    //  text-transform: uppercase;
    //  letter-spacing: 0.9px;
    //  margin-bottom: 10px;
    //}

    .fonts {
        margin: 20px 0px 20px;
        &:last-of-type {
            margin-bottom: 0px;
        }
        display: flex;
        flex-wrap: wrap;
        //width: 155px;
        .font {
            display: inline-block;
            border: solid 1px #ccc;
            padding: 2px 0px;
            background: white;
            margin-bottom: 5px;
            margin-right: 5px;
            img {
                display: inline-block;
                vertical-align: middle;
            }
            &.title {
                img {
                    width: 200px;
                }
            }
            &.body {
                img {
                    width: 130px;
                }
            }
            &.selected {
                border-color: $selectionColor;
                outline: solid 1px $selectionColor;
            }
        }
    }

    .control {
        clear: both;
    }
    .control.toggle,
    .control.multi_toggle {
        label {
            width: 100px;
            font-weight: 500;
            font-size: 12px;
            vertical-align: middle;
            margin-bottom: 0px;
        }
    }

    .control.textinput {
        width: 100%;
        input {
            outline: none;
        }
    }

    .control.dropdown_menu_prompt {
        display: flex;
        align-items: center;
        label {
            min-width: 60px;
            margin-right: 10px;
            margin-bottom: 0px;
        }
    }

    .titleStyles {
        .icon {
            width: 88px !important;
            img {
                width: 88px !important;
                height: 55px !important;
            }
        }
    }

    .logo_group {
        margin-bottom: 20px;
        .section {
            min-width: 54px;
        }

        .logos {
            display: flex;
            width: 350px;
            justify-content: space-between;
        }
        .logo_container {
            label {
                display: block;
                width: 100%;
                margin-top: 4px;
                text-align: center;
                text-transform: uppercase;
                font-size: 10px;
                font-weight: 500;
                color: #555;
            }
        }
        .logo {
            width: 170px;
            height: 100px;
            padding: 10px;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;

            &.light {
                background: #fff;
            }
            &.dark {
                background: #333;
            }
            .delete {
                position: absolute;
                top: -7px;
                right: -7px;
                font-size: 14px;
                background: $selectionColor;
                border-radius: 100px;
                padding: 2px;
                color: white;
                display: none;
                cursor: pointer;
            }
            &:hover {
                i {
                    display: block;
                }
            }
        }
        .logo + .logo {
            margin-left: 20px;
        }
        .labels {
            clear: both;
            padding-top: 4px;
            font-size: 10px;
            color: #666;
            div {
                float: left;
                width: 170px;
                margin-right: 20px;
                text-align: center;
            }
        }
    }

    .palettePanel {
        .palette_colors {
            margin-top: 10px;
        }
    }
}

.edit_theme_properties {
    .theme_properties {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        height: 100%;
    }

    .dropdown_menu_prompt {
        label {
            font-size: 14px;
            min-width: 125px;
        }
        margin-bottom: 20px;
    }

    input {
        margin: 0px;
    }

    .row {
        & > div {
            display: inline-block;
            margin-right: 40px;
        }
    }
    .name_field {
        margin-bottom: 20px;
        .section {
            display: inline-block;
            min-width: 54px;
        }
        .textinput {
            display: inline-block;
            input {
                border: solid 1px #ddd;
                vertical-align: middle;
                width: 220px;
                padding: 8px 10px 8px;
            }

            .button {
                margin-left: 10px;
                margin-bottom: 0px;
            }
        }
    }

    hr {
        clear: both;
        border-style: none;
        height: 1px;
        background: #ddd;
        margin-top: 10px;
        margin-bottom: 20px;
    }

    .logo_group {
        margin-bottom: 20px;
        .section {
            min-width: 54px;
        }

        .logos {
            display: flex;
            width: 350px;
            justify-content: space-between;
        }
        .logo_container {
            label {
                display: block;
                width: 100%;
                margin-top: 4px;
                text-align: center;
                text-transform: uppercase;
                font-size: 10px;
                font-weight: 500;
                color: #555;
            }
        }
        .logo {
            width: 170px;
            height: 100px;
            padding: 10px;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;

            &.light {
                background: #fff;
            }
            &.dark {
                background: #333;
            }
            .delete {
                position: absolute;
                top: -7px;
                right: -7px;
                font-size: 14px;
                background: $selectionColor;
                border-radius: 100px;
                padding: 2px;
                color: white;
                display: none;
                cursor: pointer;
            }
            &:hover {
                i {
                    display: block;
                }
            }
        }
        .logo + .logo {
            margin-left: 20px;
        }
        .labels {
            clear: both;
            padding-top: 4px;
            font-size: 10px;
            color: #666;
            div {
                float: left;
                width: 170px;
                margin-right: 20px;
                text-align: center;
            }
        }
    }

    .footer_group {
        .control {
            margin-right: 20px;
        }
        .control.toggle,
        .control.multi_toggle {
            //margin-right: 40px;
            label {
                min-width: 60px;
                margin-right: 10px;
                width: auto;
            }
        }
        //.control.multi_toggle {
        //  label {
        //    width: 68px;
        //    //  text-transform: uppercase;
        //    //  font-size: 14px;
        //    //  margin-right: 20px;
        //    //  font-weight: 600;
        //  }
        //  display: inline-block;
        //  width: 100%;
        //}
        .textinput {
            width: 100%;
            input {
                outline: none;
            }
        }
    }
}

.palettePanel {
    .dropdown_menu_prompt {
        label {
            margin: 0px;
        }
    }
    .palette_colors {
        //margin-left: 40px;
        //width: 600px;
        .color_list {
            float: left;
            margin-right: 20px;
            margin-bottom: 20px;
            .large_color_chit {
                width: 40px;
                display: inline-block;
                margin-right: 4px;
                position: relative;
                .color_chit {
                    width: 40px;
                    height: 40px;
                    border: solid 1px rgba(0, 0, 0, 0.2);
                }
                i {
                    position: absolute;
                    top: -7px;
                    right: -7px;
                    font-size: 14px;
                    background: $selectionColor;
                    border-radius: 100px;
                    padding: 2px;
                    color: white;
                    display: none;
                    cursor: pointer;
                    z-index: 10000;
                }
                &:hover {
                    i {
                        display: block;
                    }
                }
            }
            .label {
                //width: calc(100% - 10px);
                //text-align: center;
                text-transform: uppercase;
                font-size: 10px;
                font-weight: 500;
                color: #555;
            }
            .control.button {
                display: inline-block;
                vertical-align: -4px;
                margin: 0px;
                background: none !important;
                i {
                    background: none;
                    color: $selectionColor;
                    box-shadow: none;
                }
            }
        }
    }
}

.palette_menu {
    display: flex;
    flex-wrap: wrap;
    max-width: 800px;
    margin-right: -20px;
    .palette_row.menu_item {
        padding: 4px 6px;
        display: inline-block;
        min-width: 180px;
        margin-right: 20px;
        .name {
            margin-bottom: 4px;
        }
        .color_chit {
            margin-right: 6px;
            width: 20px;
            height: 20px;
        }
        &:hover {
            background: lighten($selectionColor, 20%);
        }
    }
}

.color_popup_menu {
    width: 125px;
    .color_group {
        width: 100%;
        margin-bottom: 40px;
        text-align: center;
        & > .label {
            font-size: 12px;
            font-weight: 600;
            color: #f1f1f1;
            text-transform: uppercase;
            margin-bottom: 20px;
        }
        .disabled_warning {
            position: absolute;
            padding: 4px 10px;
            border: solid 1px #50bbe6;
            top: 42px;
            background: #50bbe6;
            color: white;
            text-transform: uppercase;
            font-size: 11px;
        }

        .color_list {
            display: inline-block;
            vertical-align: top;
            margin-right: -8px;
            &.disabled {
                opacity: 0.2;
            }
            .color_chit {
                width: 35px;
                height: 35px;
                display: inline-block;
                margin-bottom: 10px;
                margin-right: 10px;
                border: none;
                border-radius: 100px;
                background: #eee;
                cursor: pointer;
                &.light-color {
                    border: solid 1px #bbb;
                }
                display: inline-flex;
                align-items: center;
                justify-content: center;
                i {
                    color: $darkSelectionColor;
                }
            }
            .label {
                width: calc(100% - 5px);
                text-align: center;
                text-transform: uppercase;
                font-size: 10px;
                font-weight: 500;
                color: #f1f1f1;
                margin-top: 4px;
            }
            .colorful_chit {
                width: 35px;
                height: 35px;
                display: inline-flex;
                margin-right: 10px;
                border-radius: 100px;
                vertical-align: top;
                overflow: hidden;
                cursor: pointer;
                div {
                    width: 4px;
                    margin-right: 0px;
                    height: 100%;
                    display: inline-block;
                    vertical-align: top;
                    flex-grow: 1;
                }
            }
        }
        .thumbnail {
            position: relative;
            width: 40px;
            height: 40px;
            margin-bottom: 10px;
            margin-right: 5px;
            //outline: solid 1px transparent;
            text-align: center;
            display: inline-block;
            img {
                width: 100%;
                height: 100%;
            }
            &:after {
                content: "";
                position: absolute;
                top: 0px;
                left: 0px;
                width: 38px;
                height: 38px;
                //border: solid 1px #bbb;
            }
            .light-color {
                &:after {
                    border: solid 1px #bbb;
                }
            }
            &:hover {
                &:after {
                    border-color: $selectionColor;
                }
            }
            &.selected {
                &:after {
                    border-color: $selectionColor;
                }
            }
        }
    }
}

.choose_palette_dialog {
    width: 970px;
    .prompt {
        text-align: center;
        font-size: 24px;
    }
    .colors {
        margin-top: 30px;
        margin-right: -10px;
        .color {
            display: inline-block;
            width: 75px;
            height: 75px;
            margin-right: 10px;
            border-radius: 100px;
            cursor: pointer;
        }
    }
}
