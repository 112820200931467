.x-spreadsheet {
    font-size: 13px;
    line-height: normal;
    user-select: none;
    -moz-user-select: none;
    font-family: 'Source Sans Pro' sans-serif;
    box-sizing: content-box;
    background: #fff;
    -webkit-font-smoothing: antialiased;
}
.x-spreadsheet textarea {
    font: 'Source Sans Pro' sans-serif;
}
.x-spreadsheet-sheet {
    position: relative;
    overflow: hidden;
}
.x-spreadsheet-table {
    vertical-align: bottom;
}
.x-spreadsheet-tooltip {
    font-family: inherit;
    position: absolute;
    padding: 5px 10px;
    color: #fff;
    border-radius: 1px;
    background: #000000;
    font-size: 12px;
    z-index: 201;
}
.x-spreadsheet-tooltip:before {
    pointer-events: none;
    position: absolute;
    left: calc(50% - 4px);
    top: -4px;
    content: "";
    width: 8px;
    height: 8px;
    background: inherit;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    z-index: 1;
    box-shadow: 1px 1px 3px -1px rgba(0, 0, 0, 0.3);
}
.x-spreadsheet-color-palette {
    padding: 5px;
}
.x-spreadsheet-color-palette table {
    margin: 0;
    padding: 0;
    border-collapse: separate;
    border-spacing: 2;
    background: #fff;
}
.x-spreadsheet-color-palette table td {
    margin: 0;
    cursor: pointer;
    border: 1px solid transparent;
}
.x-spreadsheet-color-palette table td:hover {
    border-color: #ddd;
}
.x-spreadsheet-color-palette table td .x-spreadsheet-color-palette-cell {
    width: 16px;
    height: 16px;
}
.x-spreadsheet-border-palette {
    padding: 6px;
}
.x-spreadsheet-border-palette table {
    margin: 0;
    padding: 0;
    border-collapse: separate;
    border-spacing: 0;
    background: #fff;
    table-layout: fixed;
}
.x-spreadsheet-border-palette table td {
    margin: 0;
}
.x-spreadsheet-border-palette .x-spreadsheet-border-palette-left {
    border-right: 1px solid #eee;
    padding-right: 6px;
}
.x-spreadsheet-border-palette .x-spreadsheet-border-palette-left .x-spreadsheet-border-palette-cell {
    width: 30px;
    height: 30px;
    cursor: pointer;
    text-align: center;
}
.x-spreadsheet-border-palette .x-spreadsheet-border-palette-left .x-spreadsheet-border-palette-cell .x-spreadsheet-icon-img {
    opacity: 0.8;
}
.x-spreadsheet-border-palette .x-spreadsheet-border-palette-left .x-spreadsheet-border-palette-cell:hover {
    background-color: #eee;
}
.x-spreadsheet-border-palette .x-spreadsheet-border-palette-right {
    padding-left: 6px;
}
.x-spreadsheet-border-palette .x-spreadsheet-border-palette-right .x-spreadsheet-toolbar-btn {
    margin-top: 0;
    margin-bottom: 3px;
}
.x-spreadsheet-border-palette .x-spreadsheet-border-palette-right .x-spreadsheet-line-type {
    position: relative;
    left: 0;
    top: -3px;
}
.x-spreadsheet-dropdown {
    position: relative;
}
.x-spreadsheet-dropdown .x-spreadsheet-dropdown-content {
    position: absolute;
    z-index: 200;
    background: #fff;
    box-shadow: 1px 2px 5px 2px rgba(51, 51, 51, 0.15);
}
.x-spreadsheet-dropdown.bottom-left .x-spreadsheet-dropdown-content {
    top: calc(100% + 5px);
    left: 0;
}
.x-spreadsheet-dropdown.bottom-right .x-spreadsheet-dropdown-content {
    top: calc(100% + 5px);
    right: 0;
}
.x-spreadsheet-dropdown.top-left .x-spreadsheet-dropdown-content {
    bottom: calc(100% + 5px);
    left: 0;
}
.x-spreadsheet-dropdown.top-right .x-spreadsheet-dropdown-content {
    bottom: calc(100% + 5px);
    right: 0;
}
.x-spreadsheet-dropdown .x-spreadsheet-dropdown-title {
    padding: 0 5px;
    display: inline-block;
}
.x-spreadsheet-dropdown .x-spreadsheet-dropdown-header .x-spreadsheet-icon.arrow-left {
    margin-left: 4px;
}
.x-spreadsheet-dropdown .x-spreadsheet-dropdown-header .x-spreadsheet-icon.arrow-right {
    width: 10px;
    margin-right: 4px;
}
.x-spreadsheet-dropdown .x-spreadsheet-dropdown-header .x-spreadsheet-icon.arrow-right .arrow-down {
    left: -130px;
}
  /* resizer **/
.x-spreadsheet-resizer {
    position: absolute;
    z-index: 11;
}
.x-spreadsheet-resizer .x-spreadsheet-resizer-hover {
    background-color: rgba(75, 137, 255, 0.25);
}
.x-spreadsheet-resizer .x-spreadsheet-resizer-line {
    position: absolute;
}
.x-spreadsheet-resizer.horizontal {
    cursor: row-resize;
}
.x-spreadsheet-resizer.horizontal .x-spreadsheet-resizer-line {
    border-bottom: 2px dashed #4b89ff;
    left: 0;
    bottom: 0;
}
.x-spreadsheet-resizer.vertical {
    cursor: col-resize;
}
.x-spreadsheet-resizer.vertical .x-spreadsheet-resizer-line {
    border-right: 2px dashed #4b89ff;
    top: 0;
    right: 0;
}
  /* scrollbar */
.x-spreadsheet-scrollbar {
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: #f4f5f8;
    opacity: 0.9;
    z-index: 12;
}
.x-spreadsheet-scrollbar.horizontal {
    right: 15px;
    overflow-x: scroll;
    overflow-y: hidden;
}
.x-spreadsheet-scrollbar.horizontal > div {
    height: 1px;
    background: #ddd;
}
.x-spreadsheet-scrollbar.vertical {
    bottom: 15px;
    overflow-x: hidden;
    overflow-y: scroll;
}
.x-spreadsheet-scrollbar.vertical > div {
    width: 1px;
    background: #ddd;
}
  /* @{css-prefix}-overlayer */
.x-spreadsheet-overlayer {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 10;
}
.x-spreadsheet-overlayer .x-spreadsheet-overlayer-content {
    position: absolute;
    overflow: hidden;
    pointer-events: none;
    width: 100%;
    height: 100%;
}
.x-spreadsheet-editor,
.x-spreadsheet-selector {
    box-sizing: content-box;
    position: absolute;
    overflow: hidden;
    pointer-events: none;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
  /* @{css-prefix}-selector */
.x-spreadsheet-selector .hide-input {
    position: absolute;
    z-index: 0;
}
.x-spreadsheet-selector .hide-input input {
    padding: 0;
    width: 0;
    border: none!important;
}
.x-spreadsheet-selector .x-spreadsheet-selector-area {
    position: absolute;
    border: 2px solid #4b89ff;
    background: rgba(75, 137, 255, 0.1);
    z-index: 5;
}
.x-spreadsheet-selector .x-spreadsheet-selector-clipboard,
.x-spreadsheet-selector .x-spreadsheet-selector-autofill {
    position: absolute;
    background: transparent;
    z-index: 100;
}
.x-spreadsheet-selector .x-spreadsheet-selector-clipboard {
    border: 2px dashed #4b89ff;
}
.x-spreadsheet-selector .x-spreadsheet-selector-autofill {
    border: 1px dashed rgba(0, 0, 0, 0.45);
}
.x-spreadsheet-selector .x-spreadsheet-selector-corner {
    pointer-events: auto;
    position: absolute;
    cursor: crosshair;
    font-size: 0;
    height: 5px;
    width: 5px;
    right: -5px;
    bottom: -5px;
    border: 2px solid #ffffff;
    background: #4b89ff;
}
.x-spreadsheet-editor {
    z-index: 20;
}
.x-spreadsheet-editor .x-spreadsheet-editor-area {
    position: absolute;
    text-align: left;
    border: 2px solid #4b89ff;
    line-height: 0;
    z-index: 100;
    pointer-events: auto;
}
.x-spreadsheet-editor .x-spreadsheet-editor-area textarea {
    box-sizing: content-box;
    border: none;
    padding: 0 3px;
    outline: none;
    resize: none;
    text-align: start;
    overflow-y: hidden;
    font: 400 13px Arial, 'Lato', 'Source Sans Pro', Roboto, Helvetica, sans-serif;
    color: inherit;
    white-space: normal;
    word-wrap: break-word;
    line-height: 22px;
    margin: 0;
}
.x-spreadsheet-editor .x-spreadsheet-editor-area .textline {
    overflow: hidden;
    visibility: hidden;
    position: fixed;
    top: 0;
    left: 0;
}
.x-spreadsheet-item {
    user-select: none;
    background: 0;
    border: 1px solid transparent;
    outline: none;
    height: 26px;
    color: rgba(0, 0, 0, 0.9);
    line-height: 26px;
    list-style: none;
    padding: 2px 10px;
    cursor: default;
    text-align: left;
    overflow: hidden;
}
.x-spreadsheet-item.disabled {
    pointer-events: none;
    opacity: 0.5;
}
.x-spreadsheet-item:hover,
.x-spreadsheet-item.active {
    background: rgba(0, 0, 0, 0.05);
}
.x-spreadsheet-item.divider {
    height: 0;
    padding: 0;
    margin: 5px 0;
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.x-spreadsheet-item .label {
    float: right;
    opacity: 0.65;
    font-size: 1em;
}
.x-spreadsheet-item.state,
.x-spreadsheet-header.state {
    padding-left: 35px!important;
    position: relative;
}
.x-spreadsheet-item.state:before,
.x-spreadsheet-header.state:before {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    left: 12px;
    top: calc(50% - 5px);
    background: rgba(0, 0, 0, 0.08);
    border-radius: 2px;
}
.x-spreadsheet-item.state.checked:before,
.x-spreadsheet-header.state.checked:before {
    background: #4b89ff;
}
.x-spreadsheet-checkbox {
    position: relative;
    display: inline-block;
    backface-visibility: hidden;
    outline: 0;
    vertical-align: baseline;
    font-style: normal;
    font-size: 1rem;
    line-height: 1em;
}
.x-spreadsheet-checkbox > input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0!important;
    outline: 0;
    z-index: -1;
}
.x-spreadsheet-suggest,
.x-spreadsheet-contextmenu,
.x-spreadsheet-sort-filter {
    position: absolute;
    box-shadow: 1px 2px 5px 2px rgba(51, 51, 51, 0.15);
    background: #fff;
    z-index: 100;
    width: 260px;
    pointer-events: auto;
    overflow: auto;
}
.x-spreadsheet-suggest {
    width: 200px;
}
.x-spreadsheet-filter {
    border: 1px solid #e9e9e9;
    font-size: 12px;
    margin: 10px;
}
.x-spreadsheet-filter .x-spreadsheet-header {
    padding: 0.5em 0.75em;
    background: #f8f8f9;
    border-bottom: 1px solid #e9e9e9;
    border-left: 1px solid transparent;
}
.x-spreadsheet-filter .x-spreadsheet-body {
    height: 200px;
    overflow-y: auto;
}
.x-spreadsheet-filter .x-spreadsheet-body .x-spreadsheet-item {
    height: 20px;
    line-height: 20px;
}
.x-spreadsheet-sort-filter .x-spreadsheet-buttons {
    margin: 10px;
}
.x-spreadsheet-toolbar,
.x-spreadsheet-bottombar {
    height: 40px;
    padding: 0 30px;
    text-align: left;
    background: #f5f6f7;
    display: flex;
}
.x-spreadsheet-bottombar {
    position: relative;
    border-top: 1px solid #e0e2e4;
}
.x-spreadsheet-bottombar .x-spreadsheet-menu > li {
    line-height: 40px;
    height: 40px;
    padding-top: 0;
    padding-bottom: 0;
    vertical-align: middle;
    border-right: 1px solid #e8eaed;
}
.x-spreadsheet-menu {
    list-style: none;
    margin: 0;
    padding: 0;
    user-select: none;
}
.x-spreadsheet-menu > li {
    float: left;
    line-height: 1.25em;
    padding: 0.785em 1em;
    margin: 0;
    vertical-align: middle;
    text-align: left;
    font-weight: 400;
    color: #80868b;
    white-space: nowrap;
    cursor: pointer;
    transition: all 0.3s;
    font-weight: bold;
}
.x-spreadsheet-menu > li.active {
    background-color: #fff;
    color: rgba(0, 0, 0, 0.65);
}
.x-spreadsheet-menu > li .x-spreadsheet-icon {
    margin: 0 6px;
}
.x-spreadsheet-menu > li .x-spreadsheet-icon .x-spreadsheet-icon-img:hover {
    opacity: 0.85;
}
.x-spreadsheet-menu > li .x-spreadsheet-dropdown {
    display: inline-block;
}
.x-spreadsheet-toolbar {
    border-bottom: 1px solid #e0e2e4;
}
.x-spreadsheet-toolbar .x-spreadsheet-toolbar-btns {
    display: inline-flex;
}
.x-spreadsheet-toolbar .x-spreadsheet-toolbar-more {
    padding: 0 6px 6px;
    text-align: left;
}
.x-spreadsheet-toolbar .x-spreadsheet-toolbar-more .x-spreadsheet-toolbar-divider {
    margin-top: 0;
}
.x-spreadsheet-toolbar .x-spreadsheet-toolbar-btn {
    flex: 0 0 auto;
    display: inline-block;
    border: 1px solid transparent;
    height: 26px;
    line-height: 26px;
    min-width: 26px;
    margin: 6px 1px 0;
    padding: 0;
    text-align: center;
    border-radius: 2px;
}
.x-spreadsheet-toolbar .x-spreadsheet-toolbar-btn.disabled {
    pointer-events: none;
    opacity: 0.5;
}
.x-spreadsheet-toolbar .x-spreadsheet-toolbar-btn:hover,
.x-spreadsheet-toolbar .x-spreadsheet-toolbar-btn.active {
    background: rgba(0, 0, 0, 0.08);
}
.x-spreadsheet-toolbar-divider {
    display: inline-block;
    border-right: 1px solid #e0e2e4;
    width: 0;
    vertical-align: middle;
    height: 18px;
    margin: 12px 3px 0;
}
.x-spreadsheet-print {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 100;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}
.x-spreadsheet-print-bar {
    background: #424242;
    height: 60px;
    line-height: 60px;
    padding: 0 30px;
}
.x-spreadsheet-print-bar .-title {
    color: #fff;
    font-weight: bold;
    font-size: 1.2em;
    float: left;
}
.x-spreadsheet-print-bar .-right {
    float: right;
    margin-top: 12px;
}
.x-spreadsheet-print-content {
    display: flex;
    flex: auto;
    flex-direction: row;
    background: #d0d0d0;
    height: calc(100% - 60px);
}
.x-spreadsheet-print-content .-sider {
    flex: 0 0 300px;
    width: 300px;
    border-left: 2px solid #ccc;
    background: #fff;
}
.x-spreadsheet-print-content .-content {
    flex: auto;
    overflow-x: auto;
    overflow-y: scroll;
    height: 100%;
}
.x-spreadsheet-canvas-card-wraper {
    margin: 40px 20px;
}
.x-spreadsheet-canvas-card {
    background: #fff;
    margin: auto;
    page-break-before: auto;
    page-break-after: always;
    box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 3px rgba(0, 0, 0, 0.12), 0 4px 5px 0 rgba(0, 0, 0, 0.2);
}
.x-spreadsheet-calendar {
    color: rgba(0, 0, 0, 0.65);
    background: #ffffff;
    user-select: none;
}
.x-spreadsheet-calendar .calendar-header {
    font-weight: 700;
    line-height: 30px;
    text-align: center;
    width: 100%;
    float: left;
    background: #f9fafb;
}
.x-spreadsheet-calendar .calendar-header .calendar-header-left {
    padding-left: 5px;
    float: left;
}
.x-spreadsheet-calendar .calendar-header .calendar-header-right {
    float: right;
}
.x-spreadsheet-calendar .calendar-header .calendar-header-right a {
    padding: 3px 0;
    margin-right: 2px;
    border-radius: 2px;
}
.x-spreadsheet-calendar .calendar-header .calendar-header-right a:hover {
    background: rgba(0, 0, 0, 0.08);
}
.x-spreadsheet-calendar .calendar-body {
    border-collapse: collapse;
    border-spacing: 0;
}
.x-spreadsheet-calendar .calendar-body th,
.x-spreadsheet-calendar .calendar-body td {
    width: 14.28571429%;
    min-width: 32px;
    text-align: center;
    font-weight: 700;
    line-height: 30px;
    padding: 0;
}
.x-spreadsheet-calendar .calendar-body td > .cell:hover {
    background: #ecf6fd;
}
.x-spreadsheet-calendar .calendar-body td > .cell.active,
.x-spreadsheet-calendar .calendar-body td > .cell.active:hover {
    background: #ecf6fd;
    color: #2185D0;
}
.x-spreadsheet-calendar .calendar-body td > .cell.disabled {
    pointer-events: none;
    opacity: 0.5;
}
.x-spreadsheet-datepicker {
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
    position: absolute;
    left: 0;
    top: calc(100% + 5px);
    z-index: 10;
    width: auto;
}
.x-spreadsheet-buttons {
    display: flex;
    justify-content: flex-end;
}
.x-spreadsheet-buttons .x-spreadsheet-button {
    margin-left: 8px;
}
.x-spreadsheet-button {
    display: inline-block;
    border-radius: 3px;
    line-height: 1em;
    min-height: 1em;
    white-space: nowrap;
    text-align: center;
    cursor: pointer;
    font-size: 1em;
    font-weight: 700;
    padding: 0.75em 1em;
    color: rgba(0, 0, 0, 0.6);
    background: #E0E1E2;
    text-decoration: none;
    font-family: "Lato", "proxima-nova", "Helvetica Neue", Arial, sans-serif;
    outline: none;
    vertical-align: baseline;
    zoom: 1;
    user-select: none;
    transition: all 0.1s linear;
}
.x-spreadsheet-button.active,
.x-spreadsheet-button:hover {
    background-color: #C0C1C2;
    color: rgba(0, 0, 0, 0.8);
}
.x-spreadsheet-button.primary {
    color: #fff;
    background-color: #2185D0;
}
.x-spreadsheet-button.primary:hover,
.x-spreadsheet-button.primary.active {
    color: #fff;
    background-color: #1678c2;
}
.x-spreadsheet-form-input {
    font-size: 1em;
    position: relative;
    font-weight: 400;
    display: inline-flex;
    color: rgba(0, 0, 0, 0.87);
}
.x-spreadsheet-form-input input {
    z-index: 1;
    margin: 0;
    max-width: 100%;
    flex: 1 0 auto;
    outline: 0;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    text-align: left;
    line-height: 30px;
    height: 30px;
    padding: 0 8px;
    background: #fff;
    border: 1px solid #e9e9e9;
    border-radius: 3px;
    transition: box-shadow 0.1s ease, border-color 0.1s ease;
    box-shadow: inset 0 1px 2px hsla(0, 0%, 4%, 0.06);
}
.x-spreadsheet-form-input input:focus {
    border-color: #4b89ff;
    box-shadow: inset 0 1px 2px rgba(75, 137, 255, 0.2);
}
.x-spreadsheet-form-select {
    position: relative;
    display: inline-block;
    background: #fff;
    border: 1px solid #e9e9e9;
    border-radius: 2px;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.87);
    user-select: none;
    box-shadow: inset 0 1px 2px hsla(0, 0%, 4%, 0.06);
}
.x-spreadsheet-form-select .input-text {
    text-overflow: ellipsis;
    white-space: nowrap;
    min-width: 60px;
    width: auto;
    height: 30px;
    line-height: 30px;
    padding: 0 8px;
}
.x-spreadsheet-form-fields {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.x-spreadsheet-form-fields .x-spreadsheet-form-field {
    flex: 0 1 auto;
}
.x-spreadsheet-form-fields .x-spreadsheet-form-field .label {
    display: inline-block;
    margin: 0 10px 0 0;
}
.x-spreadsheet-form-field {
    display: block;
    /* vertical-align: middle; */
    margin-left: 10px;
    margin-bottom: 10px;
}
.x-spreadsheet-form-field:first-child {
    margin-left: 0;
}
.x-spreadsheet-form-field.error .x-spreadsheet-form-select,
.x-spreadsheet-form-field.error input {
    border-color: #f04134;
}
.x-spreadsheet-form-field .tip {
    color: #f04134;
    font-size: 0.9em;
}
.x-spreadsheet-dimmer {
    display: none;
    position: absolute;
    top: 0 !important;
    left: 0 !important;
    width: 100%;
    height: 100%;
    text-align: center;
    vertical-align: middle;
    background-color: rgba(0, 0, 0, 0.6);
    opacity: 0;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-duration: 0.5s;
    animation-duration: 0.5s;
    transition: background-color 0.5s linear;
    user-select: none;
    z-index: 1000;
}
.x-spreadsheet-dimmer.active {
    display: block;
    opacity: 1;
}
  form fieldset {
    border: none;
}
  form fieldset label {
    display: block;
    margin-bottom: 0.5em;
    font-size: 1em;
    color: #666;
}
  form fieldset select {
    font-size: 1.1em;
    width: 100%;
    background-color: #fff;
    border: none;
    border-bottom: 2px solid #ddd;
    padding: 0.5em 0.85em;
    border-radius: 2px;
}
.x-spreadsheet-modal,
.x-spreadsheet-toast {
    font-size: 13px;
    position: fixed;
    z-index: 1001;
    text-align: left;
    line-height: 1.25em;
    min-width: 360px;
    color: rgba(0, 0, 0, 0.87);
    font-family: 'Lato', 'Source Sans Pro', Roboto, Helvetica, Arial, sans-serif;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background-color: #fff;
    background-clip: padding-box;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 8px;
}
.x-spreadsheet-toast {
    background-color: rgba(255, 255, 255, 0.85);
}
.x-spreadsheet-modal-header,
.x-spreadsheet-toast-header {
    font-weight: 600;
    background-clip: padding-box;
    background-color: rgba(255, 255, 255, 0.85);
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: 4px 4px 0 0;
}
.x-spreadsheet-modal-header .x-spreadsheet-icon,
.x-spreadsheet-toast-header .x-spreadsheet-icon {
    position: absolute;
    right: 0.8em;
    top: 0.65em;
    border-radius: 18px;
}
.x-spreadsheet-modal-header .x-spreadsheet-icon:hover,
.x-spreadsheet-toast-header .x-spreadsheet-icon:hover {
    opacity: 1;
    background: rgba(0, 0, 0, 0.08);
}
.x-spreadsheet-toast-header {
    color: #F2711C;
}
.x-spreadsheet-modal-header {
    border-bottom: 1px solid #e0e2e4;
    background: rgba(0, 0, 0, 0.08);
    font-size: 1.0785em;
}
.x-spreadsheet-modal-header,
.x-spreadsheet-modal-content,
.x-spreadsheet-toast-header,
.x-spreadsheet-toast-content {
    padding: 0.75em 1em;
}
  @media screen and (min-width: 320px) and (max-width: 480px) {
  .x-spreadsheet-toolbar {
      display: none;
  }
}
.x-spreadsheet-icon {
    width: 18px;
    height: 18px;
    margin: 1px 1px 2px 1px;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    overflow: hidden;
    position: relative;
    display: inline-block;
}
.x-spreadsheet-icon .x-spreadsheet-icon-img {
    background-image: url(58eaeb4e52248a5c75936c6f4c33a370.svg);
    position: absolute;
    width: 262px;
    height: 444px;
    opacity: 0.56;
}
.x-spreadsheet-icon .x-spreadsheet-icon-img.undo {
    left: 0;
    top: 0;
}
.x-spreadsheet-icon .x-spreadsheet-icon-img.redo {
    left: -18px;
    top: 0;
}
.x-spreadsheet-icon .x-spreadsheet-icon-img.print {
    left: -36px;
    top: 0;
}
.x-spreadsheet-icon .x-spreadsheet-icon-img.paintformat {
    left: -54px;
    top: 0;
}
.x-spreadsheet-icon .x-spreadsheet-icon-img.clearformat {
    left: -72px;
    top: 0;
}
.x-spreadsheet-icon .x-spreadsheet-icon-img.font-bold {
    left: -90px;
    top: 0;
}
.x-spreadsheet-icon .x-spreadsheet-icon-img.font-italic {
    left: -108px;
    top: 0;
}
.x-spreadsheet-icon .x-spreadsheet-icon-img.underline {
    left: -126px;
    top: 0;
}
.x-spreadsheet-icon .x-spreadsheet-icon-img.strike {
    left: -144px;
    top: 0;
}
.x-spreadsheet-icon .x-spreadsheet-icon-img.color {
    left: -162px;
    top: 0;
}
.x-spreadsheet-icon .x-spreadsheet-icon-img.bgcolor {
    left: -180px;
    top: 0;
}
.x-spreadsheet-icon .x-spreadsheet-icon-img.merge {
    left: -198px;
    top: 0;
}
.x-spreadsheet-icon .x-spreadsheet-icon-img.align-left {
    left: -216px;
    top: 0;
}
.x-spreadsheet-icon .x-spreadsheet-icon-img.align-center {
    left: -234px;
    top: 0;
}
.x-spreadsheet-icon .x-spreadsheet-icon-img.align-right {
    left: 0;
    top: -18px;
}
.x-spreadsheet-icon .x-spreadsheet-icon-img.align-top {
    left: -18px;
    top: -18px;
}
.x-spreadsheet-icon .x-spreadsheet-icon-img.align-middle {
    left: -36px;
    top: -18px;
}
.x-spreadsheet-icon .x-spreadsheet-icon-img.align-bottom {
    left: -54px;
    top: -18px;
}
.x-spreadsheet-icon .x-spreadsheet-icon-img.textwrap {
    left: -72px;
    top: -18px;
}
.x-spreadsheet-icon .x-spreadsheet-icon-img.autofilter {
    left: -90px;
    top: -18px;
}
.x-spreadsheet-icon .x-spreadsheet-icon-img.formula {
    left: -108px;
    top: -18px;
}
.x-spreadsheet-icon .x-spreadsheet-icon-img.arrow-down {
    left: -126px;
    top: -18px;
}
.x-spreadsheet-icon .x-spreadsheet-icon-img.arrow-right {
    left: -144px;
    top: -18px;
}
.x-spreadsheet-icon .x-spreadsheet-icon-img.link {
    left: -162px;
    top: -18px;
}
.x-spreadsheet-icon .x-spreadsheet-icon-img.chart {
    left: -180px;
    top: -18px;
}
.x-spreadsheet-icon .x-spreadsheet-icon-img.freeze {
    left: -198px;
    top: -18px;
}
.x-spreadsheet-icon .x-spreadsheet-icon-img.ellipsis {
    left: -216px;
    top: -18px;
}
.x-spreadsheet-icon .x-spreadsheet-icon-img.add {
    left: -234px;
    top: -18px;
}
.x-spreadsheet-icon .x-spreadsheet-icon-img.border-all {
    left: 0;
    top: -36px;
}
.x-spreadsheet-icon .x-spreadsheet-icon-img.border-inside {
    left: -18px;
    top: -36px;
}
.x-spreadsheet-icon .x-spreadsheet-icon-img.border-horizontal {
    left: -36px;
    top: -36px;
}
.x-spreadsheet-icon .x-spreadsheet-icon-img.border-vertical {
    left: -54px;
    top: -36px;
}
.x-spreadsheet-icon .x-spreadsheet-icon-img.border-outside {
    left: -72px;
    top: -36px;
}
.x-spreadsheet-icon .x-spreadsheet-icon-img.border-left {
    left: -90px;
    top: -36px;
}
.x-spreadsheet-icon .x-spreadsheet-icon-img.border-top {
    left: -108px;
    top: -36px;
}
.x-spreadsheet-icon .x-spreadsheet-icon-img.border-right {
    left: -126px;
    top: -36px;
}
.x-spreadsheet-icon .x-spreadsheet-icon-img.border-bottom {
    left: -144px;
    top: -36px;
}
.x-spreadsheet-icon .x-spreadsheet-icon-img.border-none {
    left: -162px;
    top: -36px;
}
.x-spreadsheet-icon .x-spreadsheet-icon-img.line-color {
    left: -180px;
    top: -36px;
}
.x-spreadsheet-icon .x-spreadsheet-icon-img.line-type {
    left: -198px;
    top: -36px;
}
.x-spreadsheet-icon .x-spreadsheet-icon-img.close {
    left: -234px;
    top: -36px;
}
.x-spreadsheet-icon .x-spreadsheet-icon-img.chevron-down {
    left: 0;
    top: -54px;
}
.x-spreadsheet-icon .x-spreadsheet-icon-img.chevron-up {
    left: -18px;
    top: -54px;
}
.x-spreadsheet-icon .x-spreadsheet-icon-img.chevron-left {
    left: -36px;
    top: -54px;
}
.x-spreadsheet-icon .x-spreadsheet-icon-img.chevron-right {
    left: -54px;
    top: -54px;
}
  