@import "common";

$top: 55px;

.presentation-settings {
  //position: absolute;
  //left: -250px;
  //top: $top;
  //z-index: 500;
  display: flex;
  //width: 100%;
  //height: 100%;
  //opacity: 0;

  //transition: 300ms;

  &.visible {
    opacity: 1;
    left: 0px;
  }

  .presentation-settings-menubar {
    width: 250px;
    height: 100vh;
    background: #222;
    position: absolute;
    top: 0px;
    padding-top: 20px;
    display: grid;
    grid-row-gap: 5px;
    grid-auto-rows: min-content;
    z-index: 500;
    //box-shadow: 6px 0px 10px rgba(0, 0, 0, 0.2);
    //z-index: 10;

    .nav-item {
      cursor: pointer;
      display: flex;
      padding: 10px 14px 12px;
      border-left: solid 6px #222;

      & > i {
        color: #ddd;
        font-size: 20px;
        margin-right: 15px;
        color: white;
      }

      .text-block {
        margin-top: 2px;
        display: flex;
        flex-direction: column;
        width: 100%;
        //margin: 0px 15px 22px;
        .title {
          color: #ddd;
          font-size: 14px;
          font-weight: 600;
          letter-spacing: 1px;
          text-transform: uppercase;
        }

        .description {
          margin-top: 2px;
          color: #aaa;
          font-size: 13px;
          font-weight: 500;
          display: none;
        }
      }

      &.selected {
        background: $body_background;
        border-left: solid 6px $selectionColor;

        i {
          color: #fff;
        }

        .title {
          color: #fff;
        }

        .description {
          color: #aaa;
          display: block;
        }
      }

      &:hover {
        & > i {
          color: $selectionColor;
        }

        .title {
          color: $selectionColor;
        }
      }
    }
  }

  .pane {
    position: absolute;
    top: 0px;
    left: 270px;
    background: $body_background;
    width: calc(100vw - 280px);
    height: calc(100% - 50px);
    padding: 0px 10px 80px 30px;
    overflow-y: scroll;
    z-index: 1;
  }

  .ui-pane.publish-to-web-pane,
  .ui-pane.share-pane,
  .ui-pane.collaborate-pane {
    .privacy-button {
      padding: 10px 20px;
      /* height: 70px; */
      border: solid 1px #11a9ef;
      color: #333;
      display: flex;
      /* flex-direction: column; */
      align-items: center;
      justify-content: center;
      position: relative;
      border-radius: 40px;

      i {
        font-size: 30px;
        margin-right: 10px;
        color: #11a9ef;
      }

      label {
        text-align: center;
        font-size: 13px;
        font-weight: 600;
        text-transform: uppercase;
      }

      &.selected {
        background: $newBlue;

        i {
          color: white;
        }

        label {
          color: white;
        }
      }

      &:hover {
        background: $newBlue;

        i {
          color: white;
        }

        label {
          color: white;
        }
      }
    }

    .share-presentation-button {
      position: relative;
      //background: $selectionColor;
      display: flex;
      align-items: center;
      justify-content: center;
      //color: white;
      padding: 10px 20px;
      border: solid 1px $newBlue;

      i {
        margin-right: 10px;
        font-size: 30px;
        color: $newBlue;
      }

      label {
        //margin-top: 5px;
        //text-align: center;
        font-size: 13px;
        font-weight: 600;
        text-transform: uppercase;
        color: #333;
        //text-shadow: 0px 0px 7px rgba(0, 0, 0, 0.2);
      }

      &:hover {
        background: $newBlue;

        i {
          color: white;
        }

        label {
          color: white;
        }
      }

      &.disabled {
        opacity: 0.3;
        pointer-events: none;
      }
    }

    .button-description {
      color: #ddd;
      font-size: 12px;
      max-width: 200px;
      margin-top: 10px;
    }
  }

  .ui-pane.export-pane {
    .app-button {
      position: relative;
      background: $newBlue;
      display: flex;
      align-items: center;
      color: white;
      text-transform: uppercase;
      font-weight: 600;
      padding: 10px 20px 10px 10px;
      height: 50px;
      font-size: 15px;
      text-decoration: none;

      &:hover {
        background: $darkSelectionColor;
      }

      .app-button-logo {
        background: white;
        border-radius: 50%;
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 15px;

        img {
          height: 25px;
        }
      }
    }
  }

  .pane.analytics-pane {
    left: 250px;
  }
}

.warning-box {
  width: 100%;
  padding: 20px;
  background: rgba(0, 0, 0, 0.3);
  color: white;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
}

.trash-warning {
  padding: 10px 0;
  bottom: 0;
  background: orangered;
  color: white;
  position: absolute;
  left: 240px;
  white-space: nowrap;
  width: 100%;
  font-size: 16px;
}

.teams-list {
  display: flex;
  flex-wrap: wrap;

  .team-share-button {
    padding: 10px 14px;
    background: $selectionColor;
    color: white;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    margin-right: 10px;
    margin-bottom: 10px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 300px;

    &:hover {
      background: $darkSelectionColor;
    }

    &.disabled {
      pointer-events: none;
      opacity: 0.5;
    }
  }
}

.link-header {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  margin-left: 28px;
  padding: 8px 12px;

  .name {
    width: 250px;
    font-size: 13px;
    margin: 0px 10px;
    font-weight: 600;
  }

  .created {
    width: 120px;
    font-size: 13px;
    margin: 0px 10px;
    font-weight: 600;
  }

  .view-state {
    width: 100px;
    font-size: 13px;
    margin: 0px 10px;
    font-weight: 600;
  }
}

.links {
  position: relative;
  left: -20px;
  width: calc(100% + 40px);

  .link {
    color: #333;
    display: flex;
    align-items: center;
    position: relative;
    border-top: 1px solid #f0f0f0;
    padding: 6px 0px 6px 20px;
    min-height: 50px;

    & > button {
      opacity: 0;
      transition: opacity 300ms;
    }

    &.disabled-link {
      opacity: 0.5;

      .name {
        text-decoration: line-through;
      }
    }

    &:hover {
      .delete-button {
        opacity: 1;
      }

      background: #f0f0f0;

      & > button {
        opacity: 1;
      }
    }

    input {
      position: absolute;
      opacity: 0 !important;
    }

    .disabled-label {
      text-transform: uppercase;
      background: #aaa;
      color: black;
      font-size: 11px;
      padding: 8px 12px;
    }

    & > .micon,
    & > .avatar {
      margin: 0px 5px;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 15px;
    }

    & > .micon {
      color: white;
      background: #11a9ef;
      border-radius: 50%;
    }

    .name {
      width: 250px;
      font-size: 13px;
      margin: 0px 10px;
      font-weight: 600;
    }

    .expires {
      font-size: 13px;
      margin: 0px 10px;
      font-weight: 600;
    }

    .created {
      width: 120px;
      font-size: 12px;
      margin: 0px 10px;
      font-weight: 600;
    }

    .type {
      width: 100px;
      text-align: center;
      font-size: 12px;
      background: #333;
      padding: 8px 12px;
      text-transform: uppercase;
      font-weight: 600;
      margin: 0px 20px;
    }

    .view-state {
      width: 100px;
      font-size: 12px;
      margin: 0px 10px;
      font-weight: 600;
      color: #333;
      text-transform: uppercase;
      //padding: 6px 10px;
      &.viewed {
        color: $selectionColor;
      }
    }

    .delete-button {
      min-width: 20px;
      color: #aaa;
      cursor: pointer;
      opacity: 0;
      transition: opacity, color 0.2s ease;

      &:hover {
        color: #444;
      }

      .micon {
        font-size: 20px;
      }
    }
  }
}

.player_analytics {
  .links {
    .link {
      border-top: 1px solid #444;
    }
  }
}

.permissions-list {
  margin-left: -20px;
  width: calc(100% + 40px);
  color: #333;
  position: relative;
  overflow-y: auto;
  min-height: 50px;

  .permission {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 20px;
    border-top: 1px solid #f0f0f0;
    clear: both;

    > * > * {
      white-space: nowrap;
      margin: 10px;
    }

    &.empty {
      margin: 10px 0;
      text-align: center;
      width: 100%;
      display: block;
      color: #333;
    }

    &:hover {
      background: #f0f0f0;

      .resend-invite-button {
        opacity: 1;
      }
    }

    .avatar {
      min-width: 30px;
    }

    .name {
      //width: 100%;
      //width: 52.5%;
      width: 250px;

      .fullName {
        color: #222;
        font-weight: 600;
      }

      .email {
        font-size: 12px;
        color: #666;
        display: block;
      }
    }

    .owner {
      margin-left: 35px;
      font-weight: 600;
    }

    .current-user {
      margin-left: 28px;
    }

    .permission {
      min-width: 100px;
      margin-right: 10px;

      &.control.dropdown_menu_prompt .selected_item {
        min-width: 100px;
      }
    }

    .pending {
      min-width: 84px;
      margin-right: 10px;
      text-transform: uppercase;
      font-size: 10px;
      color: #555;
    }

    .resend-invite-button {
      opacity: 0;
      transition: opacity, color 0.2s ease;
    }
  }
}

.empty-list-warning {
  margin-top: 10px;
  margin-bottom: 10px;
  color: #555;
  font-weight: 600;
  font-size: 14px;
  //background: rgba(0, 0, 0, 0.1);
  //padding: 10px 20px;
  display: inline-block;
}

.is_mobile {
  .permissions-list {
    width: auto;
    margin-left: 0;
    max-height: 200px;

    .permission {
      width: auto;
      padding: 0;

      .name {
        width: calc(100vw - 237px);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .owner {
        margin-left: 10px;
      }

      .current-user {
        margin-left: 10px;
      }
    }
  }
}
