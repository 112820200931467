@import "common";


$transitionSpeed: 400ms;

.search-frame {
  padding: 20px;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1;
  //background: $lightGray;
  width: 100%;
  align-items: normal !important;

  .search-bar-container {
    padding: 0px 30px;
  }

  .search-input-spacer {
    height: 30vh;
    transition: $transitionSpeed;
  }

  .search-input {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    transition: $transitionSpeed;
    transform-origin: 0 0;
    background: white;
    padding: 10px;

    span {
      font-size: 30px;
      margin-right: 10px;
      margin-left: 5px;
    }

    input {
      width: 100%;
      font-size: 24px;
      border: none;
      outline: none;
      background: none;

      &::placeholder {
        font-weight: 500;
      }
    }
  }

  .slide-templates-list {
    transition: $transitionSpeed;
  }

  .divider {
    border-style: none;
    height: 1px;
    background: rgba(0, 0, 0, .2);
    margin: 30px 0px;
    width: 75%;
    margin-left: 12.5%;
    opacity: 1;
    transition: $transitionSpeed;
  }

  .shortcuts {
    display: flex;
    align-content: center;
    justify-content: flex-start;
    width: 100%;
    transition: $transitionSpeed;
    margin-bottom: 20px;
  }

  .browse-templates-label {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 10px;
    width: 100%;
    .browse-templates-button {
      text-transform: uppercase;
      font-weight: 600;
      font-size: 16px;
      color: white;
      border-radius: 6px;
      background: $newBlue;
      padding: 8px 16px;
      letter-spacing: 0.5px;
    }

    span {
      font-size: 40px;
      color: $newBlue;
    }
    opacity: 1;
    transition: $transitionSpeed;
  }

  .clear-search {
    &:hover {
      color: $newBlue;
    }
  }
}

.browse-slide-templates {
  position: relative;
  padding-bottom: 20px;
  opacity: 1;
}

.add-slide-container {
  .nav-label {
    text-align: left;
  }

  .ui-sub-navigation-item {
    background: #f1f1f1;
    padding: 10px 20px;
    color: #333;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1px;
    cursor: pointer;
    text-align: left;
    text-transform: uppercase;
    margin: 0px 0px 8px 0px;

    &:hover {
      color: white;
      background: #111;
    }

    &.selected {
      color: $selectionColor;
      background: #111;
    }
  }

  .button-bar {
    padding: 30px;
    background-color: #4b4e55;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .catheader {
    background: $lightGray;
    padding: 10px 20px;
    text-transform: uppercase;
    font-weight: 600;
    color: #333;
    margin-bottom: 30px;
    font-size: 15px;
    letter-spacing: 1px;
  }

  .category {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    margin-left: 20px;
  }

  .template_item {
    display: inline-block;
    vertical-align: top;
    margin-right: 30px;
    margin-bottom: 40px;
    width: 180px;
    position: relative;
    transition-duration: 0.3s;

    .thumbnail {
      width: 180px;
      height: 108px;
      background: white;
      opacity: 0;
      transition: opacity 333ms;
    }

    &.dev:after {
      content: "DEV ONLY";
      color: #ffffff;
      background: #e05355;
      font-size: 10px;
      font-weight: bold;
      padding: 2px;
      position: absolute;
      top: 0;
      left: 0;
      width: auto;
      height: auto;
    }

    &.coming-soon:after {
      content: "COMING SOON";
      color: #ffffff;
      background: #11a9e2;
      font-size: 11px;
      font-weight: bold;
      padding: 5px 12px;
      position: absolute;
      top: -12px;
      left: 37px;
      width: auto;
      height: auto;
      box-shadow: none !important;
      border: none !important;
    }

    &.coming-soon:hover {
      transform: none;
    }

    &.coming-soon {
      img {
        opacity: 0.5;
      }
    }

    img {
      box-sizing: content-box;
      width: 180px;
    }

    &:after {
      content: "";
      width: 178px;
      height: 106px;
      top: 0px;
      left: 0px;
      position: absolute;
      z-index: 1;
      transition-duration: 0.3s;
      border: solid 1px rgba(255, 255, 255, 0);
    }

    &:hover {
      transform: scale(1.1);

      &:after {
        border: solid 1px $selectionColor;
      }
    }

    &:active {
      transition-duration: 0.1s;
      transform: scale(1.06);

      &:after {
        border: solid 1px $selectionColor;
      }
    }

    .info {
      margin-top: 6px;
      text-align: left;
      width: 100%;

      .title {
        font-size: 12px;
        font-weight: 600;
        color: #eee;
        text-align: center;
        text-transform: uppercase;
        transition: text-shadow 0.3s;
        letter-spacing: 0.75px;
      }

      .subtitle {
        font-size: 14px;
        color: #777;
        font-weight: 500;
        font-style: italic;
        text-align: center;
      }

      .description {
        margin-top: 2px;
        font-size: 13px;
        letter-spacing: 0px;
        display: none;
        color: #333;
        font-weight: 400;
        opacity: 0.4;
      }

      i.edit_template {
        position: absolute;
        bottom: 2px;
        right: 20px;
        font-size: 14px;
        display: none;
      }

      i.delete_template {
        position: absolute;
        bottom: 2px;
        right: 0px;
        font-size: 14px;
        display: none;
      }
    }
  }
}
