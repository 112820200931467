@import url(material-ui-font.scss);

$minUIWidth: 600px;
$minUIHeight: 400px;


$ui_font: "Source Sans Pro";

$background: #8b9daa;
$body_background: darken(#595d65, 6%);
$dark_background: #4b5b67;
$border_color: #979797;

$menu_background: #333;
$menu_popup_background: white;
$menu_popup_border: #bbb;

$newBlue: #11a9e2;
$lightGray: #eee;
$darkGray: #222;
$lightBlue: #DAE9F0;
$hoverBlue: #F7FAFC;
$warning: orangered;

$selectionColor: #50bbe6;
$darkSelectionColor: darken($selectionColor, 10%);
$lightSelectionColor: lighten($selectionColor, 30%);

$ui_border_color_disabled: #e1e1e1;
$ui_border_color: darken($selectionColor, 10%);
$ui_border: solid 1px $ui_border_color;

$ui_hover_color: #e9e9e9;

$ui_text_color: #11a9e2;
$ui_focused_text_color: white;

$ui_gray_color: #777;
$ui_gray_border: solid 1px #ccc;

$ui_color: #f1f1f1;
$ui_muted_color: #777;
$ui_focused_color: #11a9e2;
$ui_background_color: #50bbe6;

$menu_background_color: #222;
$menu_text_color: white;

$editor_border: solid 1px white;

$box_shadow: 0px 0px 3px rgba(0, 0, 0, 0.15);

$text_shadow: 0px 0px 3px rgba(0, 0, 0, 0.15);

$button_color: #4aa4c9;
$button_text_color: white;

@mixin close_icon {
    font-family: "Material Icons";
    content: "\E5CD";
}

@mixin no_user_select {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

@mixin alignable {
    &:before {
        content: "";
        height: 100%;
        display: inline-block;
        vertical-align: middle;
    }
}

@mixin font-icon($code) {
    &:before {
        font-family: "Material Icons";
        content: $code;
        vertical-align: -5px;
        font-size: 18px;
        margin-right: 3px;
        font-weight: normal;
    }
}
