@import "common";

$thumbnail-width: 250px;
$thumbnail-height: calc((#{$thumbnail-width} * 9) / 16);

.ui-pane.select-presentation-pane {
    background: $body_background !important;
    //position: relative;
    //height: 100%;
    //width: 100%;
    .ui-inner-pane {
        padding: 0px;
    }

    .content-wrapper.presentations {
        overflow-y: scroll;
        height: 100%;
        padding: 30px;
        width: 100%;

        .presentation-folders {
            width: 180px;
            float: left;
            margin-right: 50px;
        }

        .presentation-list-container {
            display: flex;
            flex-wrap: wrap;

            .presentation-item-wrapper {
                display: flex;
                flex-direction: column;
                align-items: center;
                color: #ddd;
                font-weight: 600;
                margin-right: 30px;
                margin-bottom: 30px;
                width: $thumbnail-width;

                .thumbnail-wrapper {
                    height: $thumbnail-height;
                    margin-bottom: 10px;
                    position: relative;
                    background: black;

                    img {
                        height: $thumbnail-height;
                        width: $thumbnail-width;
                    }
                }

                .title {
                    font-size: 16px;
                    font-weight: 500;
                    text-overflow: ellipsis;
                    width: 100%;
                    white-space: nowrap;
                    overflow: hidden;
                    text-align: center;
                }
            }
        }
    }
}

.presentation-list-container {
    display: flex;
    flex-wrap: wrap;
    overflow-y: scroll;

    .presentation-item-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #ddd;
        font-weight: 600;
        margin-right: 30px;
        margin-bottom: 30px;
        width: $thumbnail-width;

        .thumbnail-wrapper {
            height: $thumbnail-height;
            margin-bottom: 10px;
            position: relative;
            background: black;

            img {
                height: $thumbnail-height;
                width: $thumbnail-width;
            }
        }

        .title {
            font-size: 16px;
            font-weight: 500;
            text-overflow: ellipsis;
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-align: center;
        }
    }
}

.library-list {
    margin-top: 10px;
}

.presentation-menu-section {
    color: rgba(255, 255, 255, 0.7);
    font-size: 10px;
    text-transform: uppercase;
    margin: 14px 0px 10px;
}

.presentation_menu_item {
    position: relative;
    cursor: pointer;
    padding: 10px 10px;
    //margin-bottom: 5px;
    //background: rgba(0, 0, 0, 0.1);
    color: #f1f1f1;
    font-size: 12px;
    font-weight: 600;
    display: flex;
    letter-spacing: 1px;
    align-items: center;
    text-shadow: 0px 0px 6px rgba(0, 0, 0, 0.4);

    &.filter_item {
        text-transform: uppercase;
        i {
            margin-right: 10px;
            position: relative;
            top: 1px;
            font-size: 20px;
        }
    }

    &.folder_item {
        i {
            display: block;
            font-size: 18px;
            margin-right: 10px;
            transition: transform 200ms;

            &.more {
                display: none;
                position: absolute;
                top: 10px;
                right: 0px;
                &.selected {
                    display: block;
                }
            }
        }
        &.selected {
            i.folder_arrow {
                // transform: rotate(90deg);
            }
        }
    }

    .label {
        width: calc(100% - 30px);
        overflow-x: hidden;
    }

    &:hover {
        color: $selectionColor;
        i {
            color: $selectionColor;
            &.more {
                display: block;
            }
        }
    }

    &.selected {
        color: $selectionColor;
        //background: #111;
        i {
            color: $selectionColor;
        }
    }

    &.drop_hover {
        color: $selectionColor;
        i {
            color: $selectionColor;
        }
    }
}
