/*! jQuery UI - v1.11.4 - 2015-12-04
* http://jqueryui.com
* Includes: core.css, datepicker.css, theme.css
* To view and modify this theme, visit http://jqueryui.com/themeroller/?ffDefault=Source%20Sans%20Pro&fwDefault=bold&fsDefault=1.3em&cornerRadius=0px&bgColorHeader=%23dddddd&bgTextureHeader=glass&bgImgOpacityHeader=35&borderColorHeader=%23bbbbbb&fcHeader=%23444444&iconColorHeader=%23999999&bgColorContent=%23c9c9c9&bgTextureContent=inset_soft&bgImgOpacityContent=50&borderColorContent=%23aaaaaa&fcContent=%23333333&iconColorContent=%23999999&bgColorDefault=%23eeeeee&bgTextureDefault=glass&bgImgOpacityDefault=60&borderColorDefault=%23cccccc&fcDefault=%233383bb&iconColorDefault=%2370b2e1&bgColorHover=%23f8f8f8&bgTextureHover=glass&bgImgOpacityHover=100&borderColorHover=%23bbbbbb&fcHover=%23599fcf&iconColorHover=%233383bb&bgColorActive=%23999999&bgTextureActive=inset_hard&bgImgOpacityActive=75&borderColorActive=%23999999&fcActive=%23ffffff&iconColorActive=%23454545&bgColorHighlight=%23eeeeee&bgTextureHighlight=flat&bgImgOpacityHighlight=55&borderColorHighlight=%23ffffff&fcHighlight=%23444444&iconColorHighlight=%233383bb&bgColorError=%23c0402a&bgTextureError=flat&bgImgOpacityError=55&borderColorError=%23c0402a&fcError=%23ffffff&iconColorError=%23fbc856&bgColorOverlay=%23eeeeee&bgTextureOverlay=flat&bgImgOpacityOverlay=0&opacityOverlay=80&bgColorShadow=%23aaaaaa&bgTextureShadow=flat&bgImgOpacityShadow=0&opacityShadow=60&thicknessShadow=4px&offsetTopShadow=-4px&offsetLeftShadow=-4px&cornerRadiusShadow=0px
* Copyright jQuery Foundation and other contributors; Licensed MIT */

/* Layout helpers
----------------------------------*/
.ui-helper-hidden {
    display: none;
}
.ui-helper-hidden-accessible {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}
.ui-helper-reset {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    line-height: 1.3;
    text-decoration: none;
    font-size: 100%;
    list-style: none;
}
.ui-helper-clearfix:before,
.ui-helper-clearfix:after {
    content: "";
    display: table;
    border-collapse: collapse;
}
.ui-helper-clearfix:after {
    clear: both;
}
.ui-helper-clearfix {
    min-height: 0; /* support: IE7 */
}
.ui-helper-zfix {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    opacity: 0;
    filter: Alpha(Opacity=0); /* support: IE8 */
}

.ui-front {
    z-index: 100;
}

/* Interaction Cues
----------------------------------*/
.ui-state-disabled {
    cursor: default !important;
}

/* Icons
----------------------------------*/

/* states and images */
.ui-icon {
    display: block;
    text-indent: -99999px;
    overflow: hidden;
    background-repeat: no-repeat;
}

/* Misc visuals
----------------------------------*/

/* Overlays */
.ui-widget-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.ui-datepicker {
    width: 17em;
    padding: 0.2em 0.2em 0;
    display: none;
    z-index: 99999 !important;
    background: white !important;
}
.ui-datepicker .ui-datepicker-header {
    position: relative;
    padding: 0.2em 0;
    background: #11a9e2;
    color: white;
    border: none;
}
.ui-datepicker .ui-datepicker-prev,
.ui-datepicker .ui-datepicker-next {
    position: absolute;
    top: 2px;
    width: 1.8em;
    height: 1.8em;
}
.ui-datepicker .ui-datepicker-prev-hover,
.ui-datepicker .ui-datepicker-next-hover {
    background: #11A9E2 !important;
    border:none !important;
}
.ui-datepicker .ui-datepicker-prev {
    left: 2px;
}
.ui-datepicker .ui-datepicker-next {
    right: 2px;
}
.ui-datepicker-next:before {
    font-family: "Material Icons";
    content: "keyboard_arrow_right";
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    color: white;
}

.ui-datepicker-prev:before {
    font-family: "Material Icons";
    content: "keyboard_arrow_left";
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    color: white;
}

.ui-datepicker .ui-datepicker-prev span,
.ui-datepicker .ui-datepicker-next span {
    display: block;
    position: absolute;
    left: 50%;
    margin-left: -8px;
    top: 50%;
    margin-top: -8px;
}
.ui-datepicker .ui-datepicker-title {
    margin: 0 2.3em;
    line-height: 1.8em;
    text-align: center;
}
.ui-datepicker .ui-datepicker-title select {
    font-size: 1em;
    margin: 1px 0;
}
.ui-datepicker select.ui-datepicker-month,
.ui-datepicker select.ui-datepicker-year {
    width: 45%;
}
.ui-datepicker table {
    width: 100%;
    font-size: 0.9em;
    border-collapse: collapse;
    margin: 0 0 0.4em;
}
.ui-datepicker th {
    padding: 0.7em 0.3em;
    text-align: center;
    font-weight: bold;
    border: 0;
}
.ui-datepicker td {
    border: 0;
    padding: 1px;
}

.ui-datepicker td span,
.ui-datepicker td a {
    display: block;
    text-align: right;
    text-decoration: none;
    border: none !important;
    padding: 5px !important;
    text-align: center !important;
}
.ui-datepicker .ui-datepicker-buttonpane {
    background-image: none;
    margin: 0.7em 0 0 0;
    padding: 0 0.2em;
    border-left: 0;
    border-right: 0;
    border-bottom: 0;
}
.ui-datepicker .ui-datepicker-buttonpane button {
    float: right;
    margin: 0.5em 0.2em 0.4em;
    cursor: pointer;
    padding: 0.2em 0.6em 0.3em 0.6em;
    width: auto;
    overflow: visible;
}
.ui-datepicker .ui-datepicker-buttonpane button.ui-datepicker-current {
    float: left;
}

/* with multiple calendars */
.ui-datepicker.ui-datepicker-multi {
    width: auto;
}
.ui-datepicker-multi .ui-datepicker-group {
    float: left;
}
.ui-datepicker-multi .ui-datepicker-group table {
    width: 95%;
    margin: 0 auto 0.4em;
}
.ui-datepicker-multi-2 .ui-datepicker-group {
    width: 50%;
}
.ui-datepicker-multi-3 .ui-datepicker-group {
    width: 33.3%;
}
.ui-datepicker-multi-4 .ui-datepicker-group {
    width: 25%;
}
.ui-datepicker-multi .ui-datepicker-group-last .ui-datepicker-header,
.ui-datepicker-multi .ui-datepicker-group-middle .ui-datepicker-header {
    border-left-width: 0;
}
.ui-datepicker-multi .ui-datepicker-buttonpane {
    clear: left;
}
.ui-datepicker-row-break {
    clear: both;
    width: 100%;
    font-size: 0;
}

/* RTL support */
.ui-datepicker-rtl {
    direction: rtl;
}
.ui-datepicker-rtl .ui-datepicker-prev {
    right: 2px;
    left: auto;
}
.ui-datepicker-rtl .ui-datepicker-next {
    left: 2px;
    right: auto;
}
.ui-datepicker-rtl .ui-datepicker-prev:hover {
    right: 1px;
    left: auto;
}
.ui-datepicker-rtl .ui-datepicker-next:hover {
    left: 1px;
    right: auto;
}
.ui-datepicker-rtl .ui-datepicker-buttonpane {
    clear: right;
}
.ui-datepicker-rtl .ui-datepicker-buttonpane button {
    float: left;
}
.ui-datepicker-rtl .ui-datepicker-buttonpane button.ui-datepicker-current,
.ui-datepicker-rtl .ui-datepicker-group {
    float: right;
}
.ui-datepicker-rtl .ui-datepicker-group-last .ui-datepicker-header,
.ui-datepicker-rtl .ui-datepicker-group-middle .ui-datepicker-header {
    border-right-width: 0;
    border-left-width: 1px;
}

/* Component Panes
----------------------------------*/
.ui-widget {
    font-family: "Source Sans Pro";
    font-size: 1.3em;
}
.ui-widget .ui-widget {
    font-size: 1em;
}
.ui-widget input,
.ui-widget select,
.ui-widget textarea,
.ui-widget button {
    font-family: "Source Sans Pro";
    font-size: 1em;
}
.ui-widget-content {
    border: 1px solid #aaaaaa;
    background: #c9c9c9 url("images/ui-bg_inset-soft_50_c9c9c9_1x100.png") 50%
        bottom repeat-x;
    color: #333333;
}
.ui-widget-content a {
    color: #333333;
}
.ui-widget-header {
    border: 1px solid #bbbbbb;
    background: #dddddd url("images/ui-bg_glass_35_dddddd_1x400.png") 50% 50%
        repeat-x;
    color: #444444;
    font-weight: bold;
}
.ui-widget-header a {
    color: #444444;
}

/* Interaction states
----------------------------------*/
.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default {
    background: #f1f1f1;
    font-weight: bold;
    color: #11a9e2;
}
.ui-state-default a,
.ui-state-default a:link,
.ui-state-default a:visited {
    color: #11a9e2;
    text-decoration: none;
}
.ui-state-hover,
.ui-widget-content .ui-state-hover,
.ui-widget-header .ui-state-hover,
.ui-state-focus,
.ui-widget-content .ui-state-focus,
.ui-widget-header .ui-state-focus {
    border: 1px solid #bbbbbb;
    background: #f8f8f8 url("images/ui-bg_glass_100_f8f8f8_1x400.png") 50% 50%
        repeat-x;
    font-weight: bold;
    color: #599fcf;
}
.ui-state-hover a,
.ui-state-hover a:hover,
.ui-state-hover a:link,
.ui-state-hover a:visited,
.ui-state-focus a,
.ui-state-focus a:hover,
.ui-state-focus a:link,
.ui-state-focus a:visited {
    color: #599fcf;
    text-decoration: none;
}
.ui-state-active,
.ui-widget-content .ui-state-active,
.ui-widget-header .ui-state-active {
    /*border: 1px solid #999999;*/
    /*background: #999999 url("images/ui-bg_inset-hard_75_999999_1x100.png") 50%*/
    /*    50% repeat-x;*/
    font-weight: bold;

    /* ensure active styling is prioritized */
    color: #ffffff !important;
    background: #11A9E2 !important;
}
.ui-state-active a,
.ui-state-active a:link,
.ui-state-active a:visited {
    color: #ffffff;
    text-decoration: none;
}

/* Interaction Cues
----------------------------------*/
.ui-state-highlight,
.ui-widget-content .ui-state-highlight,
.ui-widget-header .ui-state-highlight {
    border: 1px solid #ffffff;
    background: #eeeeee;
    color: #444444;
}
.ui-state-highlight a,
.ui-widget-content .ui-state-highlight a,
.ui-widget-header .ui-state-highlight a {
    color: #444444;
}
.ui-state-error,
.ui-widget-content .ui-state-error,
.ui-widget-header .ui-state-error {
    border: 1px solid #c0402a;
    background: #c0402a;
    color: #ffffff;
}
.ui-state-error a,
.ui-widget-content .ui-state-error a,
.ui-widget-header .ui-state-error a {
    color: #ffffff;
}
.ui-state-error-text,
.ui-widget-content .ui-state-error-text,
.ui-widget-header .ui-state-error-text {
    color: #ffffff;
}
.ui-priority-primary,
.ui-widget-content .ui-priority-primary,
.ui-widget-header .ui-priority-primary {
    font-weight: bold;
}
.ui-priority-secondary,
.ui-widget-content .ui-priority-secondary,
.ui-widget-header .ui-priority-secondary {
    opacity: 0.7;
    filter: Alpha(Opacity=70); /* support: IE8 */
    font-weight: normal;
}
.ui-state-disabled,
.ui-widget-content .ui-state-disabled,
.ui-widget-header .ui-state-disabled {
    opacity: 0.35;
    filter: Alpha(Opacity=35); /* support: IE8 */
    background-image: none;
}
.ui-state-disabled .ui-icon {
    filter: Alpha(Opacity=35); /* support: IE8 - See #6059 */
}

/* Icons
----------------------------------*/

/* states and images */
.ui-icon {
    width: 16px;
    height: 16px;
}
.ui-icon,
.ui-widget-content .ui-icon {
    background-image: url("images/ui-icons_999999_256x240.png");
}
.ui-widget-header .ui-icon {
    background-image: url("images/ui-icons_999999_256x240.png");
}
.ui-state-default .ui-icon {
    background-image: url("images/ui-icons_70b2e1_256x240.png");
}
.ui-state-hover .ui-icon,
.ui-state-focus .ui-icon {
    background-image: url("images/ui-icons_3383bb_256x240.png");
}
.ui-state-active .ui-icon {
    background-image: url("images/ui-icons_454545_256x240.png");
}
.ui-state-highlight .ui-icon {
    background-image: url("images/ui-icons_3383bb_256x240.png");
}
.ui-state-error .ui-icon,
.ui-state-error-text .ui-icon {
    background-image: url("images/ui-icons_fbc856_256x240.png");
}

/* positioning */
.ui-icon-blank {
    background-position: 16px 16px;
}
.ui-icon-carat-1-n {
    background-position: 0 0;
}
.ui-icon-carat-1-ne {
    background-position: -16px 0;
}
.ui-icon-carat-1-e {
    background-position: -32px 0;
}
.ui-icon-carat-1-se {
    background-position: -48px 0;
}
.ui-icon-carat-1-s {
    background-position: -64px 0;
}
.ui-icon-carat-1-sw {
    background-position: -80px 0;
}
.ui-icon-carat-1-w {
    background-position: -96px 0;
}
.ui-icon-carat-1-nw {
    background-position: -112px 0;
}
.ui-icon-carat-2-n-s {
    background-position: -128px 0;
}
.ui-icon-carat-2-e-w {
    background-position: -144px 0;
}
.ui-icon-triangle-1-n {
    background-position: 0 -16px;
}
.ui-icon-triangle-1-ne {
    background-position: -16px -16px;
}
.ui-icon-triangle-1-e {
    background-position: -32px -16px;
}
.ui-icon-triangle-1-se {
    background-position: -48px -16px;
}
.ui-icon-triangle-1-s {
    background-position: -64px -16px;
}
.ui-icon-triangle-1-sw {
    background-position: -80px -16px;
}
.ui-icon-triangle-1-w {
    background-position: -96px -16px;
}
.ui-icon-triangle-1-nw {
    background-position: -112px -16px;
}
.ui-icon-triangle-2-n-s {
    background-position: -128px -16px;
}
.ui-icon-triangle-2-e-w {
    background-position: -144px -16px;
}
.ui-icon-arrow-1-n {
    background-position: 0 -32px;
}
.ui-icon-arrow-1-ne {
    background-position: -16px -32px;
}
.ui-icon-arrow-1-e {
    background-position: -32px -32px;
}
.ui-icon-arrow-1-se {
    background-position: -48px -32px;
}
.ui-icon-arrow-1-s {
    background-position: -64px -32px;
}
.ui-icon-arrow-1-sw {
    background-position: -80px -32px;
}
.ui-icon-arrow-1-w {
    background-position: -96px -32px;
}
.ui-icon-arrow-1-nw {
    background-position: -112px -32px;
}
.ui-icon-arrow-2-n-s {
    background-position: -128px -32px;
}
.ui-icon-arrow-2-ne-sw {
    background-position: -144px -32px;
}
.ui-icon-arrow-2-e-w {
    background-position: -160px -32px;
}
.ui-icon-arrow-2-se-nw {
    background-position: -176px -32px;
}
.ui-icon-arrowstop-1-n {
    background-position: -192px -32px;
}
.ui-icon-arrowstop-1-e {
    background-position: -208px -32px;
}
.ui-icon-arrowstop-1-s {
    background-position: -224px -32px;
}
.ui-icon-arrowstop-1-w {
    background-position: -240px -32px;
}
.ui-icon-arrowthick-1-n {
    background-position: 0 -48px;
}
.ui-icon-arrowthick-1-ne {
    background-position: -16px -48px;
}
.ui-icon-arrowthick-1-e {
    background-position: -32px -48px;
}
.ui-icon-arrowthick-1-se {
    background-position: -48px -48px;
}
.ui-icon-arrowthick-1-s {
    background-position: -64px -48px;
}
.ui-icon-arrowthick-1-sw {
    background-position: -80px -48px;
}
.ui-icon-arrowthick-1-w {
    background-position: -96px -48px;
}
.ui-icon-arrowthick-1-nw {
    background-position: -112px -48px;
}
.ui-icon-arrowthick-2-n-s {
    background-position: -128px -48px;
}
.ui-icon-arrowthick-2-ne-sw {
    background-position: -144px -48px;
}
.ui-icon-arrowthick-2-e-w {
    background-position: -160px -48px;
}
.ui-icon-arrowthick-2-se-nw {
    background-position: -176px -48px;
}
.ui-icon-arrowthickstop-1-n {
    background-position: -192px -48px;
}
.ui-icon-arrowthickstop-1-e {
    background-position: -208px -48px;
}
.ui-icon-arrowthickstop-1-s {
    background-position: -224px -48px;
}
.ui-icon-arrowthickstop-1-w {
    background-position: -240px -48px;
}
.ui-icon-arrowreturnthick-1-w {
    background-position: 0 -64px;
}
.ui-icon-arrowreturnthick-1-n {
    background-position: -16px -64px;
}
.ui-icon-arrowreturnthick-1-e {
    background-position: -32px -64px;
}
.ui-icon-arrowreturnthick-1-s {
    background-position: -48px -64px;
}
.ui-icon-arrowreturn-1-w {
    background-position: -64px -64px;
}
.ui-icon-arrowreturn-1-n {
    background-position: -80px -64px;
}
.ui-icon-arrowreturn-1-e {
    background-position: -96px -64px;
}
.ui-icon-arrowreturn-1-s {
    background-position: -112px -64px;
}
.ui-icon-arrowrefresh-1-w {
    background-position: -128px -64px;
}
.ui-icon-arrowrefresh-1-n {
    background-position: -144px -64px;
}
.ui-icon-arrowrefresh-1-e {
    background-position: -160px -64px;
}
.ui-icon-arrowrefresh-1-s {
    background-position: -176px -64px;
}
.ui-icon-arrow-4 {
    background-position: 0 -80px;
}
.ui-icon-arrow-4-diag {
    background-position: -16px -80px;
}
.ui-icon-extlink {
    background-position: -32px -80px;
}
.ui-icon-newwin {
    background-position: -48px -80px;
}
.ui-icon-refresh {
    background-position: -64px -80px;
}
.ui-icon-shuffle {
    background-position: -80px -80px;
}
.ui-icon-transfer-e-w {
    background-position: -96px -80px;
}
.ui-icon-transferthick-e-w {
    background-position: -112px -80px;
}
.ui-icon-folder-collapsed {
    background-position: 0 -96px;
}
.ui-icon-folder-open {
    background-position: -16px -96px;
}
.ui-icon-document {
    background-position: -32px -96px;
}
.ui-icon-document-b {
    background-position: -48px -96px;
}
.ui-icon-note {
    background-position: -64px -96px;
}
.ui-icon-mail-closed {
    background-position: -80px -96px;
}
.ui-icon-mail-open {
    background-position: -96px -96px;
}
.ui-icon-suitcase {
    background-position: -112px -96px;
}
.ui-icon-comment {
    background-position: -128px -96px;
}
.ui-icon-person {
    background-position: -144px -96px;
}
.ui-icon-print {
    background-position: -160px -96px;
}
.ui-icon-trash {
    background-position: -176px -96px;
}
.ui-icon-locked {
    background-position: -192px -96px;
}
.ui-icon-unlocked {
    background-position: -208px -96px;
}
.ui-icon-bookmark {
    background-position: -224px -96px;
}
.ui-icon-tag {
    background-position: -240px -96px;
}
.ui-icon-home {
    background-position: 0 -112px;
}
.ui-icon-flag {
    background-position: -16px -112px;
}
.ui-icon-calendar {
    background-position: -32px -112px;
}
.ui-icon-cart {
    background-position: -48px -112px;
}
.ui-icon-pencil {
    background-position: -64px -112px;
}
.ui-icon-clock {
    background-position: -80px -112px;
}
.ui-icon-disk {
    background-position: -96px -112px;
}
.ui-icon-calculator {
    background-position: -112px -112px;
}
.ui-icon-zoomin {
    background-position: -128px -112px;
}
.ui-icon-zoomout {
    background-position: -144px -112px;
}
.ui-icon-search {
    background-position: -160px -112px;
}
.ui-icon-wrench {
    background-position: -176px -112px;
}
.ui-icon-gear {
    background-position: -192px -112px;
}
.ui-icon-heart {
    background-position: -208px -112px;
}
.ui-icon-star {
    background-position: -224px -112px;
}
.ui-icon-link {
    background-position: -240px -112px;
}
.ui-icon-cancel {
    background-position: 0 -128px;
}
.ui-icon-plus {
    background-position: -16px -128px;
}
.ui-icon-plusthick {
    background-position: -32px -128px;
}
.ui-icon-minus {
    background-position: -48px -128px;
}
.ui-icon-minusthick {
    background-position: -64px -128px;
}
.ui-icon-close {
    background-position: -80px -128px;
}
.ui-icon-closethick {
    background-position: -96px -128px;
}
.ui-icon-key {
    background-position: -112px -128px;
}
.ui-icon-lightbulb {
    background-position: -128px -128px;
}
.ui-icon-scissors {
    background-position: -144px -128px;
}
.ui-icon-clipboard {
    background-position: -160px -128px;
}
.ui-icon-copy {
    background-position: -176px -128px;
}
.ui-icon-contact {
    background-position: -192px -128px;
}
.ui-icon-image {
    background-position: -208px -128px;
}
.ui-icon-video {
    background-position: -224px -128px;
}
.ui-icon-script {
    background-position: -240px -128px;
}
.ui-icon-alert {
    background-position: 0 -144px;
}
.ui-icon-info {
    background-position: -16px -144px;
}
.ui-icon-notice {
    background-position: -32px -144px;
}
.ui-icon-help {
    background-position: -48px -144px;
}
.ui-icon-check {
    background-position: -64px -144px;
}
.ui-icon-bullet {
    background-position: -80px -144px;
}
.ui-icon-radio-on {
    background-position: -96px -144px;
}
.ui-icon-radio-off {
    background-position: -112px -144px;
}
.ui-icon-pin-w {
    background-position: -128px -144px;
}
.ui-icon-pin-s {
    background-position: -144px -144px;
}
.ui-icon-play {
    background-position: 0 -160px;
}
.ui-icon-pause {
    background-position: -16px -160px;
}
.ui-icon-seek-next {
    background-position: -32px -160px;
}
.ui-icon-seek-prev {
    background-position: -48px -160px;
}
.ui-icon-seek-end {
    background-position: -64px -160px;
}
.ui-icon-seek-start {
    background-position: -80px -160px;
}
/* ui-icon-seek-first is deprecated, use ui-icon-seek-start instead */
.ui-icon-seek-first {
    background-position: -80px -160px;
}
.ui-icon-stop {
    background-position: -96px -160px;
}
.ui-icon-eject {
    background-position: -112px -160px;
}
.ui-icon-volume-off {
    background-position: -128px -160px;
}
.ui-icon-volume-on {
    background-position: -144px -160px;
}
.ui-icon-power {
    background-position: 0 -176px;
}
.ui-icon-signal-diag {
    background-position: -16px -176px;
}
.ui-icon-signal {
    background-position: -32px -176px;
}
.ui-icon-battery-0 {
    background-position: -48px -176px;
}
.ui-icon-battery-1 {
    background-position: -64px -176px;
}
.ui-icon-battery-2 {
    background-position: -80px -176px;
}
.ui-icon-battery-3 {
    background-position: -96px -176px;
}
.ui-icon-circle-plus {
    background-position: 0 -192px;
}
.ui-icon-circle-minus {
    background-position: -16px -192px;
}
.ui-icon-circle-close {
    background-position: -32px -192px;
}
.ui-icon-circle-triangle-e {
    background-position: -48px -192px;
}
.ui-icon-circle-triangle-s {
    background-position: -64px -192px;
}
.ui-icon-circle-triangle-w {
    background-position: -80px -192px;
}
.ui-icon-circle-triangle-n {
    background-position: -96px -192px;
}
.ui-icon-circle-arrow-e {
    background-position: -112px -192px;
}
.ui-icon-circle-arrow-s {
    background-position: -128px -192px;
}
.ui-icon-circle-arrow-w {
    background-position: -144px -192px;
}
.ui-icon-circle-arrow-n {
    background-position: -160px -192px;
}
.ui-icon-circle-zoomin {
    background-position: -176px -192px;
}
.ui-icon-circle-zoomout {
    background-position: -192px -192px;
}
.ui-icon-circle-check {
    background-position: -208px -192px;
}
.ui-icon-circlesmall-plus {
    background-position: 0 -208px;
}
.ui-icon-circlesmall-minus {
    background-position: -16px -208px;
}
.ui-icon-circlesmall-close {
    background-position: -32px -208px;
}
.ui-icon-squaresmall-plus {
    background-position: -48px -208px;
}
.ui-icon-squaresmall-minus {
    background-position: -64px -208px;
}
.ui-icon-squaresmall-close {
    background-position: -80px -208px;
}
.ui-icon-grip-dotted-vertical {
    background-position: 0 -224px;
}
.ui-icon-grip-dotted-horizontal {
    background-position: -16px -224px;
}
.ui-icon-grip-solid-vertical {
    background-position: -32px -224px;
}
.ui-icon-grip-solid-horizontal {
    background-position: -48px -224px;
}
.ui-icon-gripsmall-diagonal-se {
    background-position: -64px -224px;
}
.ui-icon-grip-diagonal-se {
    background-position: -80px -224px;
}

/* Misc visuals
----------------------------------*/

/* Corner radius */
.ui-corner-all,
.ui-corner-top,
.ui-corner-left,
.ui-corner-tl {
    border-top-left-radius: 0px;
}
.ui-corner-all,
.ui-corner-top,
.ui-corner-right,
.ui-corner-tr {
    border-top-right-radius: 0px;
}
.ui-corner-all,
.ui-corner-bottom,
.ui-corner-left,
.ui-corner-bl {
    border-bottom-left-radius: 0px;
}
.ui-corner-all,
.ui-corner-bottom,
.ui-corner-right,
.ui-corner-br {
    border-bottom-right-radius: 0px;
}

/* Overlays */
.ui-widget-overlay {
    background: #eeeeee;
    opacity: 0.8;
    filter: Alpha(Opacity=80); /* support: IE8 */
}
/*.ui-widget-shadow {*/
/*margin: -4px 0 0 -4px;*/
/*padding: 4px;*/
/*background: #aaaaaa;*/
/*opacity: .6;*/
/*filter: Alpha(Opacity=60); /!* support: IE8 *!/*/
/*border-radius: 0px;*/
/*}*/

.ui-tooltip {
    position: absolute;
    z-index: 10001;
    max-width: 300px;
    pointer-events: none;
    background: #11a9e2;
    /*border: solid 1px #11a9e2;*/
    border: none;
    border-radius: 5px;
    /*padding: 8px 10px;*/
    text-align: left;
    font-weight: 600;
    font-size: 13px;
    color: white;
    /*border: solid 2px #50bbe6;*/
    box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.2);
}

.ui-tooltip.disabled {
    background: orangered;
}

.ui-tooltip-content {
    position: relative;
    padding: 8px 12px;
    text-shadow: 0px 0px 5px rgba(0,0,0,.2);
}
.ui-tooltip-content::after {
    color: #11a9e2;
    content: '';
    position: absolute;
    border-style: solid;
    display: block;
    width: 0;
}
.right .ui-tooltip-content::after {
    top: 10px;
    left: -8px;
    border-color: transparent #11a9e2;
    border-width: 8px 8px 8px 0;
}
.left .ui-tooltip-content::after {
    top: 18px;
    right: -8px;
    border-color: transparent #11a9e2;
    border-width: 8px 0 8px 8px;
}
.top .ui-tooltip-content::after {
    bottom: -8px;
    left: 72px;
    border-color: #11a9e2 transparent;
    border-width: 8px 8px 0;
}
.bottom .ui-tooltip-content::after {
    top: -8px;
    left: 72px;
    border-color: #11a9e2 transparent;
    border-width: 0 8px 8px;
}

.right.disabled .ui-tooltip-content::after {
    top: 10px;
    left: -8px;
    border-color: transparent orangered;
    border-width: 8px 8px 8px 0;
}
.left.disabled .ui-tooltip-content::after {
    top: 18px;
    right: -8px;
    border-color: transparent orangered;
    border-width: 8px 0 8px 8px;
}
.top.disabled .ui-tooltip-content::after {
    bottom: -8px;
    left: 72px;
    border-color: orangered transparent;
    border-width: 8px 8px 0;
}
.bottom.disabled .ui-tooltip-content::after {
    top: -8px;
    left: 72px;
    border-color: orangered transparent;
    border-width: 0 8px 8px;
}

.arrow {
    width: 70px;
    height: 16px;
    overflow: hidden;
    position: absolute;
    left: 50%;
    margin-left: -35px;
    bottom: -16px;
}
.arrow.top {
    top: -16px;
    bottom: auto;
}
.arrow.left {
    left: 20%;
}
.arrow:after {
    content: "";
    position: absolute;
    left: 20px;
    top: -20px;
    width: 25px;
    height: 25px;
    box-shadow: 6px 5px 9px -9px black;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
.arrow.top:after {
    bottom: -20px;
    top: auto;
}
