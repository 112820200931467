@import "common";

@font-face {
  font-family: "Source Sans Pro Heavy";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url("../fonts/SourceSansPro/SourceSansPro-Black.ttf");
}

#editor {
  position: absolute;
  width: 100%;
  height: 100%;
  min-width: $minUIWidth;
  min-height: $minUIHeight;
  overflow: hidden;

  // Editor-specific slide canvas styles
  .slide_canvas {
    box-shadow: rgba(0, 0, 0, 0.4) 0px 26px 40px;

    .options_menu_button {
      display: none;
      color: $selectionColor;
      background: white;
      border-radius: 100px;
      font-size: 90px;
      padding: 0px;
      position: absolute;
      right: 14px;
      bottom: 14px;
      margin: 0px;
    }
  }
}

#canvas_bounds {
  position: absolute;
  width: 100%;
  height: 100%;

  &.doc-view {
    pointer-events: auto;
    overflow-y: scroll;
  }
}

#canvas_container {
  width: 100%;
  transform-origin: 0% 0%;
  position: absolute;
  top: 0px;
  pointer-events: auto;
}

.verify-email #editor {
  height: calc(100% - 50px);
}

.change_template {
  position: absolute !important;
  transition: opacity 100ms;
  z-index: 200;
  display: flex;

  color: white;


  &.hidden {
    opacity: 0 !important;
    visibility: hidden;
  }

  .control {
    background: none !important;
    color: white !important;

    img {
      width: 120px;
      border: solid 1px #ccc;
    }
  }

  .popup {
    padding: 0px !important;
  }

  &:hover {
    .control {
      i {
        color: $selectionColor;
      }
    }
  }
}

.editor_menu_bar {
  background: #333;
  width: 76px;
  height: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 301;
  display: flex;
  align-items: center;
  flex-direction: column;

  &.right-menu {
    right: 0px;
    left: auto;
    width: 60px;
  }

  .spacer {
    flex-grow: 2;
  }

  transition: width 300ms ease-out;

  .back_button {
    background: black;
    width: 100%;
    color: white;
    text-align: center;
    cursor: pointer;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease;

    #back-arrowhead {
      transition: all 0.2s ease;
      transform: translateX(0px);
    }

    #back-arrowline {
      transition: all 0.2s ease-out;
      width: 0px;
      x: 10px;
    }

    &:hover {
      background: $newBlue;

      #back-arrowhead {
        transform: translateX(-7px);
      }

      #back-arrowline {
        width: 16px;
        x: 3px;
      }
    }
  }

  .divider {
    border-top: solid 1px #444;
    width: 100%;
    margin: 0px 0px 10px;
  }

  .presentation_menu {
    background: black;
    height: 50px;
    color: $newBlue;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .menu_button {
    color: #eee;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
    cursor: pointer;
    transition: 350ms;
    position: relative;
    transition: opacity 200ms;

    & + .probadge_container {
      position: absolute;
      top: 0;
      right: 10px;

      .pro-badge {
        position: relative;
        left: 4px;
        top: 0px;
      }
    }

    &.hidden {
      opacity: 0 !important;
      visibility: hidden;

      & + .probadge_container {
        opacity: 0 !important;
        visibility: hidden;
      }
    }

    &.disabled {
      opacity: 0.3 !important;
      pointer-events: none;
    }

    i {
      font-size: 22px;
      transition: color 350ms;
    }

    label {
      display: block;
      color: #ccc;
      text-align: center;
      margin-top: -2px;
      font-size: 9px;
      text-transform: uppercase;
      opacity: 0;
      transition: opacity 350ms;
    }

    &:hover {
      i {
        color: $selectionColor;
      }

      label {
        opacity: 1;
      }
    }

    &.selected {
      background: #222;

      label {
        opacity: 1;
      }

      i {
        color: $selectionColor;
      }
    }


    &#elements {
      i {
        width: 100%;
      }
    }

    &.organize {
      label {
        margin-top: 2px;
      }

      &:hover {
        #organize-squares > rect {
          fill: $selectionColor;
        }

        #first-box {
          transition: all 0.4s ease;
          transform: translateX(6.1px);
        }

        #second-box {
          transition: all 0.4s ease;
          transform: translateX(-6.1px);
        }

        #third-box {
          transition: all 0.4s 0.2s ease;
          transform: translateY(6.5px);
        }

        #fourth-box {
          transition: all 0.4s 0.2s ease;
          transform: translateY(-6.5px);
        }
      }
    }

    &.layout {
      label {
        margin-top: 2px;
      }

      #layout-icon {
        position: absolute;
        left: 26px;
        width: 25px;
      }

      #layout-slide {
        transition: all 0.4s ease;
      }

      .spin {
        transform-origin: 20.32px 15.31px;
        transition: all 0.4s ease;
      }

      &:hover {

        #layout-slide,
        #gear-solid {
          fill: $selectionColor;
        }

        #layout-icon .spin {
          transform: rotate(90deg);
        }
      }
    }

    &.add-image {
      label {
        margin-top: 2px;
      }

      #camera-icon {
        position: absolute;
        left: 26px;
        width: 20px;
        height: 20px;
      }

      #circle-mask,
      #plus,
      #camera,
      #circle-mask,
      #plus-circle {
        transition: all 0.4s ease;
      }

      &:hover {

        #circle-mask,
        #plus-circle {
          fill: $selectionColor;
          transform: translate(-12px, -11px) scale(2);
        }

        #plus {
          fill: black;
        }

        #camera {
          fill: $selectionColor;
          opacity: 0;
        }
      }
    }

    &.animation-icon {

      #arrow1,
      #arrow2 {
        transition: all 0.4s ease;
      }

      @keyframes animate {
        0% {
          transform: translateX(0px);
        }

        50% {
          transform: translate(22px);
        }

        51% {
          transform: translate(-30px);
        }

        100% {
          transform: translateX(0px);
        }
      }

      &:hover {
        svg {
          fill: $selectionColor;
        }

        #arrow1 {
          animation-name: animate;
          animation-duration: 0.5s;
        }

        #arrow2 {
          animation-name: animate;
          animation-duration: 0.4s;
        }
      }
    }

    &.colors {
      label {
        margin-top: 2px;
      }

      &:before {
        transition: transform 0.5s ease;
        position: absolute;
        width: 23px;
        height: 23px;
        content: "";
        background: url("../images/ui/colors.svg") no-repeat 0px 0px;
        background-size: 100%;
      }

      &:hover {
        &:before {
          transform: rotate(135deg);
        }
      }
    }

    //this style is used to make menu_button look disabled while retaining pointer-events
    &.disabled-with-pointer-events {
      opacity: 0.3 !important;

      &:hover {
        transform: unset;

        &:before {
          transform: unset;
        }

        i {
          color: unset;
        }

        label {
          opacity: 0;
        }
      }
    }
  }

  .presentation_menu {
    width: 100%;
  }

  .slide_menu {
    margin-top: 20px;
    width: 100%;
    display: flex;
    flex-grow: 2;
    flex-direction: column;
  }

  .menu {
    position: absolute;
    background: white;
    padding: 10px;
    width: 600px;
    border: solid 1px #eee;
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.25);
  }
}

.editor_menu {
  position: absolute;
  background: #222;
  top: -20px;
  z-index: 100;
  height: 100vh;
  padding: 10px 12px 10px;
  color: white;
  box-shadow: 10px 0px 40px rgba(0, 0, 0, 0.5);
  overflow: auto;

  &.theme_panel {
    width: 125px;

    .button label {
      color: white;
    }

  }

  &.layout_panel {
    width: 200px;
  }

  &.template_panel {
    width: 200px;
  }

  .button.edit-theme {
    color: white;

    &.disabled {
      opacity: 0.3;
      pointer-events: none;
    }

    &.team-theme .micon {
      background: red;
      border-radius: 100%;
      padding: 2px;
      margin-bottom: 3px;
      color: white;

      &:hover {
        color: #50bbe6;
      }
    }
  }

  &.deleted_slides {
    padding: 10px 0;
    width: 300px;

    .slide_item {
      .thumbnail {
        padding: 10px 20px;
        margin-bottom: 0;

        img {
          width: 260px;
          height: auto;
        }
      }
    }
  }

  &.slide_revisions {
    padding: 0px;
    width: 225px;
    cursor: default;
    background: white;
    color: #333;
    height: calc(100% - 50px);
  }

  .empty-container {
    display: flex;
    -webkit-box-align: center;
    box-align: center;
    align-items: center;
    height: calc(100% - 45px);
    padding: 20px;
  }

  &.element-layout-panel {
    width: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .section {
      text-transform: uppercase;
      margin: 10px 0px;
      width: 100%;

      &:first-of-type {
        margin-top: 0px;
      }
    }

    .thumbnail {
      opacity: 0.5;
      margin: 0px;
      width: 80%;

      img {
        height: auto;
        width: 100%;
        vertical-align: middle;
      }

      &.selected {
        //outline: $selectionColor 2px solid;
        opacity: 1;
      }

      &.disabled {
        opacity: 0.1;
        pointer-events: none;
      }

      &:hover {
        outline: $selectionColor 2px solid;
        opacity: 1;
      }
    }

    .thumbnail + .thumbnail {
      margin-top: 10px;
    }

    & > .control.button {
      margin-bottom: 10px;
      flex-shrink: 0;
      padding: 0px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: none;
      border: solid 1px $selectionColor;
      height: 36px;
      width: 100%;
    }

    .warning {
      position: absolute;
      width: 130px;
      background: #50bbe6;
      font-size: 12px;
      color: white;
      text-transform: uppercase;
      width: 80%;
      padding: 10px 10px;
      text-align: center;
      top: 205px;
      font-weight: 600;
    }
  }

  hr {
    border: none;
    background: #444;
    height: 1px;
    margin: 20px 0px 20px;
  }

  .title {
    font-weight: 600;
    text-transform: uppercase;
    padding: 10px 20px 20px;
  }

  .section {
    .control {
      margin-bottom: 20px;
    }
  }

  .section.content_tray {
    margin-top: 15px;
  }

  .section.bottom {
    position: absolute;
    width: 100%;
    margin-top: 30px;
    left: 0px;
    padding: 0px 14px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .control.toggle {
      label {
        min-width: 65px;
      }
    }
  }

  .control.dropdown_menu_prompt {
    flex-wrap: wrap;

    .selected_item {
      margin-top: 6px;
      width: 100%;
      color: #333;
    }

    margin-bottom: 20px;
  }

  .control.toggle {
    flex-direction: column;
    align-items: flex-start;

    .toggle_container {
      margin-left: 0px;
      margin-top: 6px;
    }

    &.skip_slide_toggle {
      display: flex;
      flex-direction: row;
      margin-top: 10px;

      label {
        font-size: 14px !important;
        margin-right: 10px;
      }

      div.toggle_container {
        margin-top: -5px;
        height: 30px;
        width: 60px;
      }

      div.toggle_button {
        height: 22px;
        width: 22px;
      }
    }
  }

  .spacer {
    height: 20px;
    width: 100%;
  }

  .label {
    font-size: 12px;
    color: white;
    font-weight: 600;
    text-transform: uppercase;
    margin-top: 2px;
    margin-bottom: 16px;
    text-align: center;
  }

  .layouts {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .thumbnail {
    margin-bottom: 5px;
    text-align: center;
    display: inline-block;

    img {
      height: 50px;
      outline: none;
    }

    label {
      font-weight: 500;
      font-size: 10px;
      color: #ccc;
      width: 100%;
      text-transform: uppercase;
    }

    &.selected {
      opacity: 1;
    }

    &.disabled {
      opacity: 0.33;
    }
  }

  .button {
    cursor: pointer;
    padding: 10px 0px;
    color: #333;
    transition: 250ms;
    text-align: center;

    label {
      display: block;
      font-size: 12px;
      text-transform: uppercase;
    }

    &:hover {
      color: $selectionColor;
    }
  }

  .color_group {
    margin-bottom: 20px;
  }

  .color_list {
    padding: 0px 9px 0px 15px;
    margin-right: -5px;
    min-width: 104px;

    &.disabled {
      opacity: 0.2;
    }

    .color_chit {
      margin-right: 10px;
      margin-bottom: 10px;
    }
  }

  .theme-background-image-thumbnail {
    background: white;
    width: 100px;
    height: 56px;
    margin-bottom: 10px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .notice {
    position: absolute;
    width: calc(100% - 40px);
    text-align: center;
    top: calc(50% - 20px);
    font-size: 13px;
    font-weight: 600;
    color: #ddd;
  }

  .template {
    padding: 0px 10px;
    width: 100%;
    margin-bottom: 20px;
    text-align: center;
    cursor: pointer;
    opacity: 0.6;
    transition: opacity 300ms;

    img {
      width: 100%;
      margin-bottom: 4px;
    }

    &.selected {
      opacity: 1;
    }

    &:hover {
      opacity: 1;
    }

    label {
      text-transform: uppercase;
      text-align: center;
      font-size: 12px;
      font-weight: 500;
      color: #ccc;
    }
  }
}
.is_safari {
  // Avoid smearing bug on safari by removing blur radius from box-shadow
  .editor_menu {
    box-shadow: 5px 0px 0px rgba(0, 0, 0, 0.2);
  }
}

.slide_options {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: default;

  &.hidden {
    opacity: 0 !important;
    visibility: hidden;
  }

  &.transition {
    transition: opacity 300ms;
  }

  .spacer {
    height: calc(50% - 15px);
  }

  .button {
    opacity: 0;
    transition: 250ms;
    color: #ddd;
    text-align: center;
    margin-bottom: 30px;
    cursor: pointer;
    width: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: "Source Sans Pro", sans-serif;

    &.disabled {
      pointer-events: none;
    }

    i {
      font-size: 20px;
      text-shadow: 0px 7px 24px rgba(0, 0, 0, 0.2);
    }

    &:hover {
      i {
        color: $selectionColor;
      }

      label {
        color: $selectionColor;
      }
    }

    label {
      display: block;
      opacity: 0;
      text-transform: uppercase;
      transition: 250ms;
      white-space: nowrap;
      margin-top: 4px;
      cursor: pointer;
      font-size: 10px;
    }

    &.add_slide,
    &.template-info {
      opacity: 1;

      i {
        font-size: 40px;
      }
    }

    &.change_template {
      opacity: 1;
      position: absolute;
      top: 0px;
      left: 0px;
    }
  }

  .delete_slide {
    opacity: 0;
    position: absolute;
    top: calc(100% - 50px);
    width: 50px;
    margin-bottom: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;

    label {
      font-weight: 500;
      color: #ddd;
      margin: 0 -10px;
      cursor: pointer;
      white-space: initial;
      font-size: 10px;
    }
  }

  &:hover {

    .button,
    .delete_slide {
      opacity: 1;

      label {
        opacity: 1;
      }
    }
  }

  &.active {

    .button,
    .delete_slide {
      opacity: 1;

      label {
        opacity: 1;
      }
    }
  }
}

// -----------------------------------------------------------------
// Selection layer
// -----------------------------------------------------------------
#selection_layer {

  &.hidden {
    opacity: 0 !important;
    visibility: hidden;
  }

  &.transition {
    transition: opacity 300ms;
  }

  &.disabled {
    pointer-events: none !important;
  }

  position: absolute;
  z-index: 102; // puts the selection layer above the menu bar for classic slide elements at top of canvas

  .element_selection_box {
    position: absolute;
    background: transparent;
    border: dotted 1px $selectionColor;
    pointer-events: none;
    cursor: pointer;

    &.base_element {
      border: dotted 1px $selectionColor;
    }

    &.child_element {
      border: dotted 1px #aaa;
      border: dotted 1px $selectionColor;
    }

    &.hidden {
      border: none;
    }

    &.focused {
      border-color: $selectionColor;
    }
  }
}


.is-dragover {
    #selection_layer .drop-target {
      pointer-events: auto;
      opacity: 1;
      background: rgba(255, 255, 0, 0.1);
      z-index: 11; // Needs to be above decorations

      &.is-dragover {
        background: rgba(80, 187, 230, 0.2);
        border: dashed 2px #999;
      }
    }
  }

// -----------------------------------------------------------------
// Property Panels (element and slide property dropdown panels)
// -----------------------------------------------------------------
.property_panel {
  font-size: 14px;

  .inner {
    height: 100%;
  }

  .section {
    clear: both;
    color: #404040;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    margin-top: 20px;
    margin-bottom: 4px;

    &:first-of-type {
      margin-top: 0px;
    }
  }

  .panel_header {
    clear: both;
    background: #e1e1e1;
    color: #333;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    padding: 6px;
    margin-bottom: 10px;
  }

  .control {
    color: #404040;
  }

  .secondary_elements {
    margin-top: 11px;
    margin-bottom: 10px;

    img {
      height: 49px;
      width: 49px;
      margin-right: 6px;
      border: solid 1px white;

      &.selected {
        border: solid 1px $selectionColor;
      }
    }
  }

  .column {
    float: left;
    margin-right: 30px;
  }
}

.layout_thumbnails {

  label {
    font-size: 11px;
    font-weight: 600;
    text-transform: uppercase;
    width: 100%;
    margin-bottom: 10px;
  }

  .thumbnails {
    display: grid;
    grid-template-columns: repeat(auto-fit, 87px);
    max-width: 576px;
    gap: 10px;
  }

  .thumbnail {
    svg {
      width: 100%;
      height: 100%;
    }

    &.selected {
      rect {
        fill: #11A9E2;
      }
    }

    &:last-of-type {
      margin-right: 0px;
    }
  }

}

// -----------------------------------------------------------------
// Editor overlay
// -----------------------------------------------------------------

.element-ui,
.element-default-overlay,
.element-drop-target {
  position: absolute;
  color: white;
  width: 100%;
  height: 100%;
  pointer-events: none;
  @include no_user_select;

  &.hidden {
    opacity: 0;

    .widgets {
      visibility: hidden;
    }

    .widget_bar {
      visibility: hidden;
    }

    .delete_button {
      visibility: hidden;
    }
  }

  .widget_bar {
    position: absolute;
    left: 0px;
    height: 32px;
    width: max-content;

    &.control_bar {
      //box-shadow: 0px 14px 36px rgba(0, 0, 0, 0.2);
      filter: drop-shadow(0px 14px 14px rgba(0, 0, 0, 0.2));
      transform: translate3d(0, 0, 0);
    }

    &.small {
      height: 28px;

      i {
        font-size: 18px !important;
      }

      .control {
        height: 28px;

        &.button {
          padding: 10px 6px;
        }
      }
    }

    z-index: 200;
    display: flex;
    align-items: center;

    .control {
      background: $ui_focused_color;

      padding: 0px 14px;
      height: 34px;
      position: relative;

      &.last-control {
        &:after {
          opacity: 0;
        }

        border-radius: 0px 2px 2px 0px;
      }

      &.no-border {
        padding-right: 0px;

        &:after {
          opacity: 0;
        }
      }

      &:after {
        content: "";
        position: absolute;
        right: 0px;
        height: 100%;
        width: 1px;
        background: white;
        opacity: 0.3;
      }

      label {
        font-size: 12px;
      }

      &.color_picker {
        padding: 0px 10px;
      }

      &.button:not(.popup) {
        &:first-of-type {
          border-left: none;
        }

        &:last-of-type {
          border-right: none;
        }
      }

      &.datepicker {
        label {
          margin-right: 10px;
          min-width: auto;
        }

        .date-value {
          font-weight: 600;
          border: none;
          padding: 0px;
          line-height: 1;
          width: 85px;
          text-align: center;
        }
      }

      &.numeric {

        margin: 0px;
        padding: 0px 8px;
        display: flex;
        align-items: center;

        label {
          min-width: 0px;
          margin-right: 10px;
        }

        .numeric-container {
          //width: 100px;
          display: flex;
          align-items: center;
          padding: 5px 0px;
          border: none;
          background: transparent;
        }

        input {
          background: transparent;
          color: white;
          font-size: 14px;
          font-weight: 600;
          min-width: 20px;
          border: none;
          flex-grow: 2;
          margin: 0px !important;
          outline: none;
          text-align: center;
          padding: 0px;
        }

        .stepper-button {
          background: none;

          &:hover {
            background: $lightBlue;
          }

          color: white;
          width: 20px;
          height: 20px;
          height: 100%;
          margin-left: 2px;
          flex-shrink: 0;
          display: flex;
          align-items: center;
          justify-content: center;

          i {
            font-size: 25px;
          }
        }
      }


      &.toggle {
        position: relative;

        label {
          margin-right: 10px;
        }

        .toggle_container {
          background: white;

          .toggle_label.off {
            color: #333;
          }

          .toggle_button {
            background: $darkSelectionColor;
          }
        }
      }

      &.multi_toggle {
        width: auto;
        flex-wrap: nowrap;

        label {
          font-size: 11px;
          margin-bottom: 0px;
          margin-left: 0px;
          margin-right: 10px;
          width: auto;
        }

        .toggle_container {
          border: solid 1px #111;
          background: white;
          height: 27px;
          display: flex;

          .toggle_button {
            cursor: pointer;
            text-transform: uppercase;
            text-align: center;
            letter-spacing: 0px;
            font-weight: 700;
            padding: 4px 12px;
            display: inline-block;
            font-size: 10px;
            color: #666;

            &.selected {
              background: $selectionColor !important;
              color: white !important;
              text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2);
            }
          }
        }
      }

      &.dropdown_menu_prompt {
        .selected_item {
          background: transparent;
          margin-top: 0px;

          .selected_item_label {
            font-size: 13px;
            text-transform: uppercase;
            font-weight: 600;
          }

          i {
            color: white;
          }
        }
      }
    }
  }

  .control {
    z-index: 10;
  }

  .delete_button {
    background: $selectionColor;
    pointer-events: auto;
    position: absolute;
    right: -5px;
    top: -5px;
    width: 18px !important;
    height: 18px !important;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;

    i {
      font-size: 14px !important;
    }
  }

  .ui_hint_container {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .ui_hint {
      color: #50bbe6;

      padding: 4px 10px;
      position: absolute;
      text-align: cent;
      font-size: 9px;
      text-transform: uppercase;
      font-weight: 600;
    }
  }
}

.element-default-overlay {
  display: flex;
  align-items: center;
  justify-content: center;

  .control.button.icon {
    border-radius: 100px;

    i {
      font-size: 21px;
    }

    &.small_icon {
      i {
        font-size: 14px;
      }
    }
  }


  .control.button + .control.button {
    margin-left: 10px;
  }
}

.text-clip-warning {
  font-size: 10px;
  font-weight: 600;
  text-transform: uppercase;
  color: white;
  background: $newBlue;
  padding: 5px 10px;
  position: absolute;
  bottom: -30px;
  white-space: nowrap;
}

// -----------------------------------------------------------------
// Editor stuff
// -----------------------------------------------------------------

.scrollbar {
  width: 100%;
  height: 10px;
  background: #555;
  position: absolute;
  left: 0px;
  top: 600px;

  .scrollthumb {
    width: 100px;
    height: 10px;
    background: #888;
    position: absolute;
    left: 0px;
  }
}

.slide-grid {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  background: $body_background;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 200;

  .slides {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 30px;
    overflow-x: hidden;
    overflow-y: auto;

    .drag-rect {
      position: absolute;
      z-index: 100;
      border: 1px dotted #555;
      background: $selectionColor;
      opacity: 0.25;
    }

    .slide-thumbnail {
      position: absolute;
      will-change: transform;
      display: flex;
      flex-direction: column;
      align-items: center;

      &:last-of-type {
        margin-bottom: 30px;
      }

      .frame {
        position: absolute;
        z-index: 2;
        top: 0px;
        left: 0px;
        width: 100%;
        height: calc(100% - 10px);
        background: transparent;

        &.loading {
          background: #222;
        }
      }

      img {
        position: absolute;
        width: 100%;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);

        &.slidePlaceholder {
          box-shadow: none;
        }
      }

      .thumbnail-skip {
        color: #ffffff;
        position: absolute;
        left: 5px;
        top: 6px;
        z-index: 1;
        font-size: 20px;
      }

      .assigned-to-user {
        color: white;
        position: absolute;
        right: 6px;
        top: 7px;
        z-index: 1;
        font-size: 30px;
      }

      .assigned-to-user-container {
        &:before {
          content: '';
          display: inline-block;
          width: 34px;
          height: 34px;
          border-radius: 50%;
          background-color: #69b6d5;
          position: absolute;
          right: 4px;
          top: 4px;
        }
      }

      .thumbnail-skip-corner {
        &:before {
          z-index: 1;
          content: "";
          color: #ffffff;
          background: none;
          font-size: 25px;
          font-weight: bold;
          padding: 0;
          position: absolute;
          top: 0;
          left: 0;
          text-align: center;
          width: 0;
          height: 0;
          border-left: 40px solid transparent;
          border-right: 40px solid transparent;
          border-bottom: 40px solid $selectionColor;
          transform: rotate(-45deg) translate(-14.2px, -22.5px);
        }
      }


      .content-indicators {
        position: absolute;
        top: 100%;
        right:0;
        color: white;

        i {
          font-size: 12px;
        }
      }


      .slide-num {
        position: absolute;
        top: 100%;
        font-size: 15px;
        font-weight: 600;
        color: white;
        padding: 0px 6px;
        margin-top: -6px;

        // Add some absolute padding
        &::after {
          content: "";
          display: block;
          position: absolute;
          top: 100%;
          width: 20px;
          height: 10px;
        }
      }

      &.selected {
        .frame {
          border: solid 3px $selectionColor;
        }
      }

      &.focused {
        .slide-num {
          background: white;
          border-radius: 100px;
          color: black;
        }
      }

      &.isDragging {
        z-index: 100;
        transition: 0ms !important;

        .slide-num {
          display: none;
        }
      }

      &.isDragFollow {
        z-index: 99;
      }
    }
  }

  .bottom-bar {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: end;
    align-items: center;
    height: 30px;
    padding: 0 10px;
    background: #222222;
    color: #eee;
  }

  .drag-helper {
    img {
      width: 250px;
    }
  }
}

// -----------------------------------------------------------------
// Images
// -----------------------------------------------------------------

.image_drag {
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  position: absolute;
  pointer-events: auto;
  cursor: move;
  z-index: 199;
}

.image_drag_shield {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  z-index: 198;
}

.styles_menu {
  display: flex;
  flex-wrap: wrap;
  background: white;

  & > label {
    display: block;
    width: 100%;
    font-size: 24px;
    margin-bottom: 20px;
    text-transform: uppercase;
    letter-spacing: 0.9px;
    color: #333;
  }

  .styles_thumbnail {
    display: inline-block;
    width: 100px;
    text-align: center;
    margin-right: 20px;

    &:last-child {
      margin-right: 5px;
    }

    img {
      width: 100%;
      height: 62px;
      border: solid 1px #ccc;
    }

    label {
      font-size: 12px;
      font-weight: 500;
      color: #444;
      margin-top: 4px;
      text-transform: uppercase;
    }

    &:hover {
      img {
        border-color: $selectionColor;
      }
    }
  }

  .style_preview {
    display: inline-block;
    margin-right: 30px;
    margin-bottom: 30px;

    label {
      display: block;
      margin-top: 10px;
      text-align: center;
      width: 100%;
      font-size: 14px;
      font-weight: 600;
      color: white;
      text-transform: uppercase;
      letter-spacing: 0.9px;
    }

    .thumbnail {
      width: 384px;
      height: 216px;
      outline: solid 1px #666;
      box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.2);

      .slide_canvas {
        position: absolute;
        display: inline-block;
        width: 1280px;
        height: 720px;
        transform: scale(0.3);
      }
    }
  }
}

.styles_panel {
  overflow-y: hidden;

  .close_button {
    color: #555;
    top: 0px;
    right: 0px;
  }

  .contents {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    padding: 20px 20px 20px 10px !important;
    height: auto !important;
    justify-content: center;
  }

  .style_thumbnail {
    display: inline-block;
    margin-right: 20px;

    label {
      display: block;
      text-align: center;
      width: 100%;
      margin-top: 5px;
      font-size: 12px;
      font-weight: 600;
      color: #333;
      text-transform: uppercase;
      letter-spacing: 0.9px;
      max-width: 130px;
    }

    .thumbnail {
      background: white;
      width: 130px;
      border: solid 1px #888;

      img {
        width: 100%;
        display: inline;
        vertical-align: middle;
      }
    }

    &:hover {
      img {
        outline: solid 2px $selectionColor;
      }
    }
  }

  .style_preview {
    display: inline-block;
    margin-right: 20px;

    label {
      display: block;
      text-align: center;
      width: 100%;
      font-size: 14px;
      font-weight: 600;
      color: white;
      text-transform: uppercase;
      letter-spacing: 0.9px;
    }

    .thumbnail {
      position: relative;
      width: 192px;
      height: 108px;
      outline: solid 1px #666;
      background: white;

      .slide_canvas {
        position: absolute;
        left: 0px;
        top: 0px;
        display: inline-block;
        width: 1280px;
        height: 720px;
        transform: scale(0.15);
      }
    }

    &:hover {
      .thumbnail {
        outline: solid 2px $selectionColor;
      }
    }
  }
}

.drag_box {
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: auto;
}

.drag_cell_target_hilite {
  fill: $selectionColor;
  fill-opacity: 0.5;
  position: absolute;
}

.drag_cell_target_prevent {
  fill: red;
  fill-opacity: 0.75;
  position: absolute;
}

.drag_cell {
  position: absolute;
  pointer-events: auto;
  background: url("../images/ui/drag_handle_small.png") no-repeat 6px 6px;
  width: 21px;
  height: 21px;
  cursor: move;
  background-color: $selectionColor;
  border-radius: 100px;
  top: -10px;
  left: -10px;

  &:before {
    content: "";
    background: transparent;
    position: absolute;
    left: -10px;
    top: -10px;
    width: calc(100% + 20px);
    height: calc(100% + 20px);
    pointer-events: auto;
  }

  &:after {
    content: "";
    background: url("../images/ui/drag_handle_small.png") no-repeat 6px 6px;
    position: absolute;
    width: 21px;
    height: 21px;
    background-color: $selectionColor;
    border-radius: 100px;
  }
}

.resize_container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  top: 0px;

  &.with_topbar {
    top: -33px;
  }

  .control.button {
    height: 34px;
    padding: 0px 12px;
  }

  .resize_box {
    position: absolute;
    border: solid 1px $selectionColor;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
  }

  .resize_controls {
    position: absolute !important; //draggable tries to set these to relative
    pointer-events: auto;
    color: white;
    width: 21px;
    height: 21px;
    border-radius: 100px;
    padding: 2px 3px;

    background: $selectionColor;

    &:before {
      font-family: "Material Icons";
      content: "\E25D";
      font-size: 16px;
    }

    &.top_left {
      top: -10px;
      left: -10px;
    }

    &.top_right {
      top: -10px;
      right: -10px;
    }

    &.bottom_right {
      bottom: -10px;
      right: -10px;
    }

    &.bottom_left {
      bottom: -10px;
      left: -10px;
    }

    &.top {
      top: -10px;
      left: calc(50% - 10px);
      cursor: ns-resize;
    }

    &.bottom {
      bottom: -10px;
      left: calc(50% - 10px);
      cursor: ns-resize;

      &.drop_shadow {
        box-shadow: 0 3px 7px rgba(0, 0, 0, 0.4);
        z-index: 1000;
      }
    }

    &.left {
      left: -10px;
      top: calc(50% - 10px);
      transform: rotate(90deg);
      cursor: ew-resize;
    }

    &.right {
      right: -10px;
      top: calc(50% - 10px);
      transform: rotate(90deg);
      cursor: ew-resize;
    }
  }
}

.resizing {
  z-index: 1000;
}

.text {
  display: inline-block;
  border: none;
  background: transparent;
  cursor: default;
}

.contentFrameDefaultOverlay {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  .control.button {
    pointer-events: auto;
    min-width: 40px !important;

    &:hover {
      background: none !important;

      i {
        background: darken($ui_background_color, 10%);
      }
    }
  }

  &.showFrame {
    background: transparentize($selectionColor, 0.9);
  }
}

.element-ui {
  position: absolute !important;
  width: 100%;
  height: 100%;
  color: white;
  pointer-events: none;
  @include no_user_select;
  display: flex;
  align-items: center;
  justify-content: center;

  .selection-box {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    border: dotted 1px $selectionColor;
    opacity: 0.6;
  }

  .mouse-event-capture {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    pointer-events: auto;
    background: transparent;
  }

  .selection-path {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    stroke: $selectionColor;
    stroke-width: 1px;
    stroke-dasharray: 1 1;
    fill: none;
    pointer-events: none;
  }

  &.rollover {
    z-index: 1;
  }

  &.light {
    .drag_button {
      color: white;
    }

    .delete_component_button {
      color: white;
    }
  }
}

.text-element-rollover {
  .selection-box {
    cursor: text;
  }
}

.default_overlay {
  position: absolute;
  pointer-events: none;
  @include no_user_select;
}

.ui_widget {
  position: absolute !important;
  pointer-events: none;
  color: white;
  z-index: 100;

  &.drop_target {
    border: dashed 1px $selectionColor;
    display: flex;
    align-items: center;
    justify-content: center;

    &.rollover {
      background: transparentize($darkSelectionColor, 0.5);
      opacity: 1;
    }

    .tray_expander {
      background: $selectionColor;
      position: absolute;
      width: 100px;
      height: 100%;
      opacity: 0;

      &.left {
        left: 0px;
        border-right: dashed 1px white;

        &:after {
          content: "\E314";
          font-family: "Material Icons";
          font-size: 24px;
          position: absolute;
          width: 100%;
          text-align: center;
          top: calc(50% - 10px);
        }
      }

      &.right {
        right: 0px;
        border-left: dashed 1px white;

        &:after {
          content: "\E315";
          font-family: "Material Icons";
          font-size: 24px;
          position: absolute;
          width: 100%;
          text-align: center;
          top: calc(50% - 10px);
        }
      }
    }
  }

  .button {
    position: absolute;
    background: $selectionColor;
    color: white;
    pointer-events: auto;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
    border-radius: 6px;

    &:hover {
      background: $darkSelectionColor;
    }

    &.with_label {
      padding: 5px 14px;
      background: white;
      border: solid 1px $selectionColor;
      color: $selectionColor;

      &:hover {
        color: $darkSelectionColor;
        border-color: $darkSelectionColor;
        background: white;
      }
    }
  }

  .button_widget {
    position: absolute;

    pointer-events: auto;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
    border-radius: 6px;

    &:hover {
      color: $darkSelectionColor;
      border-color: $darkSelectionColor;
    }
  }

  .add_component_button {
    padding: 5px 14px;
  }

  .horizontal_line {
    width: 100%;
    position: absolute;
    border: dashed 1px $selectionColor;
    opacity: 0.5;
  }

  .vertical_line {
    height: 100%;
    position: absolute;
    border: dashed 1px $selectionColor;
    opacity: 0.5;
  }

  .icon_button {
    position: absolute;
    pointer-events: auto;
    cursor: pointer;
    background: $selectionColor;
    border: solid 1px $selectionColor;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    width: 18px;
    height: 17px;

    i {
      font-size: 13px;
      user-select: none;
    }

    &:hover {
      color: white;
      background: $darkSelectionColor;
    }
  }

  &.large {
    .icon_button {
      width: 22px;
      height: 22px;

      i {
        font-size: 18px;
      }
    }
  }

  .icon_button_square {
    position: absolute;
    pointer-events: auto;
    cursor: pointer;
    background: $selectionColor;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0px;

    i {
      font-size: 15px;
      pointer-events: none;
    }
  }

  .drag_button {
    cursor: -webkit-grab;
    background: transparent;
    color: $selectionColor;

    i {
      font-size: 17px;
    }

    &:hover {
      background: transparent;
    }
  }

  .delete_component_button {
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    color: $selectionColor;
    border-radius: 50%;

    &:hover {
      color: white;
      background: $selectionColor;
    }

    i.micon {
      font-size: 15px;
      pointer-events: none;
    }
  }

  .drop_target_image {
    position: absolute;
    pointer-events: auto;
    cursor: pointer;
    background: $selectionColor;

    &:before {
      font-family: "Material Icons";
      content: "\E439";
      font-size: 18px;
    }

    border-radius: 100px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .action_button {
    &:after {
      font-family: "Material Icons";
      content: "\E908";
      font-size: 15px;
    }

    border-radius: 100px;
    width: 22px;
    height: 22px;
    text-align: center;
    padding-top: 3px;
  }

  .add_item_button {
    &:after {
      font-family: "Material Icons";
      content: "\E145";
      font-size: 15px;
    }

    border-radius: 100px;
    width: 22px;
    height: 22px;
    text-align: center;
    padding-top: 3px;
  }

  .edit_button {
    &:after {
      font-family: "Material Icons";
      content: "\E145";
      font-size: 15px;
    }

    border-radius: 100px;
    width: 22px;
    height: 22px;
    text-align: center;
    padding-top: 3px;
  }

  .marker {
    position: absolute;
    border-radius: 100px;
    background: $selectionColor;
    width: 10px;
    height: 10px;
  }

  .chart_point_rollover {
    position: absolute;
    left: 15px;
    top: 15px;
    width: 20px;
    height: 20px;
    border-radius: 100px;
    border: solid 2px $selectionColor;
    pointer-events: none;
  }

  .drag_handle {
    position: absolute;
    pointer-events: auto;
    background: url("../images/ui/drag_handle_small.png") no-repeat 6px 6px;
    width: 22px;
    height: 22px;
    cursor: move;
    background-color: $selectionColor;
    border-radius: 100px;
    top: -22px;
    left: -22px;
  }

  .resizer {
    position: absolute !important; //draggable tries to set these to relative
    pointer-events: auto;
    color: white;
    width: 21px;
    height: 21px;
    border-radius: 100px;
    background: $selectionColor;

    &.horizontal {
      &:before {
        padding: 2px 3px;
        font-family: "Material Icons";
        content: "\E25D";
        font-size: 16px;
      }

      cursor: ew-resize;
      transform: rotate(90deg);
    }

    &.vertical {
      &:before {
        font-family: "Material Icons";
        content: "\E25D";
        font-size: 16px;
      }

      cursor: ns-resize;
    }
  }

  .fillbox {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.2;
    background: $selectionColor;
    top: 0px;
    left: 0px;
  }

  &.outline {
    border: solid 1px $selectionColor;
  }

  &.fill {
    background: transparentize($selectionColor, 0.8);
  }

  .tray_resizer {
    position: absolute !important;
    pointer-events: auto;
    width: 22px;
    height: 22px;
    cursor: pointer;
    background: $selectionColor;
    border-radius: 100px;

    &.horizontal {
      &:before {
        content: "";
        width: 0px;
        height: 300px;
        border: dashed 1px $lightSelectionColor;
        position: absolute;
        top: -150px;
        left: 10px;
      }
    }

    &.vertical {
      &:before {
        content: "";
        width: 500px;
        height: 0px;
        border: dashed 1px $lightSelectionColor;
        position: absolute;
        left: -250px;
        top: 10px;
      }
    }

    &:after {
      content: "";
      background: url("../images/ui/drag_handle_small.png") no-repeat 6px 6px;
      position: absolute;
      width: 21px;
      height: 21px;
      background-color: $selectionColor;
      border-radius: 100px;
    }
  }

  .tray_resizer_centered {
    position: absolute !important;
    pointer-events: auto;

    &:after {
      content: "";
      background: url("../images/ui/drag_handle_small.png") no-repeat 6px 6px;
      position: absolute;
      width: 21px;
      height: 21px;
      background-color: $selectionColor;
      border-radius: 50%;
      cursor: pointer;
    }

    &.horizontal {
      &:before {
        content: "";
        width: 0px;
        height: 300px;
        border: dashed 1px $lightSelectionColor;
        position: absolute;
        top: -150px;
        left: 0px;
      }

      &:after {
        left: -10px;
      }
    }

    &.vertical {
      &:before {
        content: "";
        width: 500px;
        height: 0px;
        border: dashed 1px $lightSelectionColor;
        position: absolute;
        left: -250px;
        top: 0px;
      }

      &:after {
        top: -10px;
      }
    }


  }

  .tray-autosize {
    position: absolute;
    pointer-events: auto;
    width: 21px;
    height: 21px;
    top: calc(50% + 15px);
    cursor: pointer;
    background: $selectionColor;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:after {
      content: "\E8D4";
      font-family: "Material Icons";
      font-size: 18px;
    }
  }
}

.drag_selection_notice {
  position: absolute;
  border: dotted 1px $selectionColor;
  background: transparentize($selectionColor, 0.9);
  display: flex;
  justify-content: center;

  .notice {
    background: $selectionColor;
    color: white;
    text-transform: uppercase;
    color: white;
    padding: 4px 10px;
    border-radius: 4px;
    position: relative;
    top: -15px;
    height: 24px;
  }
}

.add_node_btn {
  position: absolute;
  pointer-events: auto;
  color: $selectionColor;
  font-size: 14px;
  background: white;
  border-radius: 50px;

  &.add_child {
    bottom: -15px;
    left: calc(50% - 7px);
  }

  &.add_left_sibling {
    left: -15px;
    top: calc(50% - 7px);
  }

  &.add_right_sibling {
    right: -15px;
    top: calc(50% - 7px);
  }

  &.add_parent {
    top: -15px;
    left: calc(50% - 7px);
  }
}

.anchor-widget {
  position: absolute;
  pointer-events: auto;
  background: white;
  border: solid 2px $selectionColor;
  border-radius: 100px;
  width: 12px;
  height: 12px;
  z-index: 10;

  &.selected {
    background: $darkSelectionColor;
  }

  &:hover {
    background: $selectionColor;
  }

  &:before {
    content: "";
    width: 30px;
    height: 30px;
    border-radius: 100px;
    position: absolute;
    top: -11px;
    left: -11px;
  }
}

.ui-widget {
  position: absolute;
  pointer-events: auto;
}

.resize-handle {
  position: absolute;
  pointer-events: auto;
  background: white;
  border: solid 1px $selectionColor;
  width: 10px;
  height: 10px;

  &.selected {
    background: $selectionColor;
  }

  &:hover {
    background: $selectionColor;
  }

  &.horizontal {
    cursor: ew-resize;
  }

  &.vertical {
    cursor: ns-resize;
  }
}

.simple-resize-handle {
  position: absolute;

  &.selected {
    background: $selectionColor;
  }

  &:hover {
    background: $selectionColor;
  }

  &.horizontal {
    cursor: ew-resize;

    .divider {
      position: absolute;
      top: -100px;
      height: 200px;
      border-left: $selectionColor 1px dotted;
      width: 1px;
      left: 0px;
    }
  }

  &.vertical {
    cursor: ns-resize;

    .divider {
      position: absolute;
      left: -100px;
      width: 200px;
      border-top: $selectionColor 1px dotted;
      height: 1px;
      top: 0px;
    }
  }

  &:after {
    content: "";
    position: absolute;
    pointer-events: auto;
    background: white;
    border: solid 1px $selectionColor;
    width: 10px;
    height: 10px;
    left: -7px;
    top: -7px;
  }

  &.tray {
    &:after {
      content: "";
      background: url("../images/ui/drag_handle_small.png") no-repeat 6px 6px;
      pointer-events: auto;
      position: absolute;
      width: 21px;
      height: 21px;
      left: -11px;
      top: -11px;
      background-color: $selectionColor;
      border-radius: 100px;
    }
  }
}

.create-connector-widget {
  position: absolute;
  pointer-events: auto;
  background: white;
  border: solid 1px $selectionColor;
  border-radius: 100px;
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;

  i {
    color: $selectionColor;
    font-size: 12px;
  }

  &:hover {
    background: $selectionColor;

    i {
      color: white;
    }

  }

  &:after {
    content: "Drag to connect";
    background: $darkSelectionColor;
    padding: 4px 2px;
    border-radius: 10px;
    top: -30px;
    width: 110px;
    font-size: 10px;
    text-transform: uppercase;
    position: absolute;
    text-align: center;
    font-weight: 600;
    opacity: 0;
    transition: opacity 400ms;
    pointer-events: none;
  }

  &:hover:after {
    opacity: 1;
  }
}

.connector-dragger {
  position: absolute;
  pointer-events: auto;
  background: white;
  border: solid 1px $selectionColor;
  border-radius: 100px;
  width: 10px;
  height: 10px;
  cursor: move;
  display: flex;
  align-items: center;
  justify-content: center;

  &:before {
    content: "";
    width: 20px;
    height: 20px;
    position: absolute;
    top: -5px;
    left: -5px;
  }

  i {
    color: $selectionColor;
    font-size: 9px;
  }

  &:hover {
    background: $selectionColor;
  }
}

.drag-widget {
  position: absolute;
  pointer-events: auto;
  background: white;
  border: solid 1px $selectionColor;
  border-radius: 100px;
  width: 10px;
  height: 10px;
  cursor: move;
  display: flex;
  align-items: center;
  justify-content: center;

  &:before {
    content: "";
    width: 20px;
    height: 20px;
    position: absolute;
    top: -5px;
    left: -5px;
  }

  &:hover {
    background: $selectionColor;
  }

  &.horizontal {
    cursor: ew-resize;
  }

  &.vertical {
    cursor: ns-resize;
  }
}

.drag-tooltip {
  position: absolute;
  background: $darkSelectionColor;
  color: white;
  padding: 6px 10px;
  white-space: nowrap;
  font-weight: 600;
}

.connector-adjustment-widget {
  position: absolute;
  pointer-events: auto;
  border: 1px solid $selectionColor;
  background: white;
  border-radius: 50% !important;
  width: 10px;
  height: 10px;
  cursor: move;
}

.wordCloudDialog {
  width: 800px;

  .contents {
    max-height: 600px;
  }

  .instructions {
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 600;
  }

  .hint {
    font-size: 16px;
    background: $selectionColor;
    color: white;
    font-weight: 600;
    margin-bottom: 20px;
    padding: 14px 20px;
  }

  .wordgroup {
    position: relative;
    margin-bottom: 14px;

    label {
      display: inline-block;
      vertical-align: middle;
      width: 60px;
      font-weight: 600;
      margin-right: 20px;
      text-transform: uppercase;
      position: absolute;
      height: 100%;
      padding-top: 10px;
    }

    .words {
      display: inline-block;
      vertical-align: middle;
      margin-left: 60px;
      font-size: 16px;
      line-height: 1.5;
      border: solid 1px #bbb;
      padding: 8px 12px;
      width: calc(100% - 60px);
      cursor: text;
    }
  }
}

.bugReportDialog {
  width: 1000px;
  height: 500px;

  textarea {
    width: calc(100% - 22px);
    height: calc(100% - 117px);
    font-size: 14px;
    padding: 10px;
    outline: none;
    resize: none;
    margin-bottom: 10px;
  }

  .checkbox {
    margin-bottom: 20px;
  }
}

.table_editor {
  .widget {
    position: absolute !important;
    background: $lightSelectionColor;
    border: solid 1px $selectionColor;
    text-align: center;
    pointer-events: auto;
    display: flex;
    align-items: center;
    justify-content: center;

    .label {
      color: $selectionColor;
    }

    label {
      color: $darkSelectionColor;
    }

    .resizer {
      position: absolute !important; //draggable will try to set to relative
    }

    .drag_handle {
      background: url(../images/ui/drag_handle_small_blue.png) no-repeat 3px 3px;
      width: 16px;
      height: 16px;
      cursor: move;
      position: absolute;
      display: none;
      left: 10px;
    }

    &:hover {
      .drag_handle {
        display: block;
      }
    }

    .control.actions_menu {
      position: absolute;
      color: $selectionColor;
      background: none;
      font-size: 16px;
      padding: 0px;
      display: block;
      height: 24px;

      label {
        font-size: 16px;
      }

      i {
        margin: 0px;
      }
    }

    &.selected {
      background: $selectionColor;

      .control {
        color: white;
      }

      label {
        color: white;
      }
    }
  }

  .table_cell_widget {
    position: absolute !important;
    pointer-events: auto;
  }

  .table_drag_helper {
    position: absolute;
    pointer-events: none;
    background: $selectionColor;
    opacity: 0.1;
  }

  .drag_target_indicator {
    position: absolute;
    background: $darkSelectionColor;
  }

  .col_widget {
    .resizer {
      width: 10px;
      height: 24px;
      left: calc(100% - 4px);
      cursor: ew-resize;
      z-index: 1000;
    }

    .drag_handle {
      left: 2px;
    }
  }

  .col-menu {
    position: absolute;
    padding: 0px 0px 0px 7px !important;
    background: white !important;
    color: $newBlue !important;

    i {
      font-size: 20px !important;
    }
  }

  .row-menu {
    position: absolute;
    padding: 0px !important;
    background: lighten($selectionColor, 5%) !important;
    background: white;
    color: $newBlue;

    .popup_arrow {
      margin-left: -2px !important;
    }
  }

  .col_drag {
    position: absolute;
    pointer-events: auto;
    background: $lightSelectionColor;
    display: flex;
    align-items: center;
    justify-content: center;

    i {
      color: $darkSelectionColor;
      font-size: 16px;
    }

    cursor: ew-resize;
  }

  .row_drag {
    position: absolute;
    pointer-events: auto;
    background: $lightSelectionColor;
    display: flex;
    align-items: center;
    justify-content: center;

    i {
      color: $darkSelectionColor;
      font-size: 16px;
    }

    cursor: ns-resize;
  }

  .row_widget {
    .resizer {
      width: 30px;
      height: 10px;
      bottom: -5px;
      cursor: ns-resize;
      z-index: 1000;
    }

    .drag_handle {
      top: 2px;
    }

    .control.actions_menu {
      min-width: 0px;

      i {
        margin-left: -2px;
        width: 10px;
      }
    }
  }

  .transpose_widget {
    position: absolute;
    background: $selectionColor;
    color: white;
    width: 20px;
    height: 20px;
    cursor: pointer;
    top: -35px;
    left: -35px;
    border-radius: 100px;

    &:before {
      content: "\E8D4";
      font-family: "Material Icons";
      font-size: 16px;
    }
    &:hover {
      background: $darkSelectionColor;
    }
  }

  .table_cell_selection {
    border: solid $selectionColor 1px;
    outline: solid $selectionColor 1px;
    width: 100%;
    height: 100%;
    position: absolute;
  }

  .table_cell_selection_container {
    position: absolute;
    top: -5px;
    left: -5px;
    width: calc(100% + 10px);
    height: calc(100% + 10px);
    stroke: $selectionColor;
    stroke-width: 2px;
  }
}

.scroll_track {
  background: $lightSelectionColor;
  border: solid 1px $darkSelectionColor;
  position: absolute;
  width: calc(100% - 100px);
  margin-left: 50px;
  bottom: 10px;
  height: 12px;
  pointer-events: auto;

  .scroll_thumb {
    width: 50px;
    height: 100%;
    position: absolute;
    background: $darkSelectionColor;
    border: solid 1px white;
    border-radius: 20px;
  }
}

.themeDebugPanel {
  position: absolute;
  z-index: 1000;
  bottom: 30px;
  left: 100px;
  padding: 20px;
  width: 90%;
  height: 150px;
  background: #f1f1f1;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  .control {
    margin-right: 30px;
  }
}

.editor_warning {
  position: absolute;
  padding: 6px 20px;
  width: 300px;
  background: $darkSelectionColor;
  color: white;
  border: solid 2px white;
  z-index: 1000;

  .warning_title {
    text-align: center;
    height: 20px;
    display: block;
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
  }

  .warning_message {
    margin-top: 6px;
    display: block;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
  }
}

.text-input {
  position: absolute;
  left: 200px;
  top: 200px;
  opacity: 0;
  font-size: 30px;
  pointer-events: none;
  width: 1px;
}

.animation-panel {
  width: 250px;
  overflow: hidden;
  background-color: #f1f1f1;
  padding: 0;
}

.text-style-bar {
  position: absolute;
  display: flex;
  background: $newBlue;
  height: 32px;
  width: 200px;

  .control {
    height: 100% !important;

    &:after {
      content: none !important;
    }
  }

  .control.button {
    padding: 0px 8px !important;

    &:hover {
      background: rgba(0, 0, 0, 0.05) !important;
    }
  }

  .control.button-toggle {
    padding: 0px 5px;

    i {
      font-size: 19px;
    }

    &:hover {
      background: rgba(0, 0, 0, 0.05) !important;
    }
  }

  .control.popup.button {
    padding: 0px 10px 0px 20px !important;
  }

  .user-scale-label {
    font-size: 12px;
    font-weight: 600;
    color: white;
    margin: 0px 8px;
    line-height: 1;
    pointer-events: auto;
    height: 100%;
    display: flex;
    align-items: center;
  }

  .divider {
    width: 1px;
    background: white;
    opacity: 0.2;
    height: 100%;
    margin: 0px 4px;
  }

}

.icon-menu {
  &.gray {
    .menu_contents {
      background: #f5f5f5;
    }
  }

  .menu_contents {
    padding: 16px;
    display: grid;
    grid-column-gap: 0px;
    grid-template-columns: repeat(3, 100px);
    grid-row-gap: 0px;

    .image_menu_item {
      cursor: pointer;
      width: 100px;
      padding: 10px;
      text-align: center;
      position: relative;

      &:hover {
        outline: solid 1px $lightSelectionColor;
      }

      img,
      svg {
        width: 100%;
        border: none;
      }

      label {
        cursor: pointer;
        letter-spacing: 0.9px;
        margin-top: 10px;
        width: 100%;
        text-transform: uppercase;
      }
    }
  }

  &.smallicon {
    .menu_contents {
      .image_menu_item {
        width: 70px;
      }
    }
  }

  &.onecol {
    .menu_contents {
      grid-template-columns: repeat(1, 100px);
    }
  }

  &.twocol {
    .menu_contents {
      grid-template-columns: repeat(2, 100px);
    }
  }

  &.threecol {
    .menu_contents {
      grid-template-columns: repeat(3, 100px);
    }
  }

  &.fourcol {
    .menu_contents {
      grid-template-columns: repeat(4, 100px);
    }
  }

  &.fivecol {
    .menu_contents {
      grid-template-columns: repeat(5, 100px);
    }
  }

  &.sixcol {
    .menu_contents {
      grid-template-columns: repeat(6, 100px);
    }
  }
}

.add-content-panel {
  overflow-y: hidden;
  background: white;
  border-top: solid 1px #ddd;

  .close_button {
    color: #555;
    top: 0px;
    right: 0px;
  }

  .contents {
    background: white;
    color: #333;
    display: flex;
    flex-direction: column;
    height: auto;
    justify-content: flex-start;
    padding: 0px 10px;
  }

  .panel {
    margin-bottom: 10px;
    position: relative;

    &:last-of-type {
      margin-right: 0px;
    }

    label {
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 0.9px;
      text-transform: uppercase;
    }

    .options {
      margin-top: 10px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 5px;

      .thumbnail {
        position: relative;
        width: 95px;

        @media (max-width: 1179px) {
          width: 70px;
        }

        @media (min-width: 1180px) and (max-width: 1219px) {
          width: 80px;
        }

        @media (min-width: 1220px) and (max-width: 1299px) {
          width: 85px;
        }

        @media (min-width: 1300px) and (max-width: 1410px) {
          width: 90px;
        }

        margin-right: 5px;

        img {
          width: 100%;
          display: inline;
          vertical-align: middle;
        }

        .frame {
          position: absolute;
          top: 0px;
          left: 0px;
          width: 100%;
          height: 100%;
          border: solid 1px #888;
        }

        &.disabled {
          opacity: 0.4;
          pointer-events: none;
        }

        &.selected {
          outline: solid 1px $selectionColor;
        }
      }
    }

    .warning {
      position: absolute;
      width: 130px;
      background: white;
      font-size: 12px;
      border: solid 1px #50bbe6;
      color: #333;
      text-transform: uppercase;
      width: 80%;
      margin-left: 10%;
      padding: 9px 9px;
      text-align: center;
      top: 36px;
      font-weight: 600;
    }
  }
}

.add-annotation-panel {
  overflow: visible;
  height: 100%;
  background: white;
  width: 110px;
  border-top: solid 1px #ddd;

  .close_button {
    color: #555;
    top: 0px;
    right: 0px;
  }

  .contents {
    background: white;
    color: #333;
    display: flex;
    flex-direction: column;
    height: auto;
    align-items: center;
    padding: 0px 0px !important;
  }

  .annotation-type {
    text-align: center;
    padding: 5px 0px;

    img {
      width: 100%;
      max-width: 100px;
    }

    label {
      opacity: 0;
      font-weight: 600;
      color: #444;
      font-size: 11px;
      text-transform: uppercase;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 100%;
      overflow: hidden;
      display: block;
      transition: opacity 300ms;
    }

    &:hover {
      background: $lightSelectionColor;

      label {
        opacity: 1;
      }
    }

    @media (max-width: 1280px) {
      width: 90px;
    }

  }

  .drag-label {
    color: $newBlue;
    text-transform: uppercase;
    text-align: center;
    font-size: 10px;
    font-weight: 600;
    margin-bottom: 10px;
  }

  .annotation-warning {
    background: orangered;
    color: white;
    text-align: center;
    padding: 10px;
    font-weight: bold;
  }

}

.drag-annotation-helper {
  img {
    width: 100px;
  }
}

// ensure a height for the chart marker type images
// since it may cause the dropdown menu to layout
// incorrectly since images are all 0 height while loading
.marker_type_menu img {
  height: 20px;
}

.pictorial-chart-menu {
  .preset-icons {
    display: grid;
    grid-template-columns: repeat(auto-fit, 50px);
    margin-bottom: 10px;
    max-width: 400px;

    .icon {
      width: 55px;
      border: solid 1px transparent;

      &:hover {
        border: solid 1px $selectionColor;
      }

      svg {
        width: 55px;
        height: 55px;
      }

      .none {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
      }
    }

  }
}

.table-icons-menu {
  .preset-icons {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;

    .icon {
      position: relative;
      width: 55px;
      height: 50px;
      border: solid 1px transparent;

      &:hover {
        border: solid 1px $lightSelectionColor;
      }

      svg {
        position: absolute;
        width: 60px;
        height: 60px;
        left: -6px;
        top: -6px;
        pointer-events: none;
      }

      &.blank_icon {
        display: flex;
        align-items: center;
        justify-content: center;

        label {
          text-transform: uppercase;
          font-size: 11px;
          font-weight: 600;
          color: #666;
        }
      }
    }
  }
}

.format-menu {
  & > label {
    width: 132px !important;
  }

  .format-icons {
    display: flex;

    .format-option {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 70px;
      margin-right: 5px;
      padding-bottom: 6px;
      border: solid 1px transparent;

      &:hover {
        border-color: $lightSelectionColor;
      }

      &.selected {
        border-color: $selectionColor !important;
      }

      img {
        width: 60px;
        height: 41.22px;
      }

      label {
        font-size: 12px;
        font-weight: 600;
        text-transform: uppercase;
      }
    }
  }

  .text-alignment {
    display: flex;
    align-items: center;
    padding: 10px 0px;

    label {
      font-size: 11px;
      margin-right: 10px;
      font-weight: 600;
      text-transform: uppercase;
    }

    & > div {
      margin-right: 10px;
      margin-bottom: 0px !important;
    }
  }
}

.table-cell-editor {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background: lighten($selectionColor, 35%);
  width: 100%;
  height: 100%;
  outline: solid 2px $selectionColor;
  padding: 0px 10px;
  z-index: 1000;

  textarea {
    font-size: 18px;
    background: red;
    resize: none;
    border: none;
    outline: none;
    background: none;
    width: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    font-family: "Source Sans Pro";
    font-size: 15px;
    color: black;
    pointer-events: auto;
    z-index: 1000;
    text-align: center;
    box-sizing: border-box;
    overflow: hidden;
  }
}

.icon-toggle-menu {
  padding: 10px;
  display: grid;
  grid-template-columns: repeat(4, 75px);
  grid-gap: 20px;
}

.orgchartnode-texteditor {
  pointer-events: auto;
  position: absolute;
  z-index: 10000;
  background: $selectionColor;
  color: #333;
  padding: 5px;

  input.name {
    display: block;
    padding: 5px;
    width: 200px;
    font-size: 15px;
    border: none;
  }

  input.role {
    display: block;
    padding: 5px;
    width: 200px;
    font-size: 15px;
    margin-top: 5px;
    border: none;
  }
}

.tree-node-drag-helper {
  background: $selectionColor;
  padding: 5px 12px;
  border-radius: 20px;
  color: white;
  text-transform: uppercase;
  font-weight: 600;
  z-index: 10000;
}

.tree-node-drop-target {
  position: absolute;
  background: $selectionColor;
  z-index: 1000;
}

.style-popup-menu {

  .edit-styles {
    display: flex;
    align-items: center;
  }

  .preset-option {
    display: flex;
    align-items: center;

    label {
      margin-left: 7px;
      font-size: 10px;
      color: #666;
    }
  }

  .toggle-button {
    font-size: 10px;
    font-weight: 600;
    text-transform: uppercase;
  }

  &.light-border {
    .color_picker_chit {
      border: solid 1px rgba(255, 255, 255, 0.75);
    }
  }
}

.test-recorder-ui {
  height: 30px;
  display: flex;
  margin-right: 20px;
  position: absolute;
  top: 10px;
  left: 400px;
  z-index: 9999;
  font-family: "Source Sans Pro", sans-serif;

  .control {
    padding-left: 12px !important;
  }
}

.test-cursor {
  position: absolute;
  width: 10px;
  height: 10px;
  z-index: 99999;
  border-radius: 100px;
  border: none;
  background: black;

  &.mousedown {
    background: orangered;
    border: solid 3px orangered;
  }
}

.collaboration-bar {
  position: absolute;
  width: 60px;
  height: calc(100% - 50px);
  top: 50px;
  right: 0px;
  z-index: 1000;

  &.hidden {
    opacity: 0 !important;
    visibility: hidden;
  }
}

.locked-obsolete-template-shield {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  overflow: visible;
  //z-index is 200 to cover all the editors on a canvas
  z-index: 200;
}

.locked-slide-shield {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  overflow: visible;
  //z-index is 201 to cover all the editors on a canvas and locked-obsolete-template-shield
  z-index: 201;
}

.text-edit-warning {
  background: orangered;
  opacity: 0.5;
  position: absolute;
}

#import.menu_button {
  position: relative;

  input {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
}

font.comment-text-hilited[data-uid] {
  color: #0fa6df;
  border-radius: 3px;
  background-color: #0fa6df2b;
}

font.comment-text-hilited[data-pending-email] {
  color: #777;
  background-color: #9090902b;
}

.drop-target-indicator {
  position: absolute;
  background: $newBlue;
  border: dotted 1px $newBlue;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  text-transform: uppercase;
  color: $newBlue;
}

.add-row-indicator {
  position: absolute;
  background: transparentize($newBlue, 0.85);
  border: dotted 1px $newBlue;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  text-transform: uppercase;
  color: $newBlue;
  text-align: center;

  &.hover {
    background: transparentize($newBlue, 0.5);
  }
}

.add-column-indicator {
  position: absolute;
  display: flex;
  align-content: flex-start;
  justify-content: flex-start;

  .label {
    font-size: 12px;
    text-transform: uppercase;
    color: $newBlue;
    text-align: left;
    position: absolute;
    top: -35px;
    width: 50px;
  }

  .line {
    width: 1px;
    height: 100%;
    border-left: dashed 1px $newBlue;
  }

  &.hover {
    .line {
      width: 100%;
      border: dotted 1px $newBlue;
      background: transparentize($newBlue, 0.5);
    }
  }
}

.video-record-countdown {
  font-size: 150px;
  color: white;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 900;
  opacity: 1;
  position: absolute;
  top: -5px;
  animation: animate-video-record-countdown 1s linear forwards;
}

@keyframes animate-video-record-countdown {
  100% {
    font-size: 40px;
    opacity: 0;
  }
}

.slide-grid .collaboration-view {
  margin: 0;
  position: fixed;
  padding: 10px;
  bottom: 0px;
  right: 0px;
  z-index: 100;
  pointer-events: auto;
  transition: bottom 500ms;
  padding: 5px;
  margin-bottom: 10px;

  .user {
    position: relative;
    display: inline-block;
    list-style-type: none;
    margin-left: 4px;
    transition: opacity 250ms;
    vertical-align: middle;
    opacity: 1;

    &:nth-of-type(1n + 5) {
      display: none;
    }

    &:hover .username {
      opacity: 1;
    }
  }

  .avatar {
    width: 24px;
    height: 24px;
    box-shadow: none;
    transition: box-shadow 250ms;
    border-radius: 50%;

    .micon {
      margin-top: 4px;
      font-size: 16px;
    }

    .micon,
    img {
        border-radius: 50%;
    }
  }

  .active {
    opacity: 1;
    border-bottom: 4px solid $ui_background_color;
    margin-bottom: -4px;
  }
}


