@import "common";

html {
    height: 100%;
    //background: $body_background;
}

.auth-modal-active {
    #mainView,
    body > .spinner {
        display: none;
    }
}

.apc-spotlight {
    opacity: 0.2 !important;
    color: #333 !important;
}

body {
    min-height: 100%;
    overflow: hidden;
    font-family: "Source Sans Pro", sans-serif;
    -webkit-font-smoothing: antialiased;
    font-size: 12px;
    padding: 0px;
    margin: 0;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 24px;
    height: 8px;
}

::-webkit-scrollbar-thumb:vertical {
    border-radius: 20px;
    background-color: rgba(0, 0, 0, 0.3);
    border: 8px solid transparent;
    background-clip: content-box;
}

::-webkit-scrollbar-thumb:horizontal {
    border-radius: 20px;
    background-color: rgba(0, 0, 0, 0.2);
}

#server-error-page {
    position: absolute;
    top: 0px;
    width: 100%;
    height: 100vh;
    background: $body_background;
    letter-spacing: 0.75px;
    overflow: hidden;

    .error-page-link {
        text-decoration: none;
        color: inherit;
        div {
            background-color:rgb(17, 169, 226);
            padding: 10px 20px;
            font-size: 16px;
        }
    }

    .spacer {
        margin: 20px;
    }
}

#server-error-page .error,
#mainView .error,
#player .error {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    margin-top: 50px;
    color: white;
    img {
        margin-top: 150px;
        //width: 50%;
        animation: shake 2.5s cubic-bezier(0.36, 0.07, 0.19, 0.97) infinite;
    }

    @keyframes shake {
        5%,
        45% {
            transform: translate3d(-1px, 0, 0);
        }

        10%,
        40% {
            transform: translate3d(2px, 0, 0);
        }

        15%,
        25%,
        35% {
            transform: translate3d(-4px, 0, 0);
        }

        20%,
        30% {
            transform: translate3d(4px, 0, 0);
        }
    }

    h2 {
        font-family: Montserrat;
        font-size: 35px;
        margin: 20px 0px;
        padding-bottom: 20px;
        border-bottom: solid 1px rgba(255, 255, 255, 0.2);
    }
    h4 {
        font-family: Montserrat;
        font-size: 20px;
        font-weight: 300;
        margin: 6px;
        color: #e1e1e1;
    }
}

::placeholder,
::-webkit-input-placeholder {
    font-weight: 300;
    font-style: italic;
    color: #aaa;
}

img {
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
}

#ios_status_bar {
    background: $dark_background;
    width: 100%;
    height: 20px;
    display: none;
}

.verify-email-dialog {
    .inner {
        padding: 20px 20px 10px;

        .textInput {
            display: block;
            width: 100%;
        }
    }

    .verify-email-btn {
        background: #50bbe6;
        border: solid 1px #50bbe6;
        padding: 12px 25px;
        text-transform: uppercase;
        font-size: 15px;
        font-weight: 600;
        color: white;
        letter-spacing: 0.75px;
        text-align: center;
    }
}
.old-library-view #menubar {
    background: transparent;
}

#mainView.offline .disconnected {
    display: flex;
}

.sidebar.profile_panel {
    height: 100%;
    width: 360px;

    .section {
        margin-top: 20px;
        font-size: 14px;
        font-weight: 600;
        text-transform: uppercase;
    }

    &.sidebar {
        z-index: 999;
        label {
            min-width: 75px;
            font-size: 14px;
        }

        input.button[disabled] {
            opacity: 0.4;
            pointer-events: none !important;
            background: #11a9e2;
        }
    }
    hr {
        margin-top: 20px;
        margin-bottom: 10px;
    }
    .avatar {
        width: 100px;
        height: 100px;
        margin-bottom: 20px;
        & > i {
            font-size: 80px;
        }
    }

    .description {
        font-size: 12px;
        color: #444;
        margin-bottom: 20px;
    }

    .control.toggle {
        label {
            margin-right: 10px;
        }
    }
    .control.textinput {
        width: 100%;
    }

    .verified-email {
        text-transform: uppercase;
        font-size: 10px;
        margin-top: -6px;
        margin-bottom: 20px;
        text-align: right;
        &.verified {
            color: green;
        }
        &.not-verified {
            color: red;
            &:hover {
                color: white;
                background: red;
            }
        }
    }

    .delete_account_button {
        border: 1px solid red;
        margin-top: 20px;
        &:hover {
            background: rgba(255, 0, 0, 0.4) !important;
        }
    }
}

.input-error {
    color: red;
    text-transform: uppercase;
}

.avatar {
    background: #333;
    width: 30px;
    height: 30px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 16px;
    font-weight: 600;

    & > i {
        width: 30px;
        height: 30px;
        font-size: 21px;
        line-height: 30px;
        text-align: center;
        color: #ccc;
    }
    img {
        width: 100%;
        height: 100%;
    }
    & > i.micon.delete {
        position: absolute;
        top: -7px;
        right: -7px;
        font-size: 14px;
        background: #50bbe6;
        border-radius: 100px;
        padding: 2px;
        color: white;
        display: none;
        cursor: pointer;
    }
    &:hover i.micon.delete {
        display: block;
    }
}

.sidebar.help_panel {
    top: 50px;
    height: calc(100% - 50px);
    width: 130px;
    z-index: 100;
    overflow:hidden;
    .contents {
        padding: 10px 0px 0px;
    }

    .control.button {
        width: 100%;
        text-align: left;
        margin-bottom: 60px !important;
        i {
            margin-bottom: 4px;
        }
        label {
            margin-top: 0px;
        }
    }

    .build-version {
        position: absolute;
        bottom: 0px;
        padding: 5px;
        color: rgba(0, 0, 0, 0.2);
        font-size: 10px;
        pointer-events: auto;
        user-select: text;
    }
}

.sidebar.shortcut_view {
    top: 50px;
    height: calc(100% - 50px);
    display: flex;
    flex-direction: column;
    z-index: 500;
    overflow:hidden;
    font-size: 12px;

    .contents {
        padding: 10px;
        overflow: unset;
    }
}

//dialogs
.no-close .ui-dialog-titlebar-close {
    display: none;
}

.icon_picker {
    width: 940px;
    height: 620px;
    #item_grid {
        height: 468px;
        overflow-y: scroll;
    }
    .icon_preview {
        float: left;
        margin-right: 10px;
        margin-bottom: 10px;
        border: solid 1px #aaa;
        padding: 10px;
    }
}

.share-menu-option {
    display: flex;
    align-items: center;
    padding: 15px;
    font-size: 16px;
    font-weight: 600;
    i {
        margin-right: 10px;
        font-size: 20px ;
        color: #999;
    }
    &:hover {
        background: $lightGray;
    }
}

.action_item {
    align-items: center;
    display: inline-flex;
    cursor: pointer;
    height: 36px;
    color: $selectionColor;
    min-width: auto;
    text-decoration: none;
    &:hover {
        color: $darkSelectionColor;
    }
}

.pro-badge {
    margin-left: 10px;
    background: #ffaa00 !important;
    color: white;
    text-transform: uppercase;
    font-size: 10px;
    letter-spacing: 0px;
    font-weight: 600;
    padding: 3px 5px;
    border-radius: 2px;
    text-shadow: 0px 0px 7px rgba(0,0,0,0.2);
    height: 19px;
    line-height: 1.3em;
    box-sizing: border-box;
}

.team-badge {
    margin-left: auto;
    background: #D6EFF9;
    color: #11A9E2;
    text-transform: uppercase;
    font-size: 10px;
    letter-spacing: 0px;
    font-weight: 600;
    padding: 3px 5px;
    border-radius: 2px;
    text-shadow: 0px 0px 7px rgba(0,0,0,0.2);
    height: 19px;
    line-height: 1.3em;
    box-sizing: border-box;
}


#mainView.slide-demo {
    background: #f1f1f1;
    #editor {
        height: calc(100vh - 100px);
        overflow: visible;
    }
    .slide_canvas {
        box-shadow: none !important;
        border: solid 1px #ccc !important;
    }
    .slide-demo-menu {
        position: absolute;
        top: 100%;
        background: white;
        width: 100%;
        height: 100px;
    }
}

// Labels have cursor set to 'default' by user agent.
//   This change ensures that wrapper elements can override the cursor.
label {
    cursor: inherit;
}

// Google picker to be always visible
.picker{
    z-index: 1500;
}
