@import "common";

.left {
    float: left;
}

.right {
    float: right;
}

input {
    font-family: $ui_font;
}

.small-control-list {
    position: absolute;
    display: flex;
    transition: opacity 300ms;

    & > .control {
        margin-right: 10px;
        padding: 5px 10px !important;
        background: #c3e9fb !important;
        color: #333 !important;
        &:hover {
            background: #88d4f7 !important;
        }
        label {
            font-size: 10px !important;
        }
    }
}

.control.remove-watermark-button {
    background: orange !important;
    margin-left: 20px !important;
    border-radius: 2px !important;
}

.control.button.context_button {
    //&:before {
    //  font-family: "Material Icons";
    //  content: "\E145";
    //  font-size: 19px;
    //  font-weight: normal;
    //  position: absolute;
    //  left: 1px;
    //  top: 1px;
    //}
    position: absolute;
    width: 22px !important;
    height: 22px !important;
    background: $selectionColor;
    border-radius: 100px;
    pointer-events: auto;
    i {
        position: absolute;
        left: 2px;
        top: 2px;
        padding: 0px !important;
    }
}

.micon {
    font-family: "Material Icons";
    font-weight: normal;
    font-style: normal;
    font-size: 24px; /* Preferred icon size */
    display: inline-block;
    width: 1em;
    height: 1em;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;

    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;

    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;

    /* Support for IE. */
    font-feature-settings: "liga";

    &.small {
        font-size: 18px;
    }
}

.icon_menu {
    .thumbnail {
        display: inline-block;
        width: 81px;
        height: 49px;
        margin-right: 6px;
        margin-bottom: 6px;
        img {
            position: absolute;
        }
    }
}

.floating-popup-button {
    position: absolute;
    width: 23px;
    height: 23px;
    padding: 0px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    i {
        font-size: 16px;
    }
}

.control-icon-list {
    padding: 0px 5px;
    //height: 100%;
    background: $newBlue;
    display: grid;
    align-items: center;
    //column-gap: 10px;
    color: white;
    grid-auto-flow: column;
    grid-auto-columns: max-content;
    .micon {
        font-size: 20px;
    }
    .control {
        padding: 0px 6px !important;
    }
    .control:after {
        display: none;
    }
}


.control {
    font-size: 12px;
    pointer-events: auto;
    cursor: pointer;
    display: inline-flex;
    align-items: center;

    &:first-child {
        border-radius: 2px 0px 0px 2px;
    }
    &:last-child {
        border-radius: 0px 2px 2px 0px;
    }

    &.disabled {
        & > :not(label) {
            opacity: 0.4;
        }
        pointer-events: none !important;
        div {
            pointer-events: none !important;
        }
    }
    label {
        font-size: 11px;
        font-weight: 600;
        text-transform: uppercase;
        display: inline-block;
        white-space: nowrap;

        &.at-end {
            margin-left: 10px;
        }
    }

    input {
        display: inline-block;
    }

    &.timer_emphasis_settings {
        display: flex;
        margin-bottom: 10px !important;
    }

    &.numeric-input {
        display: flex;
        align-items: center;
        padding: 0px 6px !important;
        i {
            font-size: 20px;
            cursor:pointer;
            pointer-events: auto;
        }
        input {
            background: transparent;
            border: none;
            outline: none;
            color: white;
            font-weight: 600;
            font-size: 12px;
            width: 30px;
            padding: 0px 5px;
            text-align: center;

            /* Chrome, Safari, Edge, Opera */
            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }

            /* Firefox */
            -moz-appearance: textfield;
        }
    }

    &.spacer {
        clear: both;

        width: 100%;
        height: 1px;
        //margin-top: 15px;
        margin-bottom: 10px;
        &.ruled {
            background: #e1e1e1;
        }
    }

    &.label {
        font-size: 12px;
        font-weight: 600;
        color: white;
        padding: 0px 10px;
    }

    &.checkbox {
        input {
            margin-right: 6px;
            display: none;
            cursor: pointer;
        }
        input + label {
            position: relative;
            &:before {
                content: "";
                width: 12px;
                height: 12px;
                border: solid 2px #ccc;
                background: white;
                position: absolute;
                left: 0px;
            }
            padding-left: 22px;
            font-weight: 600;
            cursor: pointer;
        }
        input:checked + label {
            &:before {
            }
            &:after {
                font-family: "Material Icons";
                content: "\E5CA";
                font-size: 19px;
                color: $selectionColor;
                position: absolute;
                left: 1px;
                top: -3px;
            }
        }
        &.disabled {
            opacity: 0.333;
        }
    }

    &.icon_list {
        display: block;
        clear: both;
        label {
            display: block;
        }
        .icons {
            display: block;
            margin: 0px 0px -10px 0px;
            width: 100%;
            .icon {
                display: inline-block;
                vertical-align: top;
                margin-right: 10px;
                margin-bottom: 0px;
                width: 50px;
                &.selected {
                    img {
                        border: solid 1px $selectionColor;
                        outline: solid 1px $selectionColor;
                    }
                }
                img {
                    width: 50px;
                    height: 50px;
                    border: solid 1px #ccc;
                    vertical-align: middle;
                    background: white;
                }
                label {
                    margin-top: 4px;
                    font-size: 10px;
                    font-weight: 500;
                    color: #555;
                    text-align: center;
                }
            }
        }
    }

    &.icon-grid {
        display: grid;
        grid-column-gap: 0px;
        grid-template-columns: repeat(3, 100px);
        grid-row-gap: 0px;
        padding: 5px;
        &.col1 {
            grid-template-columns: repeat(1, 100px);
        }
        &.col2 {
            grid-template-columns: repeat(2, 100px);
        }
        &.col3 {
            grid-template-columns: repeat(3, 100px);
        }
        &.col4 {
            grid-template-columns: repeat(4, 100px);
        }
        &.col5 {
            grid-template-columns: repeat(5, 100px);
        }
        &.col6 {
            grid-template-columns: repeat(6, 100px);
        }
        .icon-grid-item {
            cursor: pointer;
            width: 100px;
            padding: 5px 0px;
            text-align: center;
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            &:hover {
                outline: solid 1px $lightSelectionColor;
            }
            img,
            svg {
                height: 40px;
                border: none;
            }
            label {
                cursor: pointer;
                letter-spacing: 0.9px;
                //margin-top: 10px;
                width: 100%;
                text-transform: uppercase;
            }
            &.selected {
                background: #f5f5f5;
            }
            &.disabled {
                opacity: 0.33;
                pointer-events: none;
            }
        }
    }


    &.textinput {
        display: block;
        input {
            margin: 0px !important;
            border: solid 1px #ddd;
            border-radius: 0px;
        }
    }

    &.tagged_input {
        display: inline-block;
        .invalid-tag .tag-editor-tag,
        .invalid-tag .tag-editor-delete {
            color: #c83400;
            background: #eee;
            text-decoration: line-through;
        }
        .invalid-tag .tag-editor-delete:before {
            color: #c83400;
        }
    }

    &.slider {
        label {
            margin-right: 6px;
        }
        input {
            width: 105px;
        }
        &.zoom-slider {
            input {
                margin-left: 14px;
                margin-right: 14px;
            }

            .micon {
                font-size: 18px;
            }
        }
    }

    &.numeric {
        margin-bottom: 14px;
        margin-right: 10px;
        //display: inline-block;
        position: relative;
        label {
            min-width: 75px;
        }

        .numeric-container {
            display: flex;
            border: solid 1px #ddd;
            background: white;
            align-items: center;
            padding: 2px;

            /* Chrome, Safari, Edge, Opera */
            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }

            /* Firefox */
            input[type=number] {
                -moz-appearance: textfield;
            }
        }
        input {
            font-size: 14px;
            font-weight: 600;
            min-width: 20px;
            border: none;
            flex-grow: 2;
            margin: 0px !important;
            outline: none;
            text-align: center;
            padding: 0px;
        }
        .stepper-button {
            background: white;
            &:hover {
                background: $lightBlue;
            }
            color: #555;
            width: 20px;
            height: 20px;
            height: 100%;
            margin-left: 2px;
            flex-shrink: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            i {
                font-size: 25px;
            }
        }
    }

    &.color_palette {
        label {
            margin-bottom: 8px;
            font-weight: 600;
        }
    }

    &.options-list {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: left;
        margin-bottom: 20px;
        .option-item {
            width: 100%;
            border: solid 1px #777;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding: 10px 10px;
            margin-bottom: 4px;
            .option-label {
                font-size: 13px;
                margin: 0px;
                text-transform: uppercase;
            }
            i {
                margin-right: 10px;
                font-size: 18px;
            }
            img {
                width: 20px;
                height: 20px;
                object-fit: contain;
                margin-right: 10px;
            }
            &.selected {
                background: $darkSelectionColor;
                border-color: $darkSelectionColor;
            }
            &:hover {
                border-color: $selectionColor;
            }
        }
    }

    &.options-list-icons {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        label {
            width: 100%;
            margin-bottom: 10px;
        }
        .option-items {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
        }
        &.option-items--with-labels {
            padding-bottom: 20px;
            padding-top: 10px;
        }
        .option-item {
            //width: 100%;
            width: 40px;
            height: 40px;
            border: solid 1px $newBlue;
            background: rgba(0, 0, 0, 0.2);
            display: flex;
            align-items: center;
            justify-content: center;
            //padding: 10px 10px;
            margin-right: 4px;
            position: relative;
            .option-label {
                position: absolute;
                bottom: -17px;
                font-size: 10px;
                color: #ddd;
                margin: 0px;
                text-transform: uppercase;
                width: 120px;
                text-align: center;
                //left: -40px;
                opacity: 0;
            }
            &:first-of-type {
                .option-label {
                    text-align: left;
                    left: 0px;
                }
            }
            i {
                font-size: 18px;
            }
            img {
                width: 100%;
            }
            &.selected {
                background: $darkSelectionColor;
                border-color: $darkSelectionColor;
            }
            &:hover {
                border-color: $selectionColor;
                .option-label {
                    opacity: 1;
                }
            }
        }
        &.slideThumbnail {
            .option-item {
                width: 100px;
                height: auto;
            }
        }
    }

    &.multi_toggle {
        flex-wrap: wrap;
        width: 100%;
        label {
            font-size: 11px;
            margin-bottom: 4px;
            margin-left: 2px;
            //margin-right: 10px;
            width: 100%;
        }
        .toggle_container {
            //width: 100%;
            //min-width: 200px;
            padding: 2px;
            border: solid 1px #111;
            border-radius: 2px;
            background: #444;
            height: 27px;
            display: flex;
            .toggle_button {
                cursor: pointer;
                //flex-grow: 1;
                text-transform: uppercase;
                text-align: center;
                letter-spacing: 0px;
                font-weight: 700;
                padding: 4px 12px;
                display: inline-block;
                font-size: 10px;
                color: #ddd;
                &.selected {
                    background: $selectionColor !important;
                    color: white !important;
                    text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2);
                    &.first-option-default {
                        //background: #AAABAD;
                    }
                }
            }
        }
        &.singleline {
            label {
                width: auto;
                margin-bottom: 0px;
            }
        }
    }

    &.icon-toggle {
        display: flex;
        flex-direction: column;
        margin: 0px !important;
        img {
            height: 45px;
            opacity: 0.4;
        }
        label {
            text-align: center;
            margin: 0px !important;
        }
        &.selected {
            img {
                //outline: solid 2px $selectionColor;
                opacity: 1;
            }
        }
    }

    &.datepicker {
        label {
            margin-right: 10px;
            min-width: 40px;
        }
        .date-value {
            font-weight: 600;
            border: solid 1px #ddd;
            padding: 8px 10px;
            line-height: 1;
            width: 85px;
            text-align: center;
        }
    }

    &.timepicker {
        gap: 5px;

        .control.textinput {
            margin: 0;
        }

        input {
            height: 27px; // matches the multi-toggle height
            padding: 0 3px;
        }
    }


    &.datepicker-inline {
        .ui-widget {
            position: relative;
        }

        .ui-widget-content {
            border: none;
        }

        .ui-datepicker {
            padding: 0;
        }
    }


    &.timepicker_options {
        display: grid;
        grid-template-columns: 60px auto 100px 50px;
        gap: 5px;
        align-items: center;
        justify-content: center;

        .dropdown_menu_prompt {
          margin: 0 !important;
          width: auto !important;
        }

        .selected_item {
            min-width: 60px !important;
        }
    }

    &.toggle {
        //text-align: center;
        label {
            font-size: 11px;
            //margin-bottom: 4px;
            //margin-left: 2px;
            min-width: 75px;
        }
        .toggle_container {
            position: relative;
            cursor: pointer;
            width: 49px;
            height: 24px;
            border: solid 1px #111;
            border-radius: 2px;
            display: inline-flex;
            align-items: center;
            padding: 2px;
            .toggle_label {
                width: 50%;
                z-index: 1;
                text-transform: uppercase;
                text-align: center;
                font-weight: 700;
                font-size: 10px;
                &.on {
                    opacity: 0;
                    color: white;
                    padding-left: 4px;
                }
                &.off {
                    opacity: 1;
                    color: #ddd;
                }
                transition: 400ms;
            }

            background: #444;
            .toggle_button {
                position: absolute;
                cursor: pointer;
                width: 18px;
                background: #aaabad;
                top: 2px;
                left: 2px;
                height: 18px;
                border-radius: 2px;
                transition: 250ms;
            }
        }
        &.selected {
            .toggle_container {
                border-radius: 2px;
                border: 1px solid $darkSelectionColor;
                background: $darkSelectionColor !important;
                color: white !important;
                .toggle_button {
                    left: calc(50% + 4px);
                    //right: 2px;
                    background: #95daf4 !important;
                }
                .toggle_label.on {
                    opacity: 1;
                    color: white;
                }
                .toggle_label.off {
                    opacity: 0;
                }
            }
        }
        &.mixed {
            .toggle_container {
                background: #f1f1f1;
                color: #444;
                padding-left: 6px;
                &:after {
                    content: "Mixed";
                    text-transform: uppercase;
                    position: absolute;
                    left: 6px;
                    top: 3px;
                }
                .toggle_button {
                    display: none;
                }
                .toggle_label {
                    display: none;
                }
            }
        }
    }

    &.dropdown_menu_prompt {
        display: inline-flex;
        align-items: center;
        label {
            min-width: 75px;
        }
        .selected_item {
            display: flex;
            justify-content: space-between;

            align-items: center;
            min-width: 140px;
            position: relative;
            //margin-top: 4px;
            background: white;
            border: solid 1px #ddd;
            padding: 5px 8px;
            font-weight: 600;
            .selected_item_group {
                display: flex;
                justify-items: center;
                align-items: center;

                .selected_item_icon {
                    margin-right: 6px;
                }
            }
            i {
                font-size: 19px;
                color: #555;
                float: right;
                position: relative;
                top: -1px;
                overflow: hidden;
            }
            img {
                height: 24px;
            }
        }
        &.icon_menu {
            .selected_item {
                min-width: 20px;
            }
        }
    }

    &.menu_button {
        i {
            font-size: 18px;
            margin-right: -3px;
        }
    }

    &.button-toggle {
        &.inactive {
            //background: #0d86b3 !important;
            //color: #11A9E2;
            i {
                opacity: 0.5;
            }
        }
    }

    &.control-group {
        &.horizontal {
            display: flex;
            flex-direction: row;
        }
        &.vertical {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }
    }

    &.button {
        position: relative;
        pointer-events: auto;
        cursor: pointer;
        font-size: 12px;
        font-weight: 600;
        padding: 0px 12px;
        line-height: normal;
        text-align: center;
        display: flex;
        outline: none;
        text-transform: uppercase;
        @include no_user_select;
        label {
            font-size: 12px;
            cursor: pointer;
        }
        &.dark {
            background: rgb(20, 81, 110) !important;
            &:after {
                background: rgb(20, 81, 110) !important;
            }
        }
        &.choose-template-button {
            &:after {
                display: none;
            }
        }
        &.ai-button {
            gap: 6px;
            width: 152.32px;

            img {
                width: 18px;
                height: 18px;
            }
        }
        &.primary {
            background: $ui_background_color !important;
            color: #ffffff;
            &:hover {
                background: $ui_focused_color !important;
                color: #ffffff !important;
            }
        }
        &.icon:not(.popup) {
            padding: 10px 10px;
            i {
                margin-right: 0px;
                vertical-align: middle;
            }
            //&:hover {
            //  color: white;
            //  i {
            //    background: $darkSelectionColor;
            //  }
            //}
        }
        &:not(.icon, .large_icon) {
            color: white;
            background: $newBlue;
            //min-width: 80px;
            //&:hover {
            //  background: darken($newBlue, 10%);
            //  color: white;
            //}
        }
        &:not(.large_icon) {
            color: white;
            background: $newBlue;
            //min-width: 80px;
            //&:hover {
            //  background: darken($newBlue, 10%);
            //  color: white;
            //}
        }

        .button-drop-down {
            margin: 0px -5px 0px 5px;
            font-size: 25px;
        }
        //&.mousedown{
        //  background: darken($newBlue, 15%) !important;
        //}

        &.popup:not(.icon),
        &:not(.large_icon) {
            padding-left: 20px;
        }
        &.popup.icon {
            padding: 0px 10px;
            i {
                margin-right: 0px;
            }
            .popup_arrow {
                margin-left: -6px;
                font-size: 22px;
            }
            &:hover {
                background: darken($newBlue, 10%);
                color: white;
            }
        }
        &.popup.hasIconLabel {
            padding-left: 10px;
        }
        i:not(.popup_arrow) {
            display: inline-block;
            margin-right: 6px;
            font-size: 18px;
            vertical-align: -6px;
        }
        &.small {
            font-size: 12px;
            padding: 5px 12px;
            min-width: 50px;
        }
        &.file_upload {
            position: relative;
            input[type="file"] {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                opacity: 0;
            }
        }

        &.small_icon {
            position: absolute;
            width: 24px !important;
            height: 24px !important;
            border-radius: 100px;
            margin: 0px;
            padding: 0px;
            display: flex;
            align-items: center;
            justify-content: center;
            i {
                font-size: 14px;
            }

            .spinner {
                left: -4px;
            }
        }

        &.disabled {
            label {
                opacity: 0.5;
            }
            i {
                opacity: 0.5;
            }
        }

        &.large_icon {
            min-width: 90px;
            min-height: 40px;
            border: none;
            text-align: center;
            background: none !important;
            color: #333;
            cursor: pointer;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 10px 0px;

            &.light {
                color: #eee;
            }

            //
            i {
                display: block;
                font-size: 24px;
                margin: 0px auto;
                padding: 8px;
                background: $ui_background_color;
                border-radius: 100px;
                color: white;
                //box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.25);
            }
            &:hover {
                background: none !important;
                i {
                    background: darken($ui_background_color, 10%) !important;
                }
            }
            &.no_label {
                padding: 0px !important;
                min-width: 40px;
            }
            label {
                margin-top: 10px;
            }

            &.hover_label {
                //min-width: 20px;
                padding: 10px;
                label {
                    opacity: 0;
                    position: absolute;
                    text-align: center;
                    left: calc(50% - 43px);
                    width: 86px;
                    border-radius: 5px;
                    font-size: 10px;
                    font-weight: 600;
                    bottom: -10px;
                    background: darken($ui_background_color, 10%);
                    padding: 2px 0px;
                    color: white;
                    //text-shadow: 0px 0px 4px rgba(0,0,0,.50);
                }
                &:hover {
                    label {
                        opacity: 1;
                    }
                }
            }
        }

        &.text_button {
            font-size: 13px;
            background: none;
            padding: 0px;
            color: $darkSelectionColor;
            &:hover {
                background: none;
                color: $selectionColor;
            }
        }

        &.datasource {
            margin-right: 5px;
            &.linked{
                background-color: #D53A00;
            }
        }

        .spinner {
            position: absolute !important;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            transform: scale(0.5);
            pointer-events: none;

            .circular {
                animation: rotate 2s linear infinite;
                width: 40px;
                height: 40px;
                transform-origin: center center;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                margin: auto;
            }

            .path {
                stroke: #ffffff;
                fill: none;
                stroke-width: 4px;
                stroke-dasharray: 1, 200;
                stroke-dashoffset: 0;
                stroke-miterlimit: 10;
                animation: dash 1.5s ease-in-out infinite;
                stroke-linecap: round;
            }

            @keyframes rotate {
                100% {
                    transform: rotate(360deg);
                }
            }
            @keyframes dash {
                0% {
                    stroke-dasharray: 1, 200;
                    stroke-dashoffset: 0;
                }
                50% {
                    stroke-dasharray: 89, 200;
                    stroke-dashoffset: -35px;
                }
                100% {
                    stroke-dasharray: 89, 200;
                    stroke-dashoffset: -124px;
                }
            }
        }
    }

    &.color_picker {
        padding-right: 10px;
        label {
            margin-right: 10px;
        }
        .color_picker_chit {
            display: inline-block;
            position: relative;
            vertical-align: middle;
            width: 20px;
            height: 20px;
            border: solid 1px rgba(0, 0, 0, 0.75);
            border-radius: 50%;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 600;
            font-size: 12px;
            padding-bottom: 1px;
            .auto-label {
                opacity: 0.86;
            }
            i {
                font-size: 14px;
                padding: 3px 2px;
            }

            .none-label {
                border-top: 18px solid #333;
                border-right: 18px solid white;
                width: 18px;
                height: 18px;
                position: absolute;
                top: 0px;
                left: 0px;
            }
        }
        &.light-border {
            .color_picker_chit {
                border: solid 1px rgba(255, 255, 255, 0.75);
            }
        }
    }

    &.text-editor {
        position: relative;
        padding: 0;
        margin: 0;
        outline-width: 0;
        border-radius: 0;
        border: none;

        input {
            padding: 10px 20px;
            border: none;
            box-shadow: 0px 4px 6px 1px #aaa;
            outline-width: 2px;
            font-size: 15px;
        }
    }

    &.text-editor {
        .contents {
            width: 100%;
            input {
                width: 100%;
                padding: 10px 0px;
                text-align: center;
            }
        }
    }
}

.has_options_menu {
    position: relative;
    .options_menu_button {
        &:before {
            display: block;
            font-family: "Material Icons";
            content: "\E5C5";
            font-size: 16px;
            font-weight: normal;
            color: white;
            text-align: center;
        }
        opacity: 0;
        background: $button_color;
        border-radius: 100px;
        padding: 0px;
        margin: 0px;
        width: 16px;
        height: 16px;
        position: absolute;
        bottom: 1px;
        right: 4px;
        font-size: 20px;
        color: $selectionColor;
    }
    &:hover {
        .options_menu_button {
            opacity: 1;
        }
    }
}

.popup_menu {
    background: white;
    border: solid 1px #ccc;
    position: absolute;
    display: none;
    text-align: left;
    box-sizing: border-box;
    color: #444;
    left: 0px;
    z-index: 10001;
    .popup_menu_item {
        font-weight: 600;
        white-space: nowrap;
        padding: 6px 7px;
        min-width: 75px;
        &:hover {
            background: $ui_hover_color;
        }
        &.selected {
            color: $ui_border_color;
        }
    }
}

.carousel_index {
    border-right: solid 1px rgba(255, 255, 255, 0.3);
    &:after {
        background: none !important;
    }
}

.position_menu {
    width: 66px;
    height: 66px;
    pointer-events: none;

    &.show-auto {
        width: 120px;
        height: 70px;
        .position_item {
            width: 38px;
            height: 23px;
        }
    }

    .position_item {
        pointer-events: auto;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        font-size: 11px;
        font-weight: 600;
        vertical-align: middle;
        background: #ccc;
        width: 20px;
        height: 20px;
        margin-right: 2px;
        margin-bottom: 2px;
        &:hover {
            background: $lightSelectionColor;
        }
        &.selected {
            background: $selectionColor;
        }
    }
}

.popup_arrow {
    font-size: 17px;
    margin-left: -1px;
    vertical-align: -4px;
}

.tab_container {
    .contents {
        padding: 20px 0px;
    }
}

.modal_dialog + .click_shield + .dropdown_menu {
    z-index: 10021;
}

.dropdown_menu + .click_shield + .dropdown_menu {
    z-index: 10022;
}

.modal_dialog + .click_shield {
    z-index: 10021;
}

.format_control_bar {
    padding: 0px 6px !important;
    .format_toggle {
        width: 34px;
        height: 34px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        i {
            font-size: 20px;
            opacity: 0.5;
        }

        &.toggled {
            i {
                opacity: 1;
            }
        }
    }
}

.dropdown_menu {
    position: absolute;
    z-index: 10000;
    max-width: calc(100vw - 100px);

    .menu_arrow {
        width: 16px;
        height: 16px;
        position: absolute;
        &.point-up {
            &:before {
                content: "";
                position: absolute;
                width: 0;
                height: 0;
                border-left: 8px solid transparent;
                border-right: 8px solid transparent;
                border-top: 8px solid transparent;
                border-bottom: 8px solid $menu_popup_border;
            }
            &:after {
                content: "";
                position: absolute;
                top: 1px;
                width: 0;
                height: 0;
                border-left: 8px solid transparent;
                border-right: 8px solid transparent;
                border-top: 8px solid transparent;
                border-bottom: 7px solid $menu_popup_background;
            }
        }
        &.point-down {
            &:before {
                content: "";
                position: absolute;
                top: 1px;
                width: 0;
                height: 0;
                border-left: 8px solid transparent;
                border-right: 8px solid transparent;
                border-bottom: 8px solid transparent;
                border-top: 8px solid $menu_popup_border;
            }
            &:after {
                content: "";
                position: absolute;
                top: 0px;
                width: 0;
                height: 0;
                border-left: 8px solid transparent;
                border-right: 8px solid transparent;
                border-bottom: 8px solid transparent;
                border-top: 8px solid $menu_popup_background;
            }
        }
    }
    .menu_contents {
        background: $menu_popup_background;
        border: solid 1px $menu_popup_border;
        border-radius: 2px;
        box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.25);
        &.colors {
            padding: 10px 5px 10px 10px;
            .color_chit {
                border: solid 1px #ccc;
                margin-right: 5px;
                cursor: pointer;
            }
        }
        &.custom {
            padding: 15px;
            color: #333;
        }

        .menu{
            display: flex;
            gap: 20px;
            flex-direction: column;

            label{
                color: #666666;
            }

            .dropdown_menu_prompt > .selected_item {
                min-width: 100px;
            }
        }

        &.menu-list {
            .control {
                label {
                    text-transform: none;
                    padding-left: 7px;
                    font-size: 12px;

                    &.centered-label{
                        padding-left: 0;
                        text-align: center;
                    }
                }
                &.checkbox input + label {
                    padding-left: 22px;
                }
            }
            .info {
                padding: 15px;
                color: rgba(0, 0, 0, 0.4);
                font-size: 10px;
                pointer-events: auto;
                user-select: text;
                max-width: 220px;
            }
            hr {
                margin: 5px 0px;
            }
        }

        .options-menu, .xcontrol-row, .menu-container {
            display: grid;
            gap: 15px;

            hr {
                margin: 0px 0px -18px;
            }
        }

        .control {
            //display: flex;
            margin: 0px;
            gap: 10px;
            min-height: 24px;
            //margin-bottom: 15px;
            &:last-of-type {
                margin-bottom: 0px;
            }
            label {
                // This impacts the chart label drop down menu, if we need to revert it, please test if the text is still aligned correctly
                /*margin-right: 10px;*/
            }

            &.button {
                pointer-events: auto;
                cursor: pointer;
                font-size: 12px;
                font-weight: 600;
                padding: 10px;
                color: white;
                line-height: normal;
                text-align: center;
                display: flex;
                justify-content: center;
                outline: none;
                text-transform: uppercase;
                @include no_user_select;
                label {
                    font-size: 12px;
                    cursor: pointer;
                    color: white;
                }
            }

            &.options-list-icons {
                margin-top: 10px;
            }

            &.slider {
                input[type="range"]::-webkit-slider-thumb {
                    background: $darkSelectionColor;
                }
            }
        }
        hr + .control.button {
            margin-top: 10px;
        }

        .section {
            font-weight: 600;
            text-transform: uppercase;
        }

        .warning {
            font-size: 12px;
            font-weight: 500;
            color: orangered;
        }

        .row {
            display: flex;
            .control {
                margin: 0px 20px 0px 0px;
            }
        }

        .tabs {
            text-transform: uppercase;
            display: inline-block;
            padding-left: 6px;
            .tab {
                display: inline-block;
                vertical-align: middle;
                margin-right: 30px;
                text-transform: uppercase;
                font-size: 14px;
                font-weight: 600;
                cursor: pointer;
                color: #555;
                &.selected {
                    color: $darkSelectionColor;
                }
            }
        }
    }

    .menu_items {
        max-width: 250px;
    }

    .menu_item,
    .submenu_item {
        font-weight: 600;
        font-size: 13px;
        text-align: left;
        color: #444;
        white-space: pre;
        padding: 15px 20px 15px 15px;
        //min-width: 159px;
        cursor: pointer;
        display: flex;
        align-items: center;
        @include no_user_select;
        &:hover:not(.disabled) {
            background: $selectionColor;
            color: white;
            & > i {
                color: white;
            }
        }
        i {
            vertical-align: -6px;
            font-size: 20px;
            color: #777;
            margin-right: 6px;
        }
        img {
            vertical-align: middle;
            margin-right: 8px;
            max-width: 22px;
            //border: solid 1px #ccc;
            //background: white;
        }
        .shortcut {
            color: rgba(0, 0, 0, 0.6);
            margin-left: auto;
            padding-left: 10px;
        }
        &.disabled {
            opacity: 0.3;
            pointer-events: none;
        }
        &.selected {
            background: #eee;
        }
    }

    .submenu_item {
        position: relative;
        &:after {
            content: "\E315";
            font-family: "Material Icons";
            font-size: 20px;
            position: absolute;
            //top: 8px;
            right: 7px;
            color: #333;
        }
    }

    .betabadge-menu-item {
        &:after {
            content: "BETA";
        }
        color: white;
        background: $newBlue;
        padding: 3px 5px;
        border-radius: 2px;
        margin-left: 10px;
        font-size: 10px;
        font-weight: 600;
        height: 19px;

    }

    .menu_control {
        display: flex;
        align-items: center;
        padding: 8px;
        label {
            color: #444;
        }
    }

    hr {
        border-style: none;
        width: 100%;
        height: 1px;
        background: #eee;
        margin: 15px 0px;
    }

    .grid {
        hr {
            margin: 5px 0px;
        }
    }

    .controls {
        margin-top: 10px;
        .control {
            display: inline-block;
            margin-right: 30px;
            margin-bottom: 0px;
        }
        .control.slider {
            input[type="range"]::-webkit-slider-thumb {
                background: $selectionColor;
            }
        }
        .control.toggle {
            label {
                min-width: auto;
            }
        }
    }

    .control.options-list-icons {
        .option-item {
            border: solid 1px #ddd;
            .option-label {
                color: #555;
            }
        }
    }
}

.grid_count {
    padding: 0px 14px;
    background: $button_color;
    border-radius: 5px;
    .control {
        &.numeric {
            margin-right: 6px;
            label {
                vertical-align: -1px;
                font-weight: 600;
                color: white;
                margin-right: 8px;
            }
            input {
                border: none;
                outline: none;
                font-size: 14px;
                font-weight: 600;
                vertical-align: middle;
                text-align: center;
            }
        }
    }
}

.drag_handle {
    background: url("../images/ui/drag_handle.png") no-repeat 4px 9px;
    width: 16px;
    height: 100%;
    display: inline-block;
    float: left;
    cursor: move;
}

.decorations {
    .decoration {
        float: left;
        width: 100px;
        height: 20px;
        border: solid 1px $ui_border_color;
    }
}

.dropdown_icon {
    &:after {
        content: "\e5c5";
        font-family: "Material Icons";
        font-size: 13px;
        vertical-align: -2px;
        padding-right: 10px;
    }
}

.color_chit {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 5px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    position: relative;
    border: solid 1px #ccc;

    .background {
        position: absolute;
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100%;
        background: white;
        border-radius: 50%;
        box-shadow: 0 0 1px 0px inset currentColor;
    }

    &.none {
        &:before {
            @include close_icon;
            font-size: 18px;
            color: #333;
        }
    }
    &.add_photo {
        border: solid 1px $selectionColor;
        color: $selectionColor;
        //padding: 5px;
        &:before {
            font-family: "Material Icons";
            content: "\E439";
            font-size: 18px;
            //position: absolute;
            //top: 4px;
            //left: 4px;
        }
    }

    .auto {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        z-index: 100;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 9px;
        font-weight: 600;
        color: white;
    }

    &.large {
        width: 40px;
        height: 40px;
    }
    &:not(.colorful_chit) {
        &:before {
            content: "";
            position: absolute;
            left: 0px;
            top: 0px;
            width: 100%;
            height: 100%;
            //background: white;
            border-radius: 50%;
        }

        &:after {
            content: "";
            position: absolute;
            left: 0px;
            top: 0px;
            width: 100%;
            height: 100%;
            background: currentColor;
            border-radius: 50%;
            box-shadow: 0 0 1px 0px currentColor, 0 0 1px 0px inset currentColor;
        }
    }
    &.selected {
        //&:after {
        //    content: "";
        //    position: absolute;
        //    left: -1px;
        //    top: -1px;
        //    width: 100%;
        //    height: 100%;
        //    border-radius: 50%;
        //    border: solid 1px white;
        //    box-shadow: 0 0 1px 0px white, 0 0 1px 0px inset white;
        //}
    }
    .disabled-chit {
        position: absolute;
        width: 4px;
        height: 100%;
        background: #222;
        left: calc(50% - 2px);
        top: 0px;
        transform: rotate(45deg);
        border-radius: 0;
        z-index: 100;
    }

    &.disabled {
        pointer-events: none;
    }
    .color_label {
        font-size: 9px;
        letter-spacing: 0px;
        color: white;
        mix-blend-mode: difference;
        text-transform: uppercase;
        font-weight: 500;
        position: absolute;
        z-index: 100;
    }
}

.colorful_chit {
    width: 30px;
    height: 30px;
    border-radius: 100px;
    overflow: hidden;
    cursor: pointer;
    .container {
        width: 100%;
        height: 100%;
        display: inline-flex;
        border-radius: 100px;
        overflow: hidden;
        vertical-align: top;
        div {
            width: 4px;
            margin-right: 0px;
            height: 100%;
            display: inline-block;
            vertical-align: top;
            flex-grow: 1;
        }
    }
}

.thumbnailBox {
    display: flex;
    flex-wrap: wrap;
    max-width: 1060px;
    margin-bottom: -10px;
    margin-right: -10px;
    .thumbnail {
        position: relative;
        width: 150px;
        height: 90px;
        margin-bottom: 10px;
        margin-right: 10px;
        //outline: solid 1px transparent;
        text-align: center;

        img {
            width: 100%;
            height: 100%;
        }
        &:after {
            content: "";
            position: absolute;
            top: 0px;
            left: 0px;
            width: 148px;
            height: 88px;
            border: solid 1px #bbb;
        }
        &:hover {
            &:after {
                border-color: $selectionColor;
            }
        }
        &.selected {
            &:after {
                border-color: $selectionColor;
            }
        }
    }
}

.backgroundStyle {
    width: 127px;
    height: 72px;
    display: inline-block;
    margin-right: 10px;
    border: solid 1px #ccc;
    text-align: center;
    &:hover {
        background: #f1f1f1;
    }
}

.control.thumbnail {
    width: 100px;
    height: 80px;
    background: #eee;
    border: solid 1px #aaa;
    text-align: center;
    color: #555;
    text-transform: uppercase;
    padding-top: 34px;
}

.image_option_list {
    .option {
        &:before {
            content: "";
            height: 100%;
            display: inline-block;
            vertical-align: middle;
        }
        display: inline-block;
        margin-right: 10px;
        background: white;
        border: solid 1px $ui_border_color_disabled;
        padding: 0px 14px;
        height: 40px;
        text-align: center;
        img {
            vertical-align: middle;
        }

        &.selected {
            border: solid 1px $ui_border_color;
        }
        &:hover {
            background: $ui_hover_color;
        }
    }
}

table.editlist {
    background: white;
}

select[multiple] {
    margin: 10px 0px;
    width: 100%;
    height: 220px;
    overflow: auto;
    font-family: $ui_font;
    font-size: 12px;
    border: solid 1px $ui_border_color;
    outline: none;
    option {
        padding: 6px;
    }
    option:checked {
        background: $selectionColor;
    }
}

.add_widget {
    background: $ui_background_color;
    border: solid 1px $ui_border_color;
    width: 12px;
    height: 12px;
}

.spinner {
    position: absolute !important;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .circular {
        //transform: translate(0, 0);
        width: 40px;
        height: 40px;
        transform-origin: center center;
        //position: absolute;
        //top: 0;
        //bottom: 0;
        //left: 0;
        //right: 0;
        //margin: auto;
    }

    .label {
        width: 100%;
        text-align: center;
        margin-top: 10px;
        font-size: 11px;
        font-weight: 600;
        letter-spacing: 0.1em;
        color: white;
        text-transform: uppercase;
    }

    .path {
        stroke: $selectionColor;
        fill: none;
        stroke-width: 4px;
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
        stroke-miterlimit: 10;
        animation: dash 1.5s ease-in-out infinite;
        stroke-linecap: round;
    }

    @keyframes rotate {
        100% {
            transform: rotate(360deg);
        }
    }
    @keyframes dash {
        0% {
            stroke-dasharray: 1, 200;
            stroke-dashoffset: 0;
        }
        50% {
            stroke-dasharray: 89, 200;
            stroke-dashoffset: -35px;
        }
        100% {
            stroke-dasharray: 89, 200;
            stroke-dashoffset: -124px;
        }
    }
}

.smart_table {
    .add_col_button {
        position: absolute;
        color: $selectionColor;
        font-size: 16px;
        //width: 16px;
        //height: 16px;
        background: white;
    }
    table {
        border-collapse: collapse;
        margin-bottom: 20px;
        width: auto;
        input {
            padding: 8px;
            margin: 0px;
            border: none;
            box-sizing: border-box;
            text-align: center;
            //outline: solid 1px #e1e1e1;
            outline: none;
        }

        td {
            border: solid 1px $ui_border_color;
            width: 150px;
        }

        thead {
            th {
                input {
                    font-weight: 600;
                    //background: #f4f4f4;
                }
                border: solid 1px #bbb;
                border-bottom: solid 1px $ui_border_color;
            }
            th.blank {
                border: none;
            }
        }

        td.series_label {
            width: 200px;
            & > div {
                width: 180px;
            }
            border: solid 1px #bbb;
            border-right: solid 1px $ui_border_color;
            input {
                text-align: left;
                display: inline-block;
                font-weight: 600;
                width: 100px;
                vertical-align: middle;
            }
            .control {
                margin: 0px;
                display: inline-block;
            }
        }

        td.chart_type {
            border: none;
            input {
                width: 100px;
            }
        }

        tr:hover {
            td.delete {
                opacity: 1;
            }
        }

        td.delete {
            opacity: 0;
            border: none;
            text-align: left;
            padding-left: 7px;
            width: 50px;
            i {
                font-size: 14px;
                vertical-align: middle;
            }
        }
    }
}

.color-picker-popup {
    position: absolute;
    width: 300px;
    height: 200px;
    padding: 10px;
    background: white;
    box-shadow: 4px 4px 5px rgba(0, 0, 0, 0.2);
    border: solid 1px #aaa;
    .slide-wrapper {
        position: relative;
        width: 20px;
        left: 200px;
    }
    .picker-wrapper {
        position: absolute;
        width: 200px;
        height: 150px;
        overflow: hidden;
    }
    .picker-indicator {
        width: 3px;
        height: 3px;
        position: absolute;
        border: 1px solid white;
        pointer-events: none;
    }
    .slide-indicator {
        width: 100%;
        height: 10px;
        position: absolute;
        border: 1px solid black;
        pointer-events: none;
    }
}

// custom slider

input[type="range"] {
    -webkit-appearance: none;
    width: 100%;
    background: transparent;
}

input[type="range"]:focus {
    outline: none;
}

input[type="range"]::-webkit-slider-runnable-track {
    width: 100%;
    height: 4px;
    cursor: pointer;
    //animate: 0.2s;
    background: #7cc2dc;
}

input[type="range"]::-webkit-slider-thumb {
    height: 12px;
    width: 12px;
    border-radius: 100px;
    background: white;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -4px;
}

input[type="range"]:focus::-webkit-slider-runnable-track {
    //background: #367ebd;
}

input[type="range"]::-moz-range-track {
    width: 100%;
    height: 4px;
    cursor: pointer;
    //animate: 0.2s;
    background: #7cc2dc;
}

input[type="range"]::-moz-range-thumb {
    height: 12px;
    width: 12px;
    border-radius: 100px;
    background: white;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -4px;
}

input[type="range"]::-ms-track {
    width: 100%;
    height: 8.4px;
    cursor: pointer;
    animate: 0.2s;
    background: transparent;
    border-color: transparent;
    border-width: 16px 0;
    color: transparent;
}

input[type="range"]::-ms-fill-lower {
    background: #2a6495;
    border: 0.2px solid #010101;
    border-radius: 2.6px;
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
}

input[type="range"]::-ms-fill-upper {
    background: #3071a9;
    border: 0.2px solid #010101;
    border-radius: 2.6px;
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
}

input[type="range"]::-ms-thumb {
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
    border: 1px solid #000000;
    height: 36px;
    width: 16px;
    border-radius: 3px;
    background: #ffffff;
    cursor: pointer;
}

input[type="range"]:focus::-ms-fill-lower {
    background: #3071a9;
}

input[type="range"]:focus::-ms-fill-upper {
    background: #367ebd;
}

.control.slider.dark {
    input[type="range"] {
        -webkit-appearance: none;
        width: 100%;
        background: transparent;
    }

    input[type="range"]::-webkit-slider-runnable-track {
        width: 100%;
        height: 4px;
        cursor: pointer;
        background: #ccc;
    }

    input[type="range"]::-webkit-slider-thumb {
        height: 12px;
        width: 12px;
        border-radius: 100px;
        background: $darkSelectionColor;
        cursor: pointer;
        -webkit-appearance: none;
        margin-top: -4px;
    }
}

.audio-loader {
    &.has-file {
        justify-content: left !important;
        padding-left: 10px !important;
        border: solid 1px #666 !important;
        background: none !important;
        cursor: default !important;
        .upload-label {
            flex-grow: 2;
            text-align: left;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
    }
}

.upload-progress-bar {
    background: $selectionColor;
    position: absolute;
    left: 0px;
    top: 0px;
    height: 100%;
    width: 0%;
    z-index: -1;
}

.icon-control-bar {
    .control {
        //background: white !important;
        padding: 0px 10px !important;
        & > img {
            filter: invert(1);
        }
    }
}

.admins .profile {
    width: 50%;
    float: left;
}

.profile {
    display: flex;
    align-items: center;

    .name-email {
        .name {
            user-select: text;
            font-size: 15px;
            font-weight: 600;
            color: #333;
        }
        .email {
            user-select: text;
            font-size: 12px;
            color: #666;
        }
        .highlighted {
            color: #222;
        }
        .underlighted {
            color: #A9A9A9;
        }
    }

    .email-only {
        .email {
            user-select: text;
            color: #333;
            font-size: 15px;
            font-weight: 600;
            word-break: break-word;
        }
        .highlighted {
            color: #222;
        }
        .underlighted {
            color: #A9A9A9;
        }
    }

    .pending {
        font-weight: 600;
        color: #a9a9a9;
        font-size: 13px;
    }

    .avatar {
        margin-right: 10px;
        flex-shrink: 0;
    }
}

.invite-input {
    width: 100%;
}

.tags-input {
    max-height: 120px;
    overflow-y: auto !important;
}

.is_mobile {
    .invite-input {
        .tags-input {
            max-height: 80px;
            overflow-y: auto;

            & > div {
                margin-bottom: 0;

                input {
                    margin-bottom: 0;
                }
            }
        }
    }
}

.pulse-circle {
    position: absolute;
    left: 16px;
    top: 13px;
    border-radius: 50%;
    background: white;
    width: 10px;
    height: 10px;
}

.frame-popup {
    padding: 0px !important;
    .MuiTabs-root {
        background: #f1f1f1;
    }
    .MuiTab-root {
        font-weight: 600;
    }
    .MuiTabs-indicator {
        background: $newBlue;
    }
}

.element-ui.ContentElement {

    .small-content-popup-button {
        border-radius: 50% !important;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0;
    }

    &:hover {
        .small-content-popup-button {
            opacity: 1;
        }
    }
}
