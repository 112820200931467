@import url(material-ui-font.scss);

.profiler-view {
    position: fixed;
    bottom: 5px;
    padding: 2px;
    font-size: 8px;
    color: #aaaaaa;
    text-align: left;
    z-index: 15000;
    pointer-events: none;
    font-family: monospace;
    line-height: 1.2;

    .stat {
        display: block;
    }

    .value.stat-changed {
        color: #88ff93;
        &.warn {
            color: #ffffff;
            background-color: #b44113;
        }
    }

    .value.fade-changed {
        transition: color 2s, background-color 2s;
    }

    .value.warn {
        color: #cccccc;
        background-color: #802e0e;
    }

    .label {
        font-size: 8px;
        display: inline-block;
        width: 100px;
    }

    .value {
        font-size: 8px;
        display: inline-block;
    }
}

#player .profiler-view {
    right: 5px;
    background: black;
    opacity: 0.75;
}

#mainView .profiler-view {
    left: 80px;
    //display: none;
}
