@import "common";

.click_shield {
    position: absolute;
    pointer-events: auto;
    z-index: 10000;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.25);
    cursor: default;

    &.transparent {
        background: none;
    }
}

.dialog_container {
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 0px;
    left: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 5000;
}

.modal_dialog,
.sidebar,
.template_slide {
    background: #f1f1f1;
    //border: solid 1px $ui_border_color;
    //box-shadow: 0px 0px 20px rgba(0, 0, 0, .2);
    //position: absolute !important;
    font-size: 14px;
    cursor: default;

    max-width: 100vw;
    max-height: 100vh;

    [contenteditable] {
        -webkit-user-select: text;
        user-select: text;
    }

    .close_button {
        color: #333;
    }

    .header {
        height: 40px;
        //border-bottom: solid 1px #eee;
        padding: 0px 20px;
        background: #f1f1f1;
        //justify-content: flex-start;
        //align-content: center;
        text-align: center;
        pointer-events: none;

        display: flex;
        align-items: center;

        //& > div {
        //  display: inline-block;
        //}

        & > .title {
            //position: absolute;
            //left: 20px;
            //top: 10px;    3
            color: #333;
            font-size: 15px;
            letter-spacing: 0.9px;
            font-weight: 600;
            text-transform: uppercase;
            line-height: 20px;
            margin-right: 20px;
        }

        .tabs {
            margin-top: 8px;
        }

        .control.button {
            background: none !important;
            color: #333 !important;
            border: none !important;
            margin: 0px;
        }
    }

    .close_button {
        &:before {
            font-family: "Material Icons";
            content: "\E5CD";
            font-size: 18px;
        }

        position: absolute;
        pointer-events: auto;
        right: 5px;
        top: 5px;
        width: 40px;
        height: 40px;
        color: #555;
        cursor: pointer;
        text-align: center;
        padding-top: 5px;
        z-index: 100;
    }

    *:not(.speaker-notes-text) > p {
        font-size: 16px;
        margin-bottom: 20px;
        line-height: 22px;

        &:last-of-type {
            margin-bottom: 0px;
        }

        color: #333;
    }

    .tabs {
        display: inline-block;
        //position: absolute;
        pointer-events: auto;
        //width: 100%;
        text-align: center;

        .tab {
            display: inline-block;
            vertical-align: middle;
            margin-right: 40px;
            text-transform: uppercase;
            font-size: 14px;
            font-weight: 600;
            cursor: pointer;
            color: white;

            &.selected {
                color: $selectionColor;
            }
        }
    }

    .search_frame {
        pointer-events: none;
        background: #595d65;
        height: 100px;
        box-shadow: inset 0px -5px 30px rgba(0, 0, 0, 0.2);
        z-index: 10;
        display: flex;
        align-items: center;
        align-content: center;
        //width: calc(100% - 15px);
        justify-content: center;
        flex-wrap: wrap;
        //background: linear-gradient(to bottom, rgb(247, 248, 249) 60%, rgba(247, 248, 249, 0) 100%);
        .search {
            margin-bottom: 0px;
            pointer-events: auto;
            display: inline-block;
            background: white;
            width: 800px;
            padding: 10px;
            border-radius: 3px;
            box-shadow: 1px 10px 33px rgba(0, 0, 0, 0.3);

            .micon {
                vertical-align: -12px;
                font-size: 30px;
                color: $button_color;
                cursor: default;
                margin: 0px 4px;
            }

            .clear_search {
                display: none;
                color: #ccc;
                vertical-align: middle;
                font-size: 24px;
            }

            input[type="text"] {
                margin: 0px 3px;
                width: calc(100% - 76px);
                vertical-align: middle;
                font-size: 26px;
                font-weight: 600;
                border: none;
                padding: 0px;
                background: none;
                outline: none;
            }
        }
    }

    hr {
        //margin-top: 20px;
        //margin-bottom: 20px;
        border-style: none;
        height: 1px;
        background: #ddd;
    }

    & > .contents {
        //background: #E9EAED;
        background: #f1f1f1;
        clear: both;
        padding: 20px 20px 20px;
        position: relative;
        overflow: auto;
        height: calc(100% - 40px);
        //padding: 15px 30px;
    }

    form {
        margin: 20px 0px;

        .row {
            margin-bottom: 10px;

            label {
                margin-bottom: 4px;
            }

            input {
                box-sizing: border-box;
            }
        }
    }

    .column {
        float: left;
        margin-left: 40px;

        .label {
            font-weight: 600;
            font-size: 16px;
            margin-bottom: 10px;
        }
    }

    .panel {
        margin-bottom: 20px;
    }

    .buttons {
        display: flex;
        padding-top: 10px;
        width: 100%;
        align-items: center;
        justify-content: center;

        .button {
            margin-bottom: 0px;
        }

        .spacer {
            display: inline-block;
            flex-grow: 2;
        }
    }

    .button {
        margin-right: 6px;
        background: white;
        pointer-events: auto;
        color: #333;
        font-size: 13px;
        font-weight: 600;
        padding: 7px 20px;
        vertical-align: middle;
        display: inline-block;
        min-width: 100px;
        height: 34px;
        text-align: center;
        outline: none;
        box-sizing: border-box;
        border: solid 1px $darkSelectionColor;
        text-transform: uppercase;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        cursor: pointer;
        //&.accept {
        //  background: $darkSelectionColor;
        //  border-color: $darkSelectionColor;
        //  color: white;
        //}
        &:last-child {
            margin-right: 0px;
        }

        &:hover {
            color: #333 !important;
            background: $lightSelectionColor !important;
        }
    }

    .section {
        clear: both;
        display: block;
        margin-top: 30px;
        margin-bottom: 10px;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 600;

        &:first-child {
            margin-top: 0px;
        }
    }

    .control_row {
        .control {
            display: inline-block;
            margin-right: 20px;
            vertical-align: top;
        }
    }

    .control.dropdown_menu_prompt .selected_item {
        background: white;
    }

    .control {
        margin-bottom: 10px;
    }

    input {
        font-size: 14px;
        padding: 6px 10px;
        margin-top: 10px;
        margin-bottom: 10px;
        box-sizing: border-box;
    }

    input[type="text"] {
        width: 100%;
    }

    /* Safari needs this */
    .theme-frame {
        input {
            padding-top: 0;
            padding-bottom: 0;
        }
    }
}

#mainView {
    .table_box {
        .error {
            margin-top: 10px; //must match margin-top of input selector above
        }
    }
}

.sidebar .header {
    height: 32px;
    & > .title {
        font-size: 14px;
    }
    .close_button {
        right: -4px;
        top: 2px;
        color: #ccc;
    }
}

.modal_dialog.progress_dialog {
    position: absolute;
    width: 500px;
    height: 100px;
    display: flex !important;
    align-items: center;
    justify-content: center;
    background: $selectionColor;
    color: white;
    font-size: 20px;
    border: solid 2px white;

    div {
        display: inline-block;
        font-weight: 600;
    }

    .spinner {
        display: inline-block;
        transform: scale(0.5);
        position: static !important;
        width: 40px;
        height: 40px;

        .path {
            stroke: white;
        }
    }
}

.image_uploader {
    position: relative;
    background: white;
    border: solid 1px #ddd;
    padding: 10px;

    img {
        max-width: 100%;
        max-height: 100%;
    }

    &.no_image {
        img {
            display: none;
        }

        i.delete {
            display: none !important;
        }
    }

    .file_upload {
        position: absolute;

        justify-content: center;
        text-align: center;
        padding-top: 12px;
        width: 50px;
        height: 50px;
        left: calc(50% - 25px);
        top: calc(50% - 25px);
        color: white;
        display: none;
        background: $ui_background_color;
        border-radius: 100px;

        input[type="file"] {
            opacity: 0;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    &:hover {
        .file_upload {
            display: flex;
        }
    }

    .delete_logo {
        background: $ui_background_color;
        position: absolute;
        top: 3px;
        right: 3px;
        border-radius: 100px;
        color: white;
        font-size: 15px;
        cursor: default;
    }
}

.sidebar {
    width: 100%;
    height: 100%;
    .contents {
        padding: 10px 20px 20px 20px; //!important;
    }

    .sidebar_resizebar {
        cursor: row-resize;
        height: 5px;
        position: absolute;
        width: 100%;
    }
}

.modal_dialog {
    min-width: 500px;
    width: 800px;
    max-width: 1800px;
    z-index: 10020;
    box-shadow: 0px 0px 20px rgba(50, 96, 148, 0.2);

    @media only screen and (max-width: 736px) and (orientation: landscape) {
        width: 100%;
        height: 100%;
    }
    @media only screen and (max-width: 736px) and (orientation: portrait) {
        width: 100%;
        height: 100%;
        min-width: auto;
    }

    &.warning_dialog {
        font-size: 18px;
        text-align: center;
        border-color: $selectionColor;
        width: 700px;

        .header {
            background: $selectionColor;

            .title {
                color: white;
            }
        }
    }
}

//.color_chit {
//  width: 14px;
//  height: 14px;
//  margin-right: 2px;
//  margin-bottom: 2px;
//  display: inline-block;
//  &.none{
//    &:before{
//      @include close_icon;
//      font-size: 18px;
//      color: red;
//    }
//  }
//}
.add_map_marker_dialog {
    .results {
        margin-top: 10px;

        .result {
            padding: 5px 6px;
            cursor: default;
            @include no_user_select;

            &:hover {
                background: $ui_hover_color;
            }
        }
    }
}

.collaborate_dialog,
.share_dialog {
    .copybox {
        cursor: pointer;
        border: solid 1px #aaa;
        background: white;
        display: flex;
        align-items: center;
        padding: 0px;
        height: 40px;
        margin-bottom: 20px;

        input[type="text"] {
            margin: 0px;
            width: 100%;
            border: none;
            font-size: 14px;
            font-family: "Source Sans Pro";
            color: #777;
        }

        .copy_to_clipboard {
            display: flex;
            align-items: center;
            width: 134px;
            padding: 6px 11px;
            font-size: 12px;
            color: #555;
            background: #ddd;
            text-transform: uppercase;
            height: 100%;
            font-weight: 600;

            &:hover {
                background: $darkSelectionColor;
                color: white;
            }

            i {
                margin-right: 4px;
            }
        }
    }

    .privacy {
        margin: 0px;
        margin-bottom: 30px;

        label {
            font-size: 14px;
            margin-bottom: 9px;
        }

        .toggle_container {
            padding: 0px;
            height: 40px;
            display: flex;
            align-items: center;
            border: solid 1px #444;
            border-radius: 100px;

            .toggle_button {
                font-size: 18px;
                height: 100%;
                padding: 7px 20px 0px 14px;

                &:first-of-type {
                    border-radius: 100px 0px 0px 100px;

                    &.selected {
                        background: #7bc12f;
                    }
                }

                &:last-of-type {
                    border-radius: 0px 100px 100px 0px;

                    &.selected {
                        background: #c83400;
                    }
                }
            }

            i {
                float: left;
                margin-right: 6px;
            }
        }
    }

    .description {
        font-size: 12px;
        color: #444;
        font-weight: 600;
        text-transform: uppercase;
        margin-left: 20px;
    }

    .owner-only {
        font-size: 12px;
        color: #444;
        font-weight: 600;
        text-transform: uppercase;
        margin-bottom: 10px;
        font-style: italic;
    }

    .share_links {
        //margin-top: 30px;
    }

    label.title {
        font-size: 12px;
        text-transform: uppercase;
        font-weight: 600;
        margin-bottom: 6px;
        //margin-top: 20px;
        display: block;
    }

    label.title.people {
        margin-top: 0;
    }

    .invite {
        display: flex;
        padding: 0;

        .button {
            vertical-align: top;
            margin-left: 20px;
        }

        .dropdown_menu_prompt {
            vertical-align: top;
            height: 36px;

            .selected_item {
                border-left: 0;
                min-width: 95px;

                .selected_item_label {
                    line-height: 2;
                }

                i {
                    font-size: 24px;
                }
            }
        }

        .tagged_input {
            vertical-align: top;
            width: 100%;

            .tag-editor li {
                margin: 7px 0;
            }
        }
    }

    .note {
        width: 640px;
        height: 90px;
        border: 1px solid #ddd;
        font-size: 14px;
        padding: 7px 14px;
        font-family: inherit;
        letter-spacing: inherit;

        @media only screen and (max-width: 736px) and (orientation: landscape) {
            width: 100%;
        }
        @media only screen and (max-width: 736px) and (orientation: portrait) {
            width: 100%;
        }

        &::placeholder {
            color: #bbb;
            font-style: normal;
        }

        box-sizing: border-box;
        resize: none;
    }

    .user_summary {
        opacity: 0;
        transition: opacity 0.2s ease-in-out;
        color: #444;
        pointer-events: none;
        margin-top: 6px;

        .details {
            float: right;
            margin-right: 10px;
            cursor: pointer;
            font-weight: 600;

            &:hover {
                color: #11a9e2;
            }
        }

        &.fade_in {
            pointer-events: auto;
            opacity: 1;
        }
    }

    hr {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    //.permissions {
    //
    //  //padding: 0 20px;
    //  margin-left: -7px;
    //  padding-right: 20px;
    //  overflow: auto;
    //  height: 280px;
    //
    //  ul {
    //    margin: 0;
    //    padding: 0;
    //    list-style-type: none;
    //  }
    //
    //  li {
    //    display: flex;
    //    //justify-content: space-between;
    //    align-items: center;
    //    border-bottom: 1px solid #ddd;
    //    clear: both;
    //    > * {
    //      white-space: nowrap;
    //      margin: 10px;
    //    }
    //
    //    &:last-child {
    //      border: 0;
    //    }
    //    &.empty {
    //      margin: 10px 0;
    //      text-align: center;
    //      width: 100%;
    //      display: block;
    //      color: #aaa;
    //    }
    //  }
    //
    //  .avatar {
    //    min-width: 30px;
    //  }
    //
    //  .name {
    //    //width: 100%;
    //    //width: 52.5%;
    //    width: 54.5%;
    //    .fullname {
    //      display: block;
    //    }
    //    .email {
    //      font-size: 12px;
    //      color: #444;
    //      display: block;
    //    }
    //  }
    //
    //  .permission {
    //    min-width: 100px;
    //    margin-right: 10px;
    //    &.control.dropdown_menu_prompt .selected_item {
    //      min-width: 100px;
    //    }
    //  }

    //.pending {
    //  min-width: 45px;
    //  margin-right: 10px;
    //  text-transform: uppercase;
    //  font-size: 10px;
    //  color: #444;
    //}
    //
    //.remove {
    //  min-width: 20px;
    //  color: #aaa;
    //  cursor: pointer;
    //  transition: color 0.2s ease;
    //  &:hover {
    //    color: #444
    //  }
    //  .micon {
    //    font-size: 20px;
    //  }
    //
    //}

    //  .loader {
    //     overflow: hidden;
    //     height: 50px;
    //     width: 100%;
    //     position: relative;
    //   }
    //}
    .collaboration {
        .title {
            margin-top: 0px;
        }
    }

    .collaboration .back_button {
        margin: 20px auto 0px;
        width: 100px;
        display: block;
    }
}

.share_dialog {
    .header {
        height: 10px;
    }

    .contents {
        padding: 20px 0;
    }
}

.displaytext_edit_dialog {
    textarea {
        width: 100%;
        font-size: 16px;
        height: 36px;
        padding: 8px;
        margin-top: 6px;
        margin-bottom: 10px;
        resize: none;
        box-sizing: border-box;
    }

    .text {
        padding: 8px;
        border: solid 1px #333;
        font-size: 18px;
        color: #333;
        margin-top: 6px;
        margin-bottom: 10px;
        display: block;
    }

    label {
        font-size: 14px;
        text-transform: uppercase;
        font-weight: 600;
        color: #333;
    }
}

.quotes_dialog {
    #quotes {
        width: 100%;
        height: 500px;
        overflow-y: scroll;
    }

    .search {
        //position: absolute;
        width: 100%;
        margin-bottom: 10px;
        display: inline-block;
        vertical-align: middle;
        height: 32px;
        padding: 4px 6px;
        border: solid 1px $button_color;
        border-radius: 6px;
        //background: lighten($button_color, 44%);
        .micon {
            vertical-align: middle;
            font-size: 20px;
            color: $button_color;
            cursor: default;
        }

        .clear_search {
            font-size: 16px;
            display: none;
        }

        input {
            margin: 0px 3px;
            width: calc(100% - 48px);
            vertical-align: middle;
            font-size: 15px;
            border: none;
            padding: 0px;
            background: none;
            outline: none;
        }
    }

    .quote {
        border: solid 1px #f1f1f1;
        padding: 10px;
        margin-right: 10px;
        background: #f1f1f1;
        margin-bottom: 10px;

        &:hover {
            background: lighten($button_color, 30%);
        }

        .text {
            font-size: 18px;
        }

        .author {
            text-align: right;
        }

        .category {
            text-align: right;
            text-transform: uppercase;
        }
    }
}

.tab_content {
    display: none;
    min-height: 400px;

    &.selected {
        display: block;
    }
}

.add_content_menu {
    margin-right: -10px;
    margin-bottom: -10px;

    .col {
        display: inline-block;
        vertical-align: top;
        width: 400px;
        margin-right: 20px;

        .label {
            color: $selectionColor;
            font-size: 14px;
            text-transform: uppercase;
            font-weight: 500;
            border-bottom: solid 2px $selectionColor;
            width: 100%;
            padding-bottom: 10px;
            margin-bottom: 30px;
        }
    }

    .item {
        margin-bottom: 20px;

        .thumbnail {
            display: inline-block;
            vertical-align: middle;
            width: 141px;
            height: 79px;
            border: solid 1px #aaa;

            img {
                width: 100%;
                height: 100%;
            }
        }

        .info {
            display: inline-block;
            vertical-align: middle;
            margin-left: 10px;
            width: 200px;

            .title {
                color: #555;
                font-size: 16px;
                font-weight: 600;
            }

            .description {
                color: #999;
                font-size: 14px;
                font-weight: 500;
            }
        }
    }
}

.generic_progress_dialog {
    .generic_progress_container {
        width: 100%;
        height: 20px;
        position: relative;
        background: #555;
        -moz-border-radius: 5px;
        -webkit-border-radius: 5px;
        border-radius: 5px;
        -webkit-box-shadow: inset 0 -1px 1px rgba(255, 255, 255, 0.3);
        -moz-box-shadow: inset 0 -1px 1px rgba(255, 255, 255, 0.3);
        box-shadow: inset 0 -1px 1px rgba(255, 255, 255, 0.3);
        margin-bottom: 10px;

        .generic_progress_inner {
            width: 0;
            transition: width 100ms ease-in-out;
            display: block;
            height: 100%;
            -moz-border-radius: 5px 3px 3px 5px;
            -webkit-border-radius: 5px 3px 3px 5px;
            border-radius: 5px 3px 3px 5px;
            background-color: $selectionColor;
            background-image: -webkit-gradient(
                linear,
                left bottom,
                left top,
                color-stop(0, $selectionColor),
                color-stop(1, lighten($selectionColor, 10%))
            );
            background-image: -moz-linear-gradient(
                center bottom,
                $selectionColor 37%,
                lighten($selectionColor, 10%) 69%
            );
            -webkit-box-shadow: inset 0 2px 9px rgba(255, 255, 255, 0.3),
                inset 0 -2px 6px rgba(0, 0, 0, 0.4);
            -moz-box-shadow: inset 0 2px 9px rgba(255, 255, 255, 0.3),
                inset 0 -2px 6px rgba(0, 0, 0, 0.4);
            box-shadow: inset 0 2px 9px rgba(255, 255, 255, 0.3),
                inset 0 -2px 6px rgba(0, 0, 0, 0.4);
            position: relative;
            overflow: hidden;

            &:after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                background-image: -webkit-gradient(
                    linear,
                    0 0,
                    100% 100%,
                    color-stop(0.25, rgba(255, 255, 255, 0.2)),
                    color-stop(0.25, transparent),
                    color-stop(0.5, transparent),
                    color-stop(0.5, rgba(255, 255, 255, 0.2)),
                    color-stop(0.75, rgba(255, 255, 255, 0.2)),
                    color-stop(0.75, transparent),
                    to(transparent)
                );
                background-image: -moz-linear-gradient(
                    -45deg,
                    rgba(255, 255, 255, 0.2) 25%,
                    transparent 25%,
                    transparent 50%,
                    rgba(255, 255, 255, 0.2) 50%,
                    rgba(255, 255, 255, 0.2) 75%,
                    transparent 75%,
                    transparent
                );
                z-index: 1;
                -webkit-background-size: 50px 50px;
                -moz-background-size: 50px 50px;
                background-size: 50px 50px;
                -webkit-animation: move 2s linear infinite;
                -moz-animation: move 2s linear infinite;
                -moz-border-radius: 5px 3px 3px 5px;
                -webkit-border-radius: 5px 3px 3px 5px;
                border-radius: 5px 3px 3px 5px;
                overflow: hidden;
            }
        }

        @-webkit-keyframes move {
            0% {
                background-position: 0 0;
            }
            100% {
                background-position: 50px 50px;
            }
        }

        @-moz-keyframes move {
            0% {
                background-position: 0 0;
            }
            100% {
                background-position: 50px 50px;
            }
        }
    }

    .button {
        float: right;
        margin-top: 10px;
    }
}

.export_dialog {
    .contents {
        padding-bottom: 10px;
    }

    .export_container {
        display: flex;
        align-items: center;
        justify-content: space-around;
    }

    .export_type_container {
        //padding: 30px 90px;
        .export_button {
            display: flex;
            padding: 10px 20px;
            border: solid 1px transparent;
            align-items: center;

            &:hover {
                border: 1px solid $selectionColor;
                background: transparentize($selectionColor, 0.9);
            }

            .icon {
                font-size: 55px;
                position: relative;
                display: block;
                color: $selectionColor;
            }

            .label {
                display: block;
                margin-left: 10px;
                font-size: 14px;
                font-weight: 600;
                color: #333;
                letter-spacing: 0.9px;
                text-align: left;
                text-transform: uppercase;
            }
        }
    }

    .note {
        text-align: center;
        margin-top: 20px;
        color: #555;
        font-size: 12px;
        text-transform: uppercase;
        letter-spacing: 0.9px;
    }
}

.import_chart_data_dialog {

    .pasteContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        font-weight: 600;
        color: $darkSelectionColor;
        height: 300px;
        width: 100%;
        border: solid 1px $selectionColor;
    }

    textarea {
        width: 100%;
        height: 300px;
        resize: none;
        font-size: 16px;
        padding: 10px;
    }
}

.dropdown_menu {
    .menu_contents {
        .filter_dialog {
            width: 1139px;
            height: 600px;
            display: flex;

            .row {
                display: grid;
                grid-template-columns: repeat(3, 300px);
                overflow: hidden;
                overflow-y: scroll;
                gap: 10px;
            }

            .filterThumbnail {
                //margin-right: 10px;
                //margin-bottom: 30px;
                //display: inline-block;
                text-align: center;
                //width: 140px;
                //height: 100px;
                .name {
                    text-align: center;
                    font-size: 12px;
                    color: #333;
                    font-weight: 600;
                    text-transform: capitalize;
                }

                &:hover {
                    background: #f1f1f1;
                }

                image {
                    //height: 100%;
                }
            }

            .controls {
                //position: fixed;
                margin: 0px 0px 0px 20px;
                display: flex;
                flex-direction: column;
                //width: 220px;
                align-items: flex-end;
                width: 190px;

                .control.slider {
                    width: 100%;
                    display: flex;
                    height: 34px;
                    margin-right: 0px;
                    label {
                        min-width: 65px;
                    }
                }

                .control.button {
                    width: 100%;
                    margin-right: 0px;
                    height: 34px;
                    label {
                        margin-right: 0px;
                    }
                }
            }
        }
    }
}

.fatal_error.modal_dialog {
    padding: 20px;

    .title {
        font-size: 22px;
        text-transform: uppercase;
        font-weight: bold;
        text-align: center;
    }

    .message {
        margin: 20px 0px;
        font-weight: 500;
        font-size: 15px;
        color: #555;
        text-align: center;
    }
}

.modal_dialog.add-text-element {
    width: 710px;

    .contents {
        background: white;
    }

    .thumbnails {
        display: grid;
        grid-template-columns: repeat(4, 160px);
        grid-column-gap: 10px;
        grid-row-gap: 10px;

        .thumbnail {
            img {
                width: 100%;
                vertical-align: middle;
            }

            &.disabled {
                opacity: 0.5;
            }

            &:hover {
                outline: $selectionColor 2px solid;
            }
        }
    }
}

.change-password.modal_dialog {
    width: 550px;

    form {
        margin: 0px;
    }

    form > .control.textinput {
        width: 100%;

        label {
            width: 200px;
            font-size: 14px;
        }

        input {
            width: 300px;
        }

        .input-error {
            margin-left: 200px;
        }
    }

    input.button {
        margin: 0px 10px 0px 0px;
        width: 240px;
    }
}

.reauth-password.modal_dialog {
    width: 500px;
    font-size: 14px;

    label {
        text-transform: uppercase;
        margin-right: 10px;
        font-weight: 600;
    }

    input {
        width: 300px;
    }
}


.upgrade-plan-dialog {
    font-family: "Source Sans Pro";
    color: #333;
    background: white;
    min-height: fit-content;
    overflow-y: auto;

    .outer {
        padding: 10px 20px;
    }
    .inner {
        padding: 20px 20px 10px;
    }
    .header-intro {
        font-size: 30px;
        font-weight: 600;
        margin-bottom: 10px;
    }
    .header-text {
        font-size: 18px;
        font-weight: 600;
    }
    .feature-label {
        font-size: 14px;
        font-weight: 600;
        text-transform: uppercase;
    }

    .feature-scroll {
        overflow-y: scroll;
        padding-top: 10px;
    }
    .feature-list {
        display: grid;
        grid-template-columns: 50% 50%;

        .feature {
            display: flex;
            margin-bottom: 10px;
            img {
                width: 65px;
                height: 65px;
                position: relative;
                top: -15px;
            }
            .frame {
                margin-left: 10px;
                width: 360px;
                .title {
                    font-size: 20px;
                    font-weight: 600;
                }
                .description {
                    font-size: 14px;
                    font-weight: 500;
                }
            }
        }
    }

    .warning {
        background: orangered;
        color: white;
        font-size: 18px;
        font-weight: 600;
        text-transform: uppercase;
        padding: 15px 20px;
        letter-spacing: 0.5px;
        text-align: center;
    }

    .cta-block {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .buttons {
            display: flex;
            .link {
                flex-grow: 2;
            }
        }
    }

    .cta-button {
        background: #ffaa00;
        //border: solid 1px $selectionColor;
        padding: 12px 25px;
        text-transform: uppercase;
        font-size: 16px;
        font-weight: 600;
        color: white;
        letter-spacing: 0.75px;
        text-align: center;
        border-radius: 4px;
        text-shadow: 0px 0px 10px rgba(0,0,0,.2);
        &:hover {
            background: darken(#ffaa00, 5%);
        }
    }

    .downgrade-button {
        margin-left: 20px;
        border: solid 1px $selectionColor;
        background: white;
        padding: 12px 25px;
        text-transform: uppercase;
        font-size: 16px;
        font-weight: 600;
        color: #333;
        letter-spacing: 0.75px;
        &:hover {
            background: $selectionColor;
        }
    }

    .cta-description {
        margin-top: 20px;
        font-size: 14px;
        font-weight: 500;
    }

    .footnote {
        font-size: 12px;
        text-align: center;
        width: 100%;
    }

    ul {
        li {
            font-size: 16px;
            font-weight: 500;
            margin-bottom: 20px;
            span {
                font-size: 14px;
                color: #555;
                display: block;
                margin-top: 5px;
            }
        }
    }
}

.is_mobile {
    .upgrade-plan-dialog {
        .header-intro {
            font-size: 24px;
            min-width: 280px;
        }
        .header-text {
            font-size: 14px;
            font-weight: 500;
        }
        .feature-label {
            font-size: 12px;
            font-weight: 500;
        }

        .feature-list-header {
            font-size: 16px;
            font-weight: 600;
            text-align: center;
            margin-bottom: 10px;
        }

        .feature-scroll {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .feature-list {
            display: block;

            .feature {
                .frame {
                    width: auto;

                    .title {
                        font-size: 16px;
                        font-weight: 500;
                    }
                    .description {
                        font-size: 14px;
                        font-weight: 500;
                    }
                }
            }
        }

        .cta-button {
            font-size: 14px;
            font-weight: 600;
        }

        .downgrade-button {
            font-size: 14px;
            font-weight: 600;
        }

        .cta-description {
            font-size: 12px;
            font-weight: 500;
        }

        .footnote {
            font-size: 12px;
            text-align: center;
            width: 100%;
        }

        ul {
            li {
                font-size: 14px;
                font-weight: 500;
                span {
                    font-size: 12px;
                }
            }
        }
    }
}
