@import "common";

.dialog-container {
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0px;
  left: 0px;
  z-index: 1300;
  // pointer-events: none;  // Brent: Removed as it was blocking child interaction.

  &.unblocking {
    width: 0;
    height: 0;
  }
}

.error-dialog {
    border-top: solid 3px orangered !important;
    max-width: 750px !important;
    .MuiDialogTitle-root {
        text-align: left !important;
    }
    .dialog-content {
        text-align: left !important;
    }
}

.dialog-content {
  flex: 1 1 auto;
  padding: 0 34px 34px;
  overflow-y: auto;

  font-size: 16px;
  font-weight: 500;
  color: #333;

  h1 {
    font-size: 20px;
  }

  h2 {
    font-size: 18px;
  }

  h3 {
    font-size: 16px;
    font-weight: 500;
    color: #666;
    margin: 10px 0px 10px;

    &:first-of-type {
      margin-top: 0px;
    }
  }

  p+p {
    margin-top: 10px
  }

  blockquote {
    background: #f1f1f1;
    margin-inline: 0px;
    padding: 20px 24px;
    font-weight: 500;
    font-size: 16px;
  }

  hr {
    border: none;
    background: #ccc;
    height: 1px;
    margin: 20px 0px 20px;
  }

  .label {
    font-size: 15px;
    font-weight: 600;
    color: #333;
    margin-bottom: 10px;
  }

  .span {
    font-size: 15px;
    font-weight: 600;
    color: #333;
    margin-bottom: 10px;
  }

  ul {
    li {
      margin-bottom: 5px;
      //display: flex;
      //align-items: center;
    }
  }

  .summary {
    background: #f1f1f1;
    font-size: 15px;
    padding: 15px 20px;
    color: #333;
  }

  .warning {
    display: flex;

    i {
      margin-right: 10px;
      color: orange;
      position: relative;
      top: -3px;
    }
  }

  span.list-num {
    border: solid 2px $newBlue;
    border-radius: 100px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $newBlue;
    font-size: 15px;
    display: inline-flex;
    margin-right: 10px;
    font-weight: 600;
  }

  .placeholder {
    .select-input {
      font-style: italic;
      color: #999;
      text-transform: initial;
      font-size: 14px;
      font-weight: 500;
    }
  }

  .dark-mode & {
    color: #ddd;

    h3 {
      color: #aaa;
    }

    hr {
      background: #444;
    }

    .label {
      color: #ddd;
    }

    .summary {
      background: #222;
      color: #ddd;
    }

    .placeholder {
      .select-input {
        color: #777;
      }
    }
  }
}

.dialog-content-text {
  min-width: 300px;

  p + p {
    margin-top: 20px;
  }
}

.dialog-icon {
  border-radius: 100px;
  background: $selectionColor;
  color: white;
  width: 50px;
  height: 50px;
  position: absolute;
  left: -25px;
  top: -25px;
  display: flex;
  align-items: center;
  justify-content: center;

  i {
    font-size: 30px;
  }
}

.dialog-paper {
  overflow-y: visible !important;
}

.dialog-embed {
  textarea {
    width: 100%;
    font-size: 14px;
    font-family: Courier;
    height: 100px;
    resize: none;
    padding: 10px;
    box-sizing: border-box;
    margin: 0px 0px;
  }
}

.dialog-banner {
  font-size: 25px;
  padding: 20px 30px 0px;
  font-weight: 600;
}

.dialog-socialshare {
  .social-links {
    display: flex;

    .social {
      fill: $selectionColor;
      margin-right: 20px;
      border: solid 1px $selectionColor;
      padding: 10px 20px;
      display: flex;
      align-items: center;

      &:last-of-type {
        margin-right: 0px;
      }

      &:hover {
        fill: white;
        background: $selectionColor;

        label {
          color: white;
        }
      }

      svg {
        width: 40px;
        height: 40px;
        margin-right: 10px;
      }

      label {
        text-transform: uppercase;
        color: #333;
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 1px;
      }
    }
  }
}

.dialog-toggle-confirmation {
  .toggle-choice {
    width: 100px;
    height: 40px;
    display: inline-block;
    align-items: center;
    justify-content: center;
    color: black;
    text-align: center;
    padding: 10px 0;
    margin: 20px 80px 0;
    cursor: pointer;

    i {
      font-size: 50px;
      text-shadow: 0px 0px 21px rgba(0, 0, 0, 0.2);
    }

    label {
      margin-top: 5px;
      text-align: center;
      font-size: 13px;
      font-weight: 600;
      text-transform: uppercase;
      text-shadow: 0px 0px 7px rgba(0, 0, 0, 0.2);

      &.selected {
        color: $selectionColor;
      }
    }

    &:hover {
      background: rgba(0, 0, 0, 0.08);
    }
  }
}

.upgrade-team-dialog {
  .disabled {
    pointer-events: none;
    opacity: 0.5;
  }
}

.record-audio-dialog {
  width: 100%;
  height: 100%;
  background: #333;
  padding: 20px;
  pointer-events: auto;
  color: white;

  .player-frame {
    position: relative;
    height: 400px;

    .player-click-shield {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 99999;
    }
  }

  #player-for-record-audio {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 640px;
    height: 360px;
  }

  .timer {
    font-weight: 500;
    font-size: 14px;
  }


  .audio-preview {
    width: 1000px;
  }

  .timeline {
    position: relative;
    margin: 30px 0px;
    width: 100%;
    padding: 10px 0px;

    .line {
      width: 100%;
      height: 3px;
      background: white;
      position: relative;
      top: 11px;
    }

    .slide-marker {
      position: absolute;
      width: 20px;
      height: 20px;
      border-radius: 100px;
      background: $selectionColor;
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 1;
      font-weight: 600;
      font-size: 10px;
      color: black;
    }

    .build-marker {
      position: absolute;
      width: 10px;
      height: 10px;
      border-radius: 100px;
      background: white;
    }

    .end-marker {
      position: absolute;
      width: 10px;
      height: 10px;
      background: red;
    }

    .waveform {
      width: 100%;
      padding: 0px !important;
    }
  }
}

.record-slide-audio-dialog {
  .react-mic {
    width: 100%;
    height: 100px;
    display: block;
  }

  .timer {
    font-weight: 500;
    font-size: 14px;
  }
}

.record-audio-dialog {
  width: 100%;
  height: 100%;
  background: #333;
  padding: 20px;
  pointer-events: auto;
  color: white;

  .player-frame {
    position: relative;
    height: 400px;

    .player-click-shield {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 99999;
    }
  }

  #player-for-record-audio {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 640px;
    height: 360px;
  }

  .timer {
    font-weight: 500;
    font-size: 14px;
  }

  .audio-preview {
    width: 1000px;
  }

  .timeline {
    position: relative;
    margin: 30px 0px;
    width: 100%;
    padding: 10px 0px;

    .line {
      width: 100%;
      height: 3px;
      background: white;
      position: relative;
      top: 11px;
    }

    .slide-marker {
      position: absolute;
      width: 20px;
      height: 20px;
      border-radius: 100px;
      background: $selectionColor;
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 1;
      font-weight: 600;
      font-size: 10px;
      color: black;
    }

    .build-marker {
      position: absolute;
      width: 10px;
      height: 10px;
      border-radius: 100px;
      background: white;
    }

    .end-marker {
      position: absolute;
      width: 10px;
      height: 10px;
      background: red;
    }

    .waveform {
      width: 100%;
      padding: 0px !important;
    }
  }
}

.record-slide-audio-dialog {
  .react-mic {
    width: 100%;
    height: 100px;
    display: block;
  }

  .timer {
    font-weight: 500;
    font-size: 14px;
  }
}

.marketing-info-dialog {
  padding-top: 30px;

  h3 {
    font-size: 18px;
    font-weight: 500;
    color: #444;
    margin: 30px 0px 14px;

    &:first-of-type {
      margin-top: 0px;
    }
  }

  .question {
    display: flex;
    margin-top: 20px;
  }
  .prompt {
    font-size: 18px;
    font-weight: 500;
    color: #444;
  }

  .list-num {
    border: solid 2px $newBlue;
    border-radius: 100px;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $newBlue;
    font-size: 15px;
    display: inline-flex;
    margin-right: 10px;
    font-weight: 600;
  }
}

.is_mobile {

  .MuiDialogTitle-root,
  .dialog-content {
    padding-left: 20px;
    padding-right: 20px;
  }

  .dialog-paper {
    max-width: 100%;
    max-height: 100%;
  }
}
