@import "common";

$backgroundColor: #333;
$panelColor: rgba(0, 0, 0, 0.4);
$panelSpacing: 20px;

.analytics-pane {
  //padding: 20px 30px 80px !important;
  overflow: hidden !important;
  padding: 0px 0px 80px 25px !important;
  width: calc(100vw - 250px) !important;
}

.analytics-upgrade-banner {
  height: 90px;
  font-weight: 600;
  font-size: 20px;
  margin-top: -10px;
  margin-bottom: -$panelSpacing;
  display: flex;
  align-items: center;

  button {
    margin-left: 20px;
  }
}
.analytics-upgrade-shield {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 40px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999;
  pointer-events: none;

  div {
    padding: 20px;
    background: white;
    opacity: 0.75;
    color: #333;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    text-transform: uppercase;
  }
}

.presentation-settings > .player_analytics {
  //width: 100%;
  //height: 100%;
  cursor: default;
  //background: $backgroundColor !important;
  color: white !important;
  padding: 0px;
  background: $body_background;

  &.analytics-userpage {
    position: absolute;
    left: 240px;
    right: 0;
    width: auto;
  }

  .contents {
    background: none;
    margin: 0px 0px;
    padding: 0px 0px 60px 0px;
  }

  .header-block {
    position: absolute;
    width: 100%;
    height: 190px;
    top: 0px;
    left: 0px;
    //background: $panelColor;
    z-index: -1;

    &.user-view {
      height: 80px;
    }

    .contents-block {
      max-width: 1240px;
      margin: 0px auto;
      padding: 10px 30px;
    }
  }

  label {
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 600;
    color: #bbb;
  }

  h2 {
    font-size: 26px;
    font-weight: 600;
    text-transform: uppercase;
  }

  .presentation-name {
    font-size: 30px;
    text-transform: uppercase;
    font-weight: 600;
  }

  .per-day-panel {
    display: flex;

    .per-day-count {
      .per-day-count-value {
        background: $selectionColor;
        color: white;
        min-width: 160px;
        padding: 0px 20px;
        height: 160px;
        display: flex;
        align-items: center;
        justify-content: center;

        label {
          color: white;
          font-size: 50px;
          font-weight: 600;
        }
      }
    }

    .per-day-label {
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 160px;
      height: 35px;
      background: $panelColor;
      font-size: 14px;
      font-weight: 600;
      color: #ccc;
      text-transform: uppercase;
      letter-spacing: 0.1em;
    }

    .completion-rate {
    }

    .per-day-chart {
      background: $panelColor;
      padding: 14px 20px 6px 4px;
      //height: 190px;
      margin-left: 20px;
      width: calc(100% - 170px);
      position: relative;
      //top: -8px;
    }
  }

  .stats-panel {

    .stats {
      display: grid;
      grid-auto-flow: column;
      column-gap: 3px;

      .stat-item {
        position: relative;
        text-align: left;
        background: $panelColor;
        padding: 10px 20px 12px 20px;
        //width: 100%;
        min-height: 80px;
        letter-spacing: 0.1em;
        white-space: nowrap;

        .stat-value {
          color: white;
          font-size: 30px;
          font-weight: 600;
          margin-bottom: 2px;
          white-space: nowrap;
        }

        &:last-child {
          margin-right: 0px;
        }

        &.with-radial {
          padding-left: 14px;
          display: flex;
          align-items: center;

          .radial-graph {
            margin-top: 2px;
            margin-right: 14px;
          }
        }

        label {
          font-size: 12px;
          color: #ccc;
        }
      }
    }
  }

  .radial-graph {
    svg {
      //position: absolute;
      display: block;
      //top: 0px;
      //left: 0px;
      circle {
        stroke: #444;
        stroke-width: 7px;
      }

      path {
        stroke: $selectionColor;
        stroke-width: 7px;
      }
    }
  }

  .chart-panel {
    background: $panelColor;
    padding: 14px 10px 14px 20px;

    label {
      font-size: 14px;
      text-transform: uppercase;
      font-weight: 600;
      letter-spacing: 0.1em;
    }

    .chart {
      margin-top: 24px;
      position: relative;
    }
  }

  .list-panel {
    margin-top: $panelSpacing;
    background: $panelColor;
    padding: 20px;

    .tabs {
      width: 100%;
    }

    .link {
      color: white;
      &:hover {
        background:  rgba(255, 255, 255, 0.05);
      }
    }
  }

  .tabs {
    .tab-list {
      display: flex;

      .tab-list-item {
        font-size: 14px;
        font-weight: 600;
        text-transform: uppercase;
        background: #111;
        padding: 10px 20px;
        color: #ccc;

        &.tab-list-active {
          background: $selectionColor;
          color: white;
        }
      }
    }

    .tab-content {
      margin-top: 20px;
      width: 100%;
    }

    .report {
      .downloaded {
        text-align: right;

        .MuiIcon-root {
          color: #90B12F;
        }
      }
    }
  }

  .slide-views {
    padding: 20px;
    display: flex;

    .slide-view-list {
      width: 100%;

      .slide-view-item {
        width: 100%;
        margin-bottom: 20px;
        display: flex;

        .thumbnail {
          height: 75px;
          margin-right: 20px;
        }

        .view-length {
          background: $selectionColor;
          border-radius: 10px;
          height: 12px;
        }
      }
    }
  }

  .user-views {
    position: relative;

    .user-view-item {
      margin-bottom: 20px;

      .user-view-label {
        display: flex;
        align-items: center;
        cursor: default;

        .marker {
          position: relative;
          width: 14px;
          height: 14px;
          border-radius: 100px;
          background: #222;
          margin-right: 10px;

          &:after {
            content: "";
            position: absolute;
            background: $panelColor;
            width: 2px;
            height: 13px;
            top: 20px;
            left: 6px;
          }
        }

        .label {
          font-size: 16px;
          font-weight: 600;
          color: #999;

          span.duration {
            margin-left: 10px;
            font-size: 16px;
            color: #ccc;
            background: #444;
            padding: 8px 12px;
          }
          span.username {
            margin-left: 10px;
            font-weight: bold;
          }
        }

        &.selected {
          .marker {
            background: $selectionColor;

            &:after {
              height: 355px;
            }
          }

          .label {
            color: white;

            span.duration {
              color: white;
            }
          }
        }

        &:hover {
          .label {
            color: #ccc;
          }
        }
      }

      .chart-panel {
        margin-left: 30px;
        margin-top: 20px;
        position: relative;
      }

      &:last-child {
        .user-view-label .marker:after {
          height: 0px;
        }
      }
    }
  }

  .user-title-bar {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    .back_button {
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-content: center;
      cursor: pointer;
      margin-right: 20px;
      border-radius: 100px;

      &:hover {
        background: $selectionColor;
      }
    }

    .user-header {
      display: flex;
      font-size: 30px;
      font-weight: 600;
      color: white;
      align-items: center;

      span.username {
        margin-left: 10px;
        font-size: 16px;
        color: #aaa;
        position: relative;
        top: 3px;
      }

      .avatar {
        position: relative;
        top: 3px;
        border-radius: 50%;
        overflow: hidden;
        width: 50px;
        height: 50px;
        margin-right: 20px;
      }
    }
  }

  .section-label {
    font-size: 16px;
    color: #ccc;
    font-weight: 600;
    text-transform: uppercase;
    margin: 30px 0px;
    letter-spacing: 0.1em;
  }

  //.avatar {
  //  border-radius: 50%;
  //  overflow: hidden;
  //  margin-right: 10px;
  //}

  .table {
    position: relative;
    //display: table;
    width: 100%;
    min-height: 200px;

    table {
      border-collapse: collapse;
      width: 100%;
    }

    thead th {
      text-transform: uppercase;
      font-size: 14px;
      color: white;
      font-weight: 600;
    }

    tbody {
      tr {
        color: #bbb;
        border-bottom: 1px solid #444;
        height: 46px;

        &:hover {
          color: white;
          background: rgba(124, 181, 236, 0.5);
        }

        &.selected {
          background: rgba(124, 181, 236, 0.1);
        }

        &:last-child {
          border-bottom: none;
        }

        .avatar {
          width: 25px;
          height: 25px;
        }

        .progress-bar {
          display: flex;
          align-items: center;

          .bar {
            background: $selectionColor;
            border-radius: 10px;
            height: 13px;
          }

          label {
            margin-left: 10px;
            font-size: 12px;
            font-weight: 600;
            color: white;
          }
        }
      }

      &.allow-clicks tr {
        cursor: pointer;
      }
    }

    th,
    td {
      text-align: left;
      padding: 10px 12px;
    }

    td {
      max-width: 600px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .invites {
    .name {
      display: flex;
      align-items: center;

      .avatar {
        border-radius: 50%;
        overflow: hidden;
        margin-right: 10px;
      }
    }
  }

  //.links{
  //  .link{
  //    display: flex;
  //    flex-direction: row;
  //    width: 100%;
  //  }
  //}

  .pagination {
    display: flex;
    justify-content: center;

    > div {
      margin: 10px 0;
      padding: 2px 6px;
      width: 40px;
      height: 30px;
      cursor: pointer;
      color: white;
      background: none;
      border: none;
      outline: none;

      &:disabled {
        color: #999;
        cursor: no-drop;
      }
    }
  }

  .chart-error,
  .table-error,
  .table-no-rows {
    display: block;
    text-align: center;
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 20px;
  }

  .errorMessage {
    margin-top: 20px;
    font-size: 16px;
    text-align: left;
  }

  @media (max-width: 1100px) {
    .tables {
      display: block;

      > .report {
        width: calc(100% - 40px);
      }
    }

    .summary-item {
      display: flex;
      flex-direction: column;
    }
  }

  .no-data {
    margin-top: 30px;
    font-size: 20px;
  }

  .load-more {
    position: relative;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    margin-top: 20px;
    display: inline-block;
    padding: 7px 17px;

    &:hover {
      background: $selectionColor;
    }

    &.with-spinner {
      &:hover {
        background: none;
      }
    }
  }

  //.status, .permission {
  //    border-radius: 10px;
  //    padding: 4px 8px;
  //    font-size: 12px;
  //    font-weight: bold;
  //    display: inline-block;
  //
  //    &.accepted {
  //        background: rgba(50,200,50,0.5);
  //    }
  //    &.pending {
  //        background: rgba(200,200,50,0.5);
  //    }
  //}
}
