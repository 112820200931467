@import "common";

@keyframes fade-in {
    0% {
        opacity: 0;
    }
    20% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.add-asset-container {
    position: absolute;
    left: 0;
    display: flex;
    height: 100%;
    opacity: 1;

    //.ui-pane-header{
    //  padding: 0px;
    //}
    //.ui-inner-pane{
    //  padding: 0px;
    //}
    .ui-navigation-items {
        padding-top: 20px;
    }

    .ui-pane {
        background: white;
        //opacity: 1;
        //display: block;
        //transform: translateX(0px);
    }

    .ui-pane.media-asset-logo {
        .previously-used-asset-wrapper {
            //width: 200px;
            //height: 200px;
            display: flex;
            align-items: center;
            background: white;
            border: solid 1px #f1f1f1;
            padding: 20px;
        }
    }

    // Wrapper that allows results container to scroll
    .search-results-wrapper,
    .previously-used-wrapper {
        //overflow-y: scroll;
        //overflow-x: hidden;
        ////padding: 30px;
        //width: 100%;
        //height: calc(100% - 57px);

        // Container of search results.
        .search-results-container,
        .previously-used-container {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            align-items: center;
        }
    }

    .search-results-wrapper.icons {
        background: white;
        .search-results-container {
            display: grid;
            grid-template-columns: repeat(auto-fit, 70px);
            grid-column-gap: 20px;
            grid-row-gap: 20px;
        }
    }

    .icon-asset {
        width: 70px;
        height: 70px;
        background: white;
        color: black;
        &:hover {
            background: #f1f1f1;
        }
        img {
            width: 100%;
            height: 100%;
        }
        //  iconParent : {
        //    width: 'auto',
        //    height: 'auto',
        //    borderRadius: 50,
        //    padding: 10,
        //    background: '#fff',
        //    position: 'relative',
        //    display: 'inline-flex',
        //    alignItems: 'center',
        //    justifyContent: 'left',
        //    marginRight: 10,
        //    marginBottom: 10,
        //    transition: 'all 0.4s ease'
        //  },
        //hover: {
        //  backgroundColor: '#f5f6f7',
        //  transition: 'all 0.4s ease'
        //},
        //icon : {
        //  width: 50,
        //  height: 50
        //}
    }

    // Wrapper for default stock images search categoryies.
    //.stock-photo-popular-search-wrapper {
    //  overflow-y: scroll;
    //  overflow-x: hidden;
    //  width: calc(100vw - 250px);
    //  height: calc(100% - 57px);
    //  padding-top: 30px;
    //  position: relative;
    //
    //}

    // Container of search results.
    .stock-media-popular-search-container {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(3, 0.333fr);

        grid-column-gap: 20px;
        grid-row-gap: 20px;

        div {
            height: 120px;
            position: relative;
            overflow: hidden;
            cursor: pointer;

            img {
                width: 100%;
                vertical-align: middle;
            }

            label {
                position: absolute;
                font-weight: 700;
                color: white;
                text-shadow: 0px 2px 6px rgba(0, 0, 0, 0.3);
                font-size: 30px;
                top: 10px;
                left: 13px;
            }
        }

        @media (min-width: 1200px) {
            grid-template-columns: repeat(4, 0.25fr);
            div {
                height: 140px;
            }
        }
        @media (min-width: 1900px) {
            grid-template-columns: repeat(5, 0.2fr);
            div {
                height: 160px;
            }
        }
    }

    // Specific wrapper for stock photos since it needs a scroll listener
    .scrolling-container {
        height: 100%;
    }

    .preview-image-wrapper {
        margin: 10px;
        position: relative;
        max-width: 400px;

        img {
            display: block;
            width: 100%;
            height: auto !important;
        }
    }
}

.stock-photo-wrapper {
    position: relative;
    background: black;

    img {
        vertical-align: middle;
        //height: 100%;
        //width: 100%;
        //display: block;
        transition: opacity 500ms;
        object-fit: cover;
    }

    .attribution {
        position: absolute;
        background: #000;
        color: #fff;
        position: absolute;
        bottom: 0;
        width: 100%;
        text-align: center;
        font-size: 12px;
        padding: 2px;
        opacity: 0;
        transition: opacity 500ms;

        a:link,
        a:visited,
        a:active,
        a:hover {
            color: #fff;
            font-weight: 700;
            text-decoration: none;
        }
    }

    &:hover {
        .attribution {
            opacity: 1;
        }
    }
}

// Search results -- logos.
.logo-wrapper {
    background: #f9f9f9;
    display: flex;
    align-items: center;
    justify-content: center;
    border: solid 1px #f1f1f1;
    padding: 20px;
    img {
        max-width: 100%;
        max-height: 100%;
    }
    //background: white;
    //width: 24%;
    //height: 180px;
    //padding: 20px;
    //margin-right: 10px;
    //margin-bottom: 10px;
    //text-align: center;
    //display: flex;
    //align-items: center;
    //justify-content: center;
    //border: solid 1px #e1e1e1;
    //position: relative;
    //overflow: hidden;
    //
    //img {
    //  max-height: 100%;
    //  max-width: 100%;
    //  display: block;
    //}

    &:hover {
        border: solid 1px #50bbe6;
    }
}

$border-width: 1px;
// Previously used assets -- stock photos.
.previously-used-asset-wrapper.image-wrapper {
    //height: 150px;
    //margin: 10px;
    //display: flex;
    //justify-content: center;
    //align-items: center;
    //border: solid $border-width #fff;
    background: #f1f1f1;
    //position: relative;

    img {
        vertical-align: middle;
        width: 100%;
        //max-height: 100%;
        //width: auto;
        //display: block;
    }
}

// Previously used assets -- logos.
.previously-used-asset-wrapper.logo-wrapper {
    border: solid $border-width #e1e1e1;
    max-height: 400px;
    position: relative;

    img {
        max-height: 100%;
        width: auto;
        display: block;
        margin: auto;
    }
}

// Previously used assets -- shared rollover
.previously-used-asset-wrapper {
    .menu-button {
        position: absolute;
        bottom: 5px;
        right: 5px;
        color: #333;
        background: white;
        //border: 1px solid $border_color;
        border-radius: 100px;
        height: 25px;
        width: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        &:hover {
            background: $selectionColor;
        }
    }

    &.logo-wrapper,
    &.image-wrapper,
    &.stock-video-wrapper {
        &:hover {
            outline: solid $border-width #50bbe6;
        }

        &.selected {
            outline: $border-width solid #50bbe6;
        }
    }
}

// Asset previews from other presentations.
.presentation-asset-wrapper {
    max-width: 400px;
    height: 150px;
    margin: 10px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f1f1f1;
    overflow: hidden;

    img {
        display: block;
        height: auto;
        max-width: 100%;
    }
}

// No results messsage.
.no_results {
    margin-top: 40px;
    font-size: 30px;
    font-weight: 300;
    text-align: center;
    width: 100%;
}

// Style applied to material-ui button at runtime (needs to live at top level)
.popover-button {
    font-family: "Source Sans Pro";
    font-size: 13px;
    color: black;
    border: none;
    padding: 10px;
    width: 100%;
    background-color: white;
    text-align: left;

    &:hover {
        background: $selectionColor;
        color: white;
    }
}
