@import "common";

.billing {
    .pane {
        display: flex;
        flex-wrap: wrap;
    }

    .billing-blocks {
        display: grid;
        column-gap: 20px;
        grid-auto-flow: column;
    }

    .managed-billing-banner{
        padding: 20px;
        background: #fa0;
        color: black;
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 20px;
        text-transform: uppercase;
        text-align: center;
    }

    .billing-block {
        color: #333;
        padding: 20px 20px 20px 20px;
        position: relative;
        font-size: 18px;
        background: white;
        display: flex;
        flex-direction: column;

        .title {
            width: 100%;
            color: #222;
            font-size: 16px;
            font-weight: 600;
            text-transform: uppercase;
            letter-spacing: 0.05em;
        }

        .contents {
            margin-top: 8px;
            margin-bottom: 20px;
            font-size: 16px;
            color: #333;
            flex-grow: 2;
        }

        .actions {
            display: grid;
            grid-auto-flow: column;
            grid-auto-columns: max-content;
            column-gap: 20px;
            margin-top: 10px;
        }

        .plan {
            display: flex;
            align-items: center;
            font-size: 36px;
            font-weight: 600;
            color: #222;
            text-transform: capitalize;

            .plan-period {
                margin-left: 6px;
                font-size: 30px;
                color: #333;
            }

            .canceled-notice {
                margin-left: 20px;
                font-size: 16px;
                color: white;
                font-weight: 600;
                text-transform: none;
                background: #cb3d0c;
                padding: 8px 15px;
                text-transform: uppercase;
            }
        }
        .plan-info {
            margin-top: 10px;
            font-size: 15px;
            color: #333;
            text-transform: none;
        }

        .past-due {
            color: #e74c3c;
            margin-top: 9px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-weight: 700;

            >button {
                color: red;
            }
        }

        &.referral {
            border: 2px solid #eaeaea;
            border-radius: 2px;
        }
    }

    #transactions {
        width: 100%;

        .amount {
            text-align: right;
        }

        .card {
            text-transform: capitalize;
        }
        .charge_id {
            user-select: auto;
        }
        .receipt {
            text-align: center;
        }
        .receiptIcon {
            display: inline-block;
        }
    }

    table {
        border-collapse: collapse;
        width: 100%;
        font-size: 15px;
        margin-top: 20px;


        thead {
            tr {
                height: 30px;
                color: #555;
                font-size: 12px;
                border-bottom: solid 2px $newBlue;
                th:nth-last-child(2) {
                    text-align: right;
                }
            }
        }
        th,
        td {
            padding: 10px 12px;
            text-align: left;
            color: #333;
        }

        th {
            text-transform: uppercase;
            &:last-child {
                text-align: center;
            }
        }

        tr {
            height: 50px;
            &:nth-child(even) {
                background: #fafafa;
            }
        }

        tbody {
            tr {
                &:last-child {
                    border-bottom: none;
                }

                a {
                    color: $newBlue;
                }

                .status {
                    .subscription_status_icon {
                        margin: auto 10px;
                        &.warning {
                            color: rgb(243, 156, 18);
                        }
                        &.success {
                            color: rgb(151, 170, 15);
                        }
                        &.error {
                            color: rgb(231, 76, 60);
                        }
                    }
                }

                .action {
                    transition: all 0.5s ease;
                    text-align: right;

                    button {
                        margin-right: 12px;

                        &:last-child {
                            margin-right: 0;
                        }
                    }
                }

                .failure {
                    color: rgb(231, 76, 60);
                    padding: 5px;
                    margin-top: 5px;
                    border-radius: 3px;
                }
            }
        }
    }

    .badge {
        background-color: rgba(255, 255, 255, 0.1);
        text-transform: uppercase;
        font-size: 14px;
        padding: 10px 20px;
        font-weight: 800;
        display: inline-block;
    }

    .subscription_status_icon {
        color: #ccc;

        &.incomplete,
        &.incomplete_expired,
        &.unpaid,
        &.inactive {
            color: rgba(203, 61, 12, 1);
        }
    }
}

.cta-title {
    font-size: 18px;
    font-weight: 600;
    color: #333;
}

.payment-method {
    display: flex;
    font-size: 15px;
    color: #222;
    align-items: center;
    width: 100%;

    .card-icon {
        margin-right: 20px;
    }
    .card-info {
        .card-name {
            font-weight: 600;
            text-transform: capitalize;
            letter-spacing: 0.5px;
        }

        .last-4-card-name {
            text-transform: capitalize;
            color: #666;
        }

        .card-expiration {
            color: #666;
        }
    }
}

.card-brand-icon {
    height: 40px;
    clip-path: inset(2px 2px 2px 2px);
}

.payment_form_panel {
    position: absolute;
    z-index: 1000;
    display: flex;
}

#checkout_form_container {
    display: flex;
    flex-direction: column;
    opacity: 1;
    &.disabled {
        pointer-events: none;
        opacity: 0.5;
    }

    .select-input {
        font-size: 16px;
        padding: 12px 25px 12px 12px;
        color: #333;
    }
}

#checkout_form {
    @media only screen and (min-height: 700px) {
        align-items: center;
    }
    width: 100%;
    position: relative;

    .payment-details {
        border: solid 1px #c4c4c4;
        padding: 10px;

        &.focused {
            outline: 2px solid $newBlue;
            outline-offset: -2px;
        }
    }

    .payment-details-b {
        border-bottom: solid 1px #c4c4c4;
        padding: 10px;

        &.focused {
            border-bottom: 1px solid $newBlue;
            outline: none;
        }
    }

    .PaymentRequestButton {
        margin: 20px 0;
    }

    .card-error {
        color: white;
        background: orangered;
        letter-spacing: 0px;
        padding: 8px 15px;
        margin-top: 20px;
        font-size: 15px;
        font-weight: 600;
    }

    #pay {
        width: 100%;
        height: 42px;
    }

    .billing-data {
        width: calc(100% - 23px);
        font-size: 14px;
        padding: 10px;
    }
}

.update_payment_form {
    .card-error {
        color: white;
        background: orangered;
        letter-spacing: 0px;
        padding: 8px 15px;
        margin-top: 20px;
        font-size: 15px;
        font-weight: 600;
    }
}

#successful_purchase {
    color: #222;
    font-size: 23px;
    text-align: center;
    padding: 0px 20px;

    @keyframes checked {
        from {
            clip-path: inset(0 100% 0 0);
        }

        to {
            clip-path: inset(0 0 0 0);
        }
    }
    @keyframes fadein {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

    #big_icon {
        i {
            font-size: 70px;
            color: $newBlue;
            margin: 20px;
            transition: all 0.5s ease;
            animation: checked 1s normal forwards ease-in-out;
            animation-iteration-count: 1;
            animation-delay: 0s;
            clip-path: inset(0 100% 0 0);
        }
    }

    #message {
        animation: fadein 1s normal forwards ease-in-out;
        margin-bottom: 50px;

        span {
            display: inline-block;
        }
    }

    .receipt {
        font-size: 18px;
        margin-bottom: 10px;
        color: #555;
    }
}

.override-selected.MuiListItem-root.Mui-selected{   
    background: white;
}

.payment-form-title{  
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 21.6px */
    letter-spacing: 0.5px;
}


.payment-form-title-b{
    color: #11A9E2;
    font-family: "Source Sans Pro";
    font-size: 10.8px;
    font-style: normal;
    font-weight: 400;
    line-height: 115%;
    letter-spacing: -0.108px;
}